import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './pages/App';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/style/index.css';
import './assets/style/circular_progress_bar.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgetPassword from './pages/admin/ForgetPassword';
import ResetPassword from './pages/admin/ResetPassword';
import Tooltip from '../src/utils/Tooltip';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
            <Provider store={store}>
                <App />
                {/* <Tooltip /> */}

                <ToastContainer />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);
