import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getAllQuestions } from '../functions/surveyQuestions';

const initialState = {
    questions: [
        {
            sectionNumber: 'Section-2',
            questionSeqNo: 1,
            questionDescription: 'Your Primary Description',
            sectionStatus: 'Enable',
            questionInformation: 'Select the type of Industry',
            choiceDetails: [
                { value: 'choice 1' },
                { value: 'choice 2' },
                { value: 'choice 3' },
                { value: 'choice 4' },
            ],
        },
    ],
};

export const surveyQuestions = createSlice({
    name: 'surveyQuestions',
    initialState,
    reducers: {
        onChangeQuestion: (state, action) => {
            console.log('line27 payload', action.payload);
            const { currentSelected, updatedQuestion } = action.payload;
            //   console.log("line29",updatedQuestion);
            //   console.log("state",state);
            let { questions } = current(state);
            console.log('line32', questions);
            questions = questions.slice();

            questions[currentSelected] = updatedQuestion;
            console.log(
                'questions[currentSelected] ',
                questions[currentSelected]
            );
            console.log('Line40', questions);
            console.log('line41', state);
            return {
                ...state,
                questions,
            };
        },
        onChangeChoice: (state, action) => {
            const { currentIndex, index, value } = action.payload;
            const newValue = state.questions;
            console.log(state);
            newValue[currentIndex].choices[index] = value;
            return {
                ...state,
                questions: newValue,
            };
        },

        addQuestion: (state, action) => {
            console.log('line 49', state);
            var questions = state.questions;
            questions = questions.slice();
            questions.push({
                newQuestion: true,
                sectionName: '',
                questionNumber: 0,
                questionDescription: '',
                questionInformation: '',
                choiceDetails: [],
                subSectionName: '',
            });
            return {
                ...state,
                questions,
            };
        },
        deleteQuestion: (state, action) => {
            var questions = state.questions;
            questions = questions.filter(
                (question, index) => index !== action.payload.currentSelected
            );
            return {
                ...state,
                questions,
            };
        },
    },
    extraReducers: {
        [getAllQuestions.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getAllQuestions.rejected]: (state, action) => {
            console.log(action.payload);
            return {
                ...state,
                error: true,
            };
        },
        [getAllQuestions.fulfilled]: (state, action) => {
            console.log(action.payload);
            const { questions } = action.payload;
            return {
                ...state,
                questions: questions,
            };
        },
    },
});

export const { onChangeQuestion, onChangeChoice, addQuestion, deleteQuestion } =
    surveyQuestions.actions;
export default surveyQuestions.reducer;
