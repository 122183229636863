import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { connect } from "react-redux";
import {
  updateQuestion,
  getInitialScore,
  updateValues,
  updateAnswer,
  updateSection,
} from "../redux/reducers/score";
import Tooltip from "../utils/Tooltip";

class OneAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radio: "",
      error: "",
      // question_data: [],
      questions: [],
      currentIndex: 0,
      answers: {},
      addText: "",
      question: null,
      count: 0,
    };
  }

  async componentDidMount() {
    // this.getQuestions();
    await this.props.getInitialScore();
    await this.getCurrentScore();

    await this.getQuestion();
    if (this.props.currentIndex === -1) {
      this.props.currentIndex = 0;
    }
    this.setState({
      radio:
        this.props.answers[
          this.props.question_ids != null
            ? this.props.question_ids[this.props.currentIndex]
            : 1
        ]?.user_choice,
      // userChoice,
      addText:
        this.props.answers[
          this.props.question_ids != null
            ? this.props.question_ids[this.props.currentIndex]
            : 1
        ]?.user_comment,
      //  userComments,
    });
  }

  async componentDidUpdate(prevProps) {
    console.log("line 55 count=", this.state);
    console.log("This.state1=", this.props);
    if (this.props.currentIndex === -1) {
      this.props.currentIndex = 0;
    }
    if (prevProps.currentQuestion !== this.props.currentQuestion) {
      // this.setState({
      //     currentQuestion: 1,
      // });
      // console.log(
      //     'line 66 props.questionsids=',
      //     this.props.question_ids[this.props.currentIndex]
      // );
      await this.getQuestion();

      if (this.props.currentIndex === -1) {
        this.props.currentIndex = 0;
      }
      this.setState({
        radio:
          this.props?.answers[this.props?.question_ids[this.props.currentIndex]]
            ?.user_choice,
        //  radio: this.props.answers[this.props.currentIndex].user_choice,

        //   userChoice,
        addText:
          this.props.answers[this.props.question_ids[this.props.currentIndex]]
            ?.user_comment,
        //   userComment,
      });
      console.log("line88 section=", this.state);
      this.props.updateSection(this.state?.question?.section_name);
    }
    if (prevProps.answers !== this.props.answers) {
      await this.submitAnswers(false);
    }
    if (this.props.currentSection === "Report") {
      await this.submitAnswersFinal(true, "Report");
      this.props.navigate("/dmat/report");
    }
  }

  getCurrentScore = async () => {
    const url = process.env.REACT_APP_SERVER + "/customer/get-nav-data";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };

    await axios
      .get(url, { headers })
      .then((res) => {
        if (res.status === 251) {
          if (
            sessionStorage.getItem("accessToken") == null &&
            sessionStorage.getItem("refreshToken") == null
          ) {
            this.props.navigate("/dmat/login");
          } else {
            this.refreshToken(this.getCurrentScore);
          }
        } else if (res.status === 200) {
          console.log("updated values");
          console.log(res);
          this.props.updateValues(res.data);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  refreshToken = async (func) => {
    const url =
      process.env.REACT_APP_SERVER + "/customer/generate-access-token";

    const token = sessionStorage.getItem("refreshToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios.get(url, { headers }).then((res) => {
      console.log("res=", res.data);
      if (res.status === 200) {
        sessionStorage.setItem("accessToken", res.data.accessToken);
      }
      func();
    });
  };

  getQuestion = async () => {
    console.log("137=", this.props);
    console.log("acessToken=", sessionStorage.getItem("accessToken"));

    let currentQuestionId = 1;

    currentQuestionId =
      this.props.question_ids != null
        ? this.props.question_ids[this.props.currentIndex]
        : 1;
    //   if (this.props.currentQuestion === 'BasicDetails') {
    //       currentQuestionId = this.props.currentIndex + 1;
    //   } else {
    //       currentQuestionId = this.props.currentQuestion;
    //   }

    const url =
      process.env.REACT_APP_SERVER +
      "/customer/get-question?ques_id=" +
      // this.props.currentQuestion;
      currentQuestionId;
    const token = sessionStorage.getItem("accessToken");

    if (token == null) {
      this.props.navigate("/dmat/login");
    }

    const headers = { Authorization: `Bearer ${token}` };
    await axios.get(url, { headers }).then((res) => {
      if (res.status === 251) {
        if (
          sessionStorage.getItem("accessToken") == null &&
          sessionStorage.getItem("refreshToken") == null
        ) {
          this.props.navigate("/dmat/login");
        } else {
          this.refreshToken(this.getQuestion);
        }
      } else if (res.status === 200) {
        this.setState({
          question: res.data.question_data,
        });
        const currentSection = res.data.question_data.section_name;
        if (currentSection === "Section 2 - Company Profile") {
          this.props.updateSection(currentSection);
          this.props.navigate("/dmat/CustomerDetailsQuestions");
        } else if (
          currentSection === "Section 3 - Organization Digital Maturity"
        ) {
          this.props.updateSection(currentSection);
          this.props.navigate("/dmat/assessment");
        } else if (currentSection === "Section 4 - Functional Maturity") {
          this.props.updateSection(currentSection);
          this.props.navigate("/dmat/functionalAssessment");
        }
      } else {
        console.log(res.data);
      }
    });
  };

  onChangeInput = (e) => {
    this.setState({
      radio: e.target.value,
      error: "",
    });
  };

  onClickButton = async () => {
    console.log("currnet index=", this.props.currentIndex);
    console.log("This.state2=", this.props.answers);
    console.log("radio=", this.state.radio);
    if (this.state.radio === "" || this.state.radio === undefined) {
      console.log("error");
      if (this.state.question.choice_details.length === 0) {
        this.setState({ error: "Please Enter an answer" });
      } else {
        this.setState({ error: "Please select an option" });
      }
      return;
    } else if (this.props.currentIndex + 1 < this.props.noOfQuestions) {
      const result = {
        id: this.state.question.ques_id,
        answer: this.state.radio,
        userComment: this.state.addText,
      };

      console.log("Result=", result);
      this.props.updateAnswer(result);
      this.props.updateQuestion("Next");

      console.log("line 236=", this.props.answers);
      console.log("result=", result);
      console.log("line 220=", this.state);
      this.setState({
        addText: "",
        radio: this.props.answers[this.props.currentIndex]?.user_choice,
      });

      this.submitAnswers(false);
    } else {
      const result = {
        id: this.state.question.ques_id,
        answer: this.state.radio,
      };
      this.props.updateAnswer(result);
      this.props.updateSection("Report");
    }
  };

  submitAnswers = async (is_complete) => {
    const url =
      process.env.REACT_APP_SERVER + "/customer/submit-survey-answers";
    const token = sessionStorage.getItem("accessToken");
    const apiData = {
      survey_answers: this.props.answers,
      is_complete,
      current_section:
        //  this.state.question &&
        this.state.question.section_name,
      current_ques_number: this.props.question_ids[this.props.currentIndex],
    };
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .post(url, apiData, { headers })
      .then((res) => {
        if (res.status === 251) {
          if (
            sessionStorage.getItem("accessToken") == null &&
            sessionStorage.getItem("refreshToken") == null
          ) {
            this.props.navigate("/dmat/login");
          } else {
            this.refreshToken(this.submitAnswers);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  submitAnswersFinal = async (is_complete, sectionName) => {
    const url =
      process.env.REACT_APP_SERVER + "/customer/submit-survey-answers";
    const token = sessionStorage.getItem("accessToken");
    const apiData = {
      survey_answers: this.props.answers,
      is_complete,
      current_section: sectionName,
      current_ques_number: this.props.question_ids[this.props.currentIndex],
    };
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .post(url, apiData, { headers })
      .then((res) => {
        if (res.status === 251) {
          if (
            sessionStorage.getItem("accessToken") == null &&
            sessionStorage.getItem("refreshToken") == null
          ) {
            this.props.navigate("/dmat/login");
          } else {
            this.refreshToken(this.submitAnswersFinal);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  prevQuestion = () => {
    console.log("line 313 count=", this.state.count);
    if (this.props.currentIndex === 0) {
      this.props.navigate("/dmat/Introduction");
    } else {
      // this.setState({
      //     radio: this.props.answers[this.props.currentIndex].user_choice,
      // });

      this.props.updateQuestion("Previous");
      this.setState({
        radio:
          this.props.answers[
            this.props.question_ids[this.props.currentIndex - 1]
          ]?.user_choice,
        error: "",
        addText:
          this.props.answers[
            this.props.question_ids[this.props.currentIndex - 1]
          ]?.user_comment,
      });

      // this.setState({
      //   // radio:
      //   //   this.props.answers[
      //   //     this.props.question_ids[this.props.currentIndex - 1]
      //   //   ],
      //   // addText: "",
      // });
      this.submitAnswers(false);
    }
  };

  render() {
    let answerError = this.state.error ? (
      <div className="text-danger custom-margin-form-control">
        {this.state.error}
      </div>
    ) : null;
    return (
      <div>
        <Navbar />
        <div className="container">
          <div
            className="w-full mx-auto mt-3 mb-3 p-3"
            style={{ width: "100%", backgroundColor: "#dfdfdf" }}
          >
            <div className="row text-left align-middle">
              <p className="theme-p-color col align-middle">
                <strong
                  style={{
                    backgroundColor: "#dfdfdf",
                    backdropFilter: "blur(5px)",
                    fontSize: "18px",
                  }}
                >
                  {this.state.question && this.state.question.question_number}.{" "}
                  {this.state.question &&
                    this.state.question.question_description}{" "}
                  {this.state.question?.question_help !== "" ? (
                    <Tooltip
                      questionHelp={this.state.question?.question_help}
                    />
                  ) : (
                    <></>
                  )}
                </strong>
              </p>
              <div className="col-1 p-2">
                <CircularProgressbar
                  value={
                    this.state.question
                      ? (this.props.maxProgress * 100) /
                        this.props.noOfQuestions
                      : 0
                  }
                  text={
                    this.state.question &&
                    `${
                      this.state.question
                        ? Math.round(
                            (this.props.maxProgress * 100) /
                              this.props.noOfQuestions
                          )
                        : 0
                    }%`
                  }
                  strokeWidth={8}
                />
              </div>
            </div>
            <div style={{ paddingLeft: "5%" }}>
              {this.props.answers === undefined
                ? console.log("Hii Sovan")
                : console.log("Hi Charan")}
              {this.state.question &&
              this.state.question.choice_details.length !== 0 ? (
                this.state.question.choice_details > 10 ? (
                  <div>
                    <select
                      class="form-select"
                      value={this.state.radio}
                      onChange={this.onChangeInput}
                    >
                      <option selected>Select Industry</option>
                      {this.state.question.choice_details.map(
                        (choice, index) => (
                          <option
                            key={index}
                            style={{
                              flex: index % 2,
                            }}
                            name="flexSelectDefault"
                            id="flexSelect1"
                            value={choice.key}
                          >
                            {choice.value}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                ) : (
                  <div style={this.state.choiceStyle}>
                    {this.state.question.choice_details.map((choice, index) => (
                      <div className="form-check" key={index}>
                        <input
                          style={{
                            flex: index % 2,
                          }}
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id={`flexRadio${index}`}
                          value={choice.key}
                          checked={this.state.radio === choice.key}
                          onChange={this.onChangeInput}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexRadio${index}`}
                        >
                          {choice.value}
                        </label>
                      </div>
                    ))}
                  </div>
                )
              ) : (
                <div className="form-group mt-3">
                  <label htmlFor="answer">Answer</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Answer"
                    value={this.state.radio}
                    id="answer"
                    onChange={(e) => {
                      this.onChangeInput(e);
                    }}
                  />
                </div>
              )}
              {answerError}
            </div>
          </div>
          <br />
          <div className="form-group mt-3">
            <label htmlFor="addText">Add Comment</label>
            <input
              type="text"
              className="form-control"
              placeholder="Add Comment"
              value={this.state.addText}
              id="addText"
              onChange={(e) => {
                this.setState({ addText: e.target.value });
              }}
            />
          </div>
          <br />
          <div className="row mt-3 d-flex justify-content-center">
            <div className="col-md-3">
              <div
                className="btn btn-primary mt-3 mb-auto float-end btn-lg"
                onClick={this.prevQuestion}
                style={{ borderRadius: "10px" }}
              >
                Back
              </div>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mt-3 btn-lg"
                onClick={this.onClickButton}
                style={{ borderRadius: "10px" }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { score } = state;
  return score;
};

const OneAssessmentNew = connect(mapStateToProps, {
  updateQuestion,
  getInitialScore,
  updateValues,
  updateAnswer,
  updateSection,
})(OneAssessment);

export default (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  return <OneAssessmentNew location={location} navigate={navigate} />;
};
