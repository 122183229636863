import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import video from '../assets/video/foreword.mp4';
import { Link } from 'react-router-dom';

class Foreword extends React.Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="container">
                    <div className="w-full mx-auto" style={{ width: "100%" }}>
                        <video className="" style={{
                            width: "50%",
                            height: "auto",
                            marginLeft: "25%",
                        }} autoPlay  >
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                    <textarea cols="5" rows="5" className='mt-3 form-control'>
                        Script goes here.
                    </textarea>
                    <div className='mt-3 p-3 text-center'>
                            <div className='mr-2'>
                                {/* <button
                                    type='submit'
                                    className='btn btn-primary mr-3 float-start'
                                    disabled={this.state.loading}
                                >Back</button> */}
                                <Link to='/dmat/register' className='btn btn-primary mr-3 inch-left'>Back</Link>
                                {/* <button
                                    type='submit'
                                    className='btn btn-primary float-end'
                                    disabled={this.state.loading}
                                >Next</button> */}
                                <Link to='/dmat/introduction' className='btn btn-primary mr-3 inch-right'>Next</Link>
                            </div>
                        </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Foreword;
