import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

class Assessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radio: "",
      error: "",
      questions: [],
      currentIndex: 0,
      answers: {},
      addText: "",
    };
  }

  componentDidMount() {
    this.getQuestions();
    this.getAnswers();
  }

  getAnswers = async () => {
    const url = process.env.REACT_APP_SERVER + "/customer/get-current-answers";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios.get(url, { headers }).then((res) => {
      if (res.status === 251) {
        this.refreshToken(this.getAnswers);
      } else if (res.status === 200) {
          console.log(res.data);
        this.setState({
          answers: res.data.surveyAnswers,
          currentIndex:
            res.data.currentSection === "ODM" ? (res.data.currentQuestion - this.state.questions[0].quesID) : 0,
        });
        if (res.data.currentSection === "FM")
          this.props.navigate("/dmat/functionalAssessment");
      }
    });
  };

  refreshToken = async (func) => {
    const url =
      process.env.REACT_APP_SERVER + "/customer/generate-access-token";
    const token = sessionStorage.getItem("refreshToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios.get(url, { headers }).then((res) => {
      if (res.status === 200) {
        sessionStorage.setItem("accessToken", res.data.accessToken);
      }
      func();
    });
  };

  onLogout = async () => {
    const token = sessionStorage.getItem("accessToken");
    const url =
      process.env.REACT_APP_SERVER + "/customer/submit-survey-answers";
    const apiData = {
      surveyAnswers: this.state.answers,
      isComplete: false,
      currentSection: "ODM",
      currentQuesNumber: this.state.questions[this.state.currentIndex].quesID,
    };
    const headers = { Authorization: `Bearer ${token}` };
    await axios.post(url, apiData, { headers }).then((res) => {
      if (res.status === 251) {
        this.refreshToken(this.onLogout);
      } else if (res.status === 200) {
        //Success
      } else {
        this.setState({
          error: true,
          errors: {
            submitAnswers: res.data.message,
          },
        });
      }
    });
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    this.props.navigate("/dmat");
  };

  getQuestions = async () => {
    const url = process.env.REACT_APP_SERVER + "/customer/get-odm-questions";
    await axios.get(url).then((res) => {
      this.setState({
        questions: res.data.questionData,
      });
    });
  };

  onChangeInput = (e) => {
    this.setState({
      radio: e.target.value,
      error: "",
    });
  };

  onClickButton = async () => {
    if (this.state.radio === "") {
      console.log("error");
      this.setState({ error: "Please select an option" });
      return;
    } else if (this.state.currentIndex + 1 < this.state.questions.length) {
      this.setState({
        answers: {
          ...this.state.answers,
          [this.state.questions[this.state.currentIndex].quesID]:
            this.state.radio,
        
        },
        radio: "",
        addText: "",
      });
        console.log(this.state.radio);
        console.log(this.state.currentIndex);
        console.log(this.state.questions[this.state.currentIndex + 1].quesNo);
        console.log(this.state.questions[0].quesID);
        console.log(this.state.answers);
        console.log(this.state.answers[this.state.questions[this.state.currentIndex + 1].quesNo+this.state.questions[0].quesID -1]);
      this.setState({
        radio:
          this.state.answers[
            this.state.questions[this.state.currentIndex + 1].quesNo+this.state.questions[0].quesID -1
          ] === "empty"
            ? ""
            : this.state.answers[
                this.state.questions[this.state.currentIndex + 1].quesNo+this.state.questions[0].quesID -1
              ],
        currentIndex: this.state.currentIndex + 1,
      });
    } else {
      this.setState({
        answers: {
          ...this.state.answers,
          [this.state.questions[this.state.currentIndex].quesID]:
            this.state.radio,
        },
      });
      this.setState({
        radio: "",
        addText: "",
      });
      const token = sessionStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_SERVER + "/customer/submit-survey-answers";
      const apiData = {
        surveyAnswers: this.state.answers,
        isComplete: false,
        currentSection: "ODM",
        currentQuesNumber: this.state.currentIndex,
      };
      const headers = { Authorization: `Bearer ${token}` };
      await axios.post(url, apiData, { headers }).then((res) => {
        if (res.status === 251) {
          console.log("logout")
          this.refreshToken(this.onLogout);
        } else if (res.status === 200) {
          //Success
            this.setState({radio:""});
        } else {
          this.setState({
            error: true,
            errors: {
              submitAnswers: res.data.message,
            },
          });
        }
      });
      this.props.navigate("/dmat/functionalAssessment");
    }
  };

  prevQuestion = () => {
    if (this.state.currentIndex === 0) {
      this.props.navigate("/dmat/CustomerDetailsQuestions");
    } else {
      this.setState({
        currentIndex: this.state.currentIndex - 1,
      });
    }
  };
  render() {
    let answerError = this.state.error ? (
      <div className='text-danger custom-margin-form-control'>
        {this.state.error}
      </div>
    ) : null;
    return (
      <div>
        <Navbar />
        <div className='container'>
          <div className='w-full mx-auto mt-3 mb-3' style={{ width: "100%", border:'solid' }}>
            <p className='theme-p-color'>
              <strong>
                {this.state.questions.length > 0 &&
                  this.state.questions[this.state.currentIndex].quesNo}
                .{" "}
                {this.state.questions.length > 0 &&
                  this.state.questions[this.state.currentIndex]
                    .questionDescription}
              </strong>
            </p>
            <div style={{paddingLeft:"5%"}}>
            {this.state.questions.length > 0 &&
              this.state.questions[this.state.currentIndex].choiceDetails &&
              this.state.questions[this.state.currentIndex].choiceDetails.map(
                (choice, index) => (
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='flexRadioDefault'
                      id='flexRadioDefault1'
                      value={choice.key}
                      checked={this.state.radio === choice.key}
                      onChange={this.onChangeInput}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='flexRadioDefault1'
                    >
                      {choice.key}
                      {". "}
                      {choice.value}
                    </label>
                  </div>
                )
              )}
            {answerError}
          </div>
          </div>
          <br />
          <div className='form-group mt-3'>
            <label htmlFor='addText'>Add Text</label>
            <input
              type='text'
              className='form-control'
              placeholder='Add Text'
              value={this.state.addText}
              id='addText'
              onChange={(e) => {
                this.setState({ addText: e.target.value });
              }}
            />
          </div>
          <br />
          <div className='row mt-3'>
            <div className='col-md-3'>
              <div
                className='btn btn-primary mt-3 mb-auto float-end'
                onClick={this.prevQuestion}
              >
                Back
              </div>
            </div>
            <div className='col-md-3'>
              <button
                className='btn btn-primary mt-3'
                onClick={this.onClickButton}
              >
                Next
              </button>
            </div>
            <div className='col-md-3'>
              <CircularProgressbar
                value={
                  this.state.questions.length > 0
                    ? ((this.state.questions[0].quesID + this.state.currentIndex) * 100) /
                      25
                    : 0
                }
                text={
                  this.state.questions.length > 0 &&
                  `${
                    this.state.questions.length > 0
                      ? ((this.state.questions[0].quesID + this.state.currentIndex) * 100) /
                        25
                      : 0
                  }%`
                }
                strokeWidth={8}
              />
            </div>
            <div className='col-md-3'>
              <button className='btn btn-primary mt-3' onClick={this.onLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <Assessment navigate={navigate} />;
};
