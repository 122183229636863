import styles from '../styles'
import { View, Text, Image } from '@react-pdf/renderer'
import fourkeyImg from '../../assets/images/fourkey.png';
import Header from './Header'
import Footer from './Footer'

export const Page4 = ({ about }) => {
  return (
    <View style={styles.section_right}>
      <Header />
      <Text style={styles.heading}>Digital Transformation for Manufacturing </Text>
      <Text style={{ fontSize: '14', marginTop: '10', marginBottom: '10', paddingRight: '40', paddingLeft: '60' }}>Introduction</Text>

      <Text style={{ lineHeight: 1.3, color: 'gray', fontSize: '12', marginTop: '10', marginBottom: '20', paddingRight: '40', paddingLeft: '60' }}>Based on our research and insights working with 20+ manufacturing organizations, we have identified 4 components that are critical to success in digital transformation. These are shown in the figure below.
      </Text>

      <Image src={fourkeyImg} style={{ width: "95%", paddingLeft: '60', border: 'rounded' }} alt="" />
      <Text style={{ paddingLeft: '70', fontSize: '8', color: 'gray', paddingBottom: "10" }}>Fig 1: Four Key Differentiations for Successful Digital Transformation, © Edify.AI India Pvt Ltd 2022</Text>

      <View style={{ marginTop: '10', marginBottom: '10', marginRight: '20', paddingRight: '40', paddingLeft: '60', flexDirection: "row" }}>
        <Text style={{ color: "black", fontSize: '12', marginHorizontal: 8 }}>1.</Text>
        <Text style={{ lineHeight: 1.3, color: "gray", fontSize: '12' }}><Text style={{ color: "black", fontSize: '12' }}>Digital Vision & Strategy: </Text>There is leadership understanding and buy-in for digital investments. Organization has developed a roadmap for digital transformation leveraging its unique competitive advantages</Text>
      </View>
      <View style={{ marginTop: '10', marginBottom: '10', marginRight: '20', paddingRight: '40', paddingLeft: '60', flexDirection: "row", }}>
        <Text style={{ color: "black", fontSize: '12', marginHorizontal: 8 }}>2.</Text>
        <Text style={{ lineHeight: 1.3, color: "gray", fontSize: '12' }}><Text style={{ color: "black", fontSize: '12' }}>Data & Analytics: </Text>  Ability of the organization to generate and utilize high quality data for better decision making</Text>
      </View>
      <View style={{ marginTop: '10', marginBottom: '10', marginRight: '20', paddingRight: '40', paddingLeft: '60', flexDirection: "row", }}>
        <Text style={{ color: "black", fontSize: '12', marginHorizontal: 8 }}>3.</Text>
        <Text style={{ lineHeight: 1.3, color: "gray", fontSize: '12' }}><Text style={{ color: "black", fontSize: '12' }}>Organization & Skills:</Text> Specialized digital roles have been created and role based digital skills development has been rolled out across the organization</Text>
      </View>
      <View style={{ marginTop: '10', marginBottom: '10', marginRight: '20', paddingRight: '40', paddingLeft: '60', flexDirection: "row", }}>
        <Text style={{ color: "black", fontSize: '12', marginHorizontal: 8 }}>4.</Text>
        <Text style={{ lineHeight: 1.3, color: "gray", fontSize: '12' }}><Text style={{ color: "black", fontSize: '12' }}>Process &amp; Technology:</Text> Digital is increasingly adopted and driven at the “edges” by the
          business. This requires greater flexibility and agility through new technology adoption
          and process transformation</Text>
      </View>
      <Footer pgnum='4' />
    </View>
  )
}