import React from "react";
import {
  validateEmail,
  validateCompanyName,
  containsChars,
  containsSpecialChars,
} from "../../utils";
import image from "../../assets/images/Silfra_Logo.png";
import {
  Link,
  Navigate,
  NavLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import axios from "axios";

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form: {
        name: "",
        company_name: "",
        mob: "",
      },
      error: true,
      errors: {},
      redirect: false,
    };
  }

  async componentDidMount() {}

  refreshToken = async (func) => {
    const url =
      process.env.REACT_APP_SERVER + "/customer/generate-access-token";
    const token = sessionStorage.getItem("refreshToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios.get(url, { headers }).then((res) => {
      if (res.status === 200) {
        sessionStorage.setItem("accessToken", res.data.accessToken);
      }
      func();
    });
  };

  onChangePhone = (value, country, e, formattedValue) => {
    this.setState({
      form: {
        ...this.state.form,
        mob: formattedValue,
      },
    });
    if (formattedValue.length < 10) {
      this.setState({
        error: true,
        errors: {
          ...this.state.errors,
          mob: "Please Enter Valid Mobile Number",
        },
      });
    } else {
      const { mob, ...rest } = this.state.errors;
      this.setState({
        error: false,
        errors: rest,
      });
    }
    console.log("lin 68 ", this.state.errors);
  };

  onValidate = (key, value) => {
    if (
      key === "name" &&
      (value === "" || !containsChars(value) || containsSpecialChars(value))
    ) {
      this.setState({
        form: {
          ...this.state.form,
          [key]: value,
        },
        errors: {
          ...this.state.errors,
          name: "Please enter a valid name.",
        },
        error: true,
      });
      return;
    } else {
      const { name, ...rest } = this.state.errors;
      this.setState({
        error: false,
        errors: rest,
      });
    }

    if (key === "company_name") {
      if (
        value === "" ||
        value.length < 3
        //        ||
        //       validateCompanyName(value)
      ) {
        this.setState({
          form: {
            ...this.state.form,
            [key]: value,
          },
          errors: {
            ...this.state.errors,
            company_name: "Please enter a valid company name.",
          },
          error: true,
        });
        return;
      } else {
        const { company_name, ...rest } = this.state.errors;
        this.setState({
          error: false,
          errors: rest,
        });
      }
    }
    console.log("line 110", this.state.errors);

    this.setState({
      //   errors: {},
      error: false,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
  };
  onChangeInput = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    this.onValidate(key, value);
  };

  onClickHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let error = false;
    let errors = {};
    //         value === '' || !containsChars(value) || containsSpecialChars(value);
    if (
      this.state.form.name === "" ||
      !containsChars(this.state.form.name) ||
      containsSpecialChars(this.state.form.name)
    ) {
      error = true;
      errors.name = "Please enter a valid name.";
    }
    if (this.state.form.company_name === "") {
      error = true;
      errors.company_name = "Please enter a valid company name.";
    }
    if (this.state.form.mob === "") {
      error = true;
      errors.mob = "Please enter the mobile number.";
    }
    // email.
    if (this.state.form.email === "" || !validateEmail(this.state.form.email)) {
      error = true;
      errors.email = "Please use official email id.";
    }
    if (error) {
      this.setState({
        error: true,
        errors,
      });
      return;
    }
    // check if this.state.errors is empty object
    if (error === false) {
      const token = this.props.token;
      const url =
        process.env.REACT_APP_SERVER + "/user/enter-details?token=" + token;
      const apiData = {
        mob_no: this.state.form.mob,
        company_name: this.state.form.company_name,
        name: this.state.form.name,
      };

      axios
        .post(url, apiData)
        .then((res) => {
          if (res.status === 251) {
          } else if (res.status === 200) {
            const { message, response, access_token, refresh_token } = res.data;

            sessionStorage.setItem("accessToken", access_token);
            sessionStorage.setItem("refreshToken", refresh_token);
            if (response !== "Success") {
              toast.success("Registration failed");
            } else {
              toast.success(message);
            }
            this.props.navigate("/dmat/admin/login");
          } else {
            console.log(res);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to="/dmat/admin/surveyQuestionManagement" />;
    }
    let NameError = this.state.errors.name ? (
      <div className="text-danger custom-margin-form-control">
        {this.state.errors.name}
      </div>
    ) : null;
    let companyNameError = this.state.errors.company_name ? (
      <div className="text-danger custom-margin-form-control">
        {this.state.errors.company_name}
      </div>
    ) : null;
    let MobError = this.state.errors.mob ? (
      <div className="text-danger custom-margin-form-control">
        {this.state.errors.mob}
      </div>
    ) : null;

    return (
      <div>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="container collapse navbar-collapse"
                id="navbarTogglerDemo01"
              >
                <NavLink
                  className="navbar-brand"
                  to="#!"
                  activeClassName="active"
                >
                  <img
                    style={{ width: "20%", height: "20%" }}
                    src={image}
                    alt="C4i4 Logo"
                  />
                </NavLink>
                <div>
                  <h1 style={{ color: "royalblue" }}>Admin Login </h1>
                </div>
              </div>
            </div>
          </nav>
          <br />
        </div>
        <div className="container mt-3 mb-3">
          <form className="mx-auto w-100 reg">
            <div className="row g-3 align-items-center mt-3">
              <div className="col-md-2">
                <label htmlFor="email" className="col-form-label">
                  <strong>
                    Name <span className="text-danger"> *</span>
                  </strong>
                </label>
              </div>
              <div className="col-md-10">
                <input
                  type="name"
                  className="form-control custom-margin-form-control"
                  id="name"
                  value={this.state.form.name}
                  name="name"
                  onChange={this.onChangeInput}
                />
                {NameError}
              </div>
            </div>

            <div className="row g-3 align-items-center mt-2">
              <div className="col-md-2">
                <label htmlFor="cname" className="col-form-label">
                  <strong>
                    Company Name <span className="text-danger"> *</span>
                  </strong>
                </label>
              </div>
              <div className="col-md-10">
                <input
                  type="cname"
                  value={this.state.form.company_name}
                  className="form-control custom-margin-form-control"
                  id="cname"
                  name="company_name"
                  onChange={this.onChangeInput}
                />
                {companyNameError}
              </div>
            </div>

            <div className="row g-3 align-items-center mt-2">
              <div className="col-md-2">
                <label htmlFor="mob" className="col-form-label">
                  <strong>
                    Mob# <span className="text-danger"> *</span>
                  </strong>
                </label>
              </div>
              <div className="col-md-10">
                <PhoneInput
                  country={"in"}
                  value={this.state.form.mob}
                  onChange={this.onChangePhone}
                  inputClass=" custom-margin-form-control"
                />
                {MobError}
              </div>
            </div>

            <div className="row g-3 align-items-center mt-2">
              <strong className="text-danger">
                Fields marked with (*) are mandatoryy
              </strong>
            </div>

            <div className="p-1 text-center">
              <div className="mr-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.onClickHandler}
                >
                  Activate Account
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default (props) => {
  const navigate = useNavigate();
  let { token } = useParams();

  return <Registration token={token} navigate={navigate} />;
};
