import Navbar from "./Navbar";
import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  getAllCustomerDetails,
  getCustomerOverview,
} from "../../redux/functions/customers";
import Loader from "../../components/Loader";
import axios from "axios";
import { toast } from "react-toastify";

//Register elements onto chartJS
ChartJS.register(ArcElement, Tooltip, Legend);

export class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyValue: 1,
      data: {
        labels: ["Complete", "InComplete", "Not Started"],
        datasets: [
          {
            data: [1, 1, 1],
            label: "Customer completion Report",
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 255, 0, 0.6)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 255, 0, 0.6)",
            ],
            borderWidth: 1,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({
      complete: this.props.customerCount.complete,
      inComplete: this.props.customerCount.inComplete,
      notStarted: this.props.customerCount.notStarted,
      data: {
        labels: ["Complete", "InComplete", "Not Started"],
        datasets: [
          {
            data: [1, 1, 1],
            label: "Customer completion Report",
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 255, 0, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 255, 0, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
    });

    console.log(this.state);
    this.props.getCustomerOverview();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerCount !== this.props.customerCount) {
      console.log(prevProps);
      console.log(this.state);
      this.setState({
        data: {
          labels: ["Complete", "InComplete", "Not Started"],
          datasets: [
            {
              data: [
                this.props.customerCount.complete,
                this.props.customerCount.inComplete,
                this.props.customerCount.notStarted,
              ],
              label: "Customer completion Report",
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 255, 0, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 255, 0, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        complete: this.props.customerCount.complete,
        inComplete: this.props.customerCount.inComplete,
        notStarted: this.props.customerCount.notStarted,
        keyValue: this.state.keyValue + 1,
      });
    }
  }

  //Data for ChartJS
  data = {
    labels: ["Complete", "InComplete", "Not Started"],
    datasets: [
      {
        data: [
          this.props.customerCount.complete,
          this.props.customerCount.inComplete,
          this.props.customerCount.notStarted,
        ],
        label: "Customer completion Report",
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 5, 112, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 5, 112, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  };

  handleAllCustomers = () => {
    this.props.navigate("/admin/customerDetails/all");
  };

  handleNotStarted = () => {
    this.props.navigate("/admin/customerDetails/notStarted");
  };

  handleInComplete = () => {
    this.props.navigate("/admin/customerDetails/inComplete");
  };

  handleComplete = () => {
    this.props.navigate("/admin/customerDetails/complete");
  };

  notifyToComplete = async () => {
    const url = process.env.REACT_APP_SERVER + "/user/notify-to-complete";
    const token = sessionStorage.getItem("accessToken");

    const token1 = sessionStorage.getItem("refreshToken");
    const headers = { Authorization: `Bearer ${token}` };
    axios
      .get(url, { headers })
      .then((res) => {
        console.log(res);
        if (res.status === 200 || res.status === 201) {
          toast.success("Email sent successfully");
        } else {
          toast.error("In maintenance mode");
          console.log("line183", res);
        }
      })
      .err((err) => console.log(err));
  };

  notifyToStart = async () => {
    const url = process.env.REACT_APP_SERVER + "/user/notify-to-start";
    const token = sessionStorage.getItem("accessToken");

    const token1 = sessionStorage.getItem("refreshToken");
    const headers = { Authorization: `Bearer ${token}` };
    axios
      .get(url, { headers })
      .then((res) => {
        console.log(res);

        if (res.status === 200 || res.status === 201) {
          // toast.success('Email sent successfully');
          toast.success(`${res.data.message}`);
        } else {
          toast.error("In maintenance mode");
          console.log("line183", res);
        }
      })

      .err((err) => console.log(err));
  };

  render() {
    if (this.props.loading === true) {
      return <Loader />;
    } else {
      return (
        <>
          <Navbar />
          <main className="container">
            <table className="table" style={{ height: "fit-content" }}>
              <thead>
                <tr>
                  <th scope="col">Total</th>
                  <th scope="col">Complete</th>
                  <th scope="col">In-Complete</th>
                  <th scope="col">Not Started</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td onClick={this.handleAllCustomers}>
                    {this.props.customerCount.total}
                  </td>
                  <td onClick={this.handleComplete}>
                    {this.props.customerCount.complete}
                  </td>
                  <td onClick={this.handleInComplete}>
                    {this.props.customerCount.inComplete}
                  </td>
                  <td onClick={this.handleNotStarted}>
                    {this.props.customerCount.notStarted}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              <div className=" p-4" style={{ width: "50%" }}>
                <Pie
                  //   style={{ height: "200px" }}
                  data={this.state.data}
                  key={this.state.keyValue}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <button
                  className="btn btn-primary m-2"
                  onClick={this.notifyToStart}
                >
                  Notify to Start
                </button>
              </div>
              <div>
                <button
                  className="btn btn-primary m-2"
                  onClick={this.notifyToComplete}
                >
                  Notify to Complete
                </button>
              </div>
            </div>
          </main>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { customers } = state;
  return customers;
};

const mapDispatchToProps = {
  getAllCustomerDetails,
  getCustomerOverview,
};

const ReduxCustomerDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetails);

export default (props) => {
  const navigate = useNavigate();
  return <ReduxCustomerDetails navigate={navigate} />;
};
