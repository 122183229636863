import { View, Text, Image } from "@react-pdf/renderer";
import styles from "../styles";

const Footer = (pgnum) => {
  let pageNum = pgnum;
  console.log(pgnum);

  return (
    <View style={styles.footer}>
      <Text style={{ textAlign: "right" }}>{pgnum["pgnum"]}</Text>
      <Text
        style={{
          marginLeft: 38,
          textAlign: "left",
          color: "black",
          fontSize: "10px",
        }}
      >
        {" "}
        ©Silfra Technologies. 2024{" "}
      </Text>
    </View>
  );
};

export default Footer;
