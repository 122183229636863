import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";

class Introduction extends React.Component {
  updateDataHandler = async () => {
    const url =
      process.env.REACT_APP_SERVER + "/customer/submit-survey-answers";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    const updateData = {
      surveyAnswers: this.props.answers,
      isComplete: false,
      currentQuesNumber: this.props.currentQuestion,
      currentSection: "Section 2 - Company Profile",
    };

    await axios
      .post(url, updateData, { headers })
      .then((res) => {
        if (res.status === 251) {
          this.refreshToken(this.updateDataHandler);
        } else if (res.status !== 200) {
          console.log(res);
        } else {
          console.log("success");
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  refreshToken = async (func) => {
    const url =
      process.env.REACT_APP_SERVER + "/customer/generate-access-token";
    const token = sessionStorage.getItem("refreshToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios.get(url, { headers }).then((res) => {
      if (res.status === 200) {
        sessionStorage.setItem("accessToken", res.data.accessToken);
      }
      func();
    });
  };

  onClickHandler = async () => {
    await this.updateDataHandler();
    this.props.navigate("/dmat/CustomerDetailsQuestions");
  };

  render() {
    return (
      <div>
        <Navbar />
        <div className='container mb-3'>
          <p>
            This is a short assignment which will help to gauge your
            Organization Digital Maturity along 4 axies:
            <ol className='mt-3 mb-3'>
              <li>Digital Vision &amp; Strategy</li>
              <li>Data &amp; Analytics</li>
              <li>Organization &amp; Skills</li>
              <li>Process &amp; Technology</li>
            </ol>
            <span className=''>
              There are a series of multiple-choice questions. At any time you
              can navigate to the previous screen using "Back" Button You may
              exit the assessment at any stage and you can retry where you left
              off.
            </span>
            <span className='mt-3'>
              The assessment is divided into 5 sections:
              <ol className='mt-3 mb-3'>
                <li>Introduction</li>
                <li>Basic Details</li>
                <li>Company Details</li>
                <li>Overall Digital Maturity</li>
                <li>Functional Maturity</li>
              </ol>
            </span>
            <span className='mt-3'>
              At the end of the assessment a Digital Score is automatically
              generated by the tool. You will recieve an e-book which gives you
              maturity score and also benchmarks your score to your industry
              average on your official e-mail. If you have any further questions
              please feel free to send your query to John@domain.com
            </span>
          </p>
          <div className='text-center'>
            <div
              onClick={this.onClickHandler}
              className='btn btn-primary mt-3 mx-auto'
            >
              Start Assessment
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { score } = state;
  return score;
};

const ReduxIntroduction = connect(mapStateToProps)(Introduction);

export default (props) => {
  const navigate = useNavigate();
  return <ReduxIntroduction navigate={navigate} />;
};
