import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { PureComponent } from "react";
import Gauge from "react-svg-gauge";
import image from "../assets/images/Silfra_Logo.png";
import topImage from "../assets/images/topImg.png";
import fourkeyImg from "../assets/images/fourkey1.png";
//import {useLocation} from 'react-router-dom';
import {
  PieChart,
  Pie,
  Sector,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
{
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;
}

const DigTrans = (props) => {
  const [dmScore, setDmScore] = useState(0);
  const [fmS, setFmS] = useState(0);
  const [deno, setDeno] = useState(0);
  const [tScore, setTScore] = useState(0);

  useEffect(() => {
    getScore();
  }, []);

  const getScore = async () => {
    const url = process.env.REACT_APP_SERVER + "/customer/get-survey-score";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios.get(url, { headers }).then((res) => {
      if (res.status === 251) {
      } else if (res.status === 200) {
        const { surveyResults } = res.data;
        setDmScore(
          ((surveyResults.odmScore * 100) / surveyResults.odmMaxScore).toFixed(
            2
          )
        );
        setFmS(
          ((surveyResults.fmScore * 100) / surveyResults.fmMaxScore).toFixed(2)
        );
        setDeno(surveyResults.odmMaxScore + surveyResults.fmMaxScore);
        setTScore(
          (
            ((surveyResults.odmScore + surveyResults.fmScore) * 100) /
            (surveyResults.odmMaxScore + surveyResults.fmMaxScore)
          ).toFixed(2)
        );
      }
    });
  };

  const query = new URLSearchParams(window.location.search);
  console.log(window.location);
  //const { odmScore, /*odmMaxScore,*/ fmScore, /*fmMaxScore*/ } = route.params;
  //const { odmScore, fmScore } = useLocation();
  //console.log(odmScore);
  //console.log(odmMaxScore);
  //console.log(fmScore);
  //console.log(fmMaxScore);
  // let dmScore = (
  //   (query.get("odmScore") * 100) /
  //   query.get("odmMaxScore")
  // ).toFixed(2); //odmScore;
  // console.log(dmScore);
  // let fmS = ((query.get("fmScore") * 100) / query.get("fmMaxScore")).toFixed(2); //fmScore;
  // console.log(fmS);
  // let deno = query.get("odmMaxScore") + query.get("fmMaxScore");
  // let tScore = (
  //   ((query.get("odmScore") + query.get("fmScore")) * 100) /
  //   deno
  // ).toFixed(2);
  //let dmScore = (odmScore/odmMaxScore)*100;
  //let fmS = (fmScore/fmMaxScore)*100;
  //let tScore = ((odmScore+fmScore)/(odmMaxScore+fmMaxScore))*100;
  // Vertical Bar Chart
  const data = [
    {
      name: "Digital Maturity",
      uv: fmS,
      pv: 0,
      amt: tScore,
    },
    {
      name: "Functional Maturity",
      uv: dmScore,
      pv: 0,
      amt: tScore,
    },
  ];

  // Vertical Bar Chart

  // Horizontal Bar Chart
  const Hordata = [
    {
      name: "Industry Median",
      "Score(%)": 35,
    },
    {
      name: "Your Org Score",
      "Score(%)": tScore,
    },
  ];

  // Horizontal Bar Chart (hardcode to 35 and 60 % respectivley)

  // Pie chart start

  const Piedata = [
    { name: "Total Maturity Score", value: tScore },
    { name: "B", value: 100 - tScore },
  ];
  const COLORS = ["#0088FE", "gray"];

  // Pie chart end
  const printheader = () => {
    return (
      <header className="standardReportHeader">
        <div>
          <img
            className="headerLogo"
            style={{ width: "7%", height: "7%" }}
            src={image}
            alt="C4i4 Logo"
          />
          <span className="headerTitle"> Digital Transformation E-Book </span>
          <span className="headerDate">{today}</span>
        </div>
      </header>
    );
  };
  const printfooter = (pgnum) => {
    return (
      <footer className="standardReportFooter">
        <div>
          <div className="pgno">{pgnum}</div>
          <span className="footerTitle"> ©Silfra Technologies. 2024 </span>
          <span></span>
        </div>
      </footer>
    );
  };

  return (
    <div className="Main">
      <div className="Img">
        <img style={{ width: "52.5%" }} src={topImage} alt="" />
      </div>
      {/* 1st page starts */}

      <div className="FirstMid Page">
        <div className="pageInnerContainer">
          {printheader()}
          <div className="para2 contentsPage">
            <p className="firstpara heading2 themeColor">Contents</p>
            <p className="p">
              Glossary of Terms Used
              ...........................................................................................
              <span>2</span>
            </p>
            <p className="p">
              List of Figures:
              ..........................................................................................................2
            </p>
            <p className="p">
              Introduction..............................................................................................................3
            </p>
            <p className="p">
              Digital Maturity in Indian Manufacturing
              Organizations..........................................4
            </p>
            <p className="p">
              How to read this Assessment Scores?
              ....................................................................6
            </p>
            <p className="p">
              Performance of Your Organization
              ...........................................................................7
            </p>
            <p className="p">
              Overall Maturity Score (Refer Fig 2 for details)
              .......................................................7
            </p>
            <p className="p">
              Break-up of Overall Score: Digital and Functional
              Maturity....................................7
            </p>
            <p className="p">
              Benchmark of Overall Maturity Score with Industry
              ..............................................8
            </p>
            <p className="p">
              Maturity
              Chart..........................................................................................................8
            </p>
            <p className="p">
              How can we help?
              ..................................................................................................10
            </p>
          </div>
          {printfooter(1)}
        </div>
      </div>
      {/* 1st page ends */}
      {/* 2nd page starts here */}

      <div className="SecondMid Page">
        <div className="pageInnerContainer">
          {printheader()}
          <div className="para2">
            <p className="firstpara heading2 themeColor">
              Glossary of Terms Used
            </p>
            <p className="p2">Glossary of Terms Used </p>
            <p className="p2">OEM: Original Equipment Manufacturer</p>
            <p className="p2">DT: Digital Transformation</p>
            <p className="p2">OEE: Original Equipment Effectiveness</p>
            <p className="p2">IT: Information Technology</p>
            <p className="p2">OT: Operational Technology</p>
            <p className="p2">AI: Artificial Intelligence</p>
            <p className="p2">ML: Machine Learning</p>
            <p className="p2">HR: Human Resource</p>
            <p className="firstpara heading2 themeColor">List of Figures:</p>
            <p className="p2">
              Fig 1: Four Key Differentiations for Successful Digital
              Transformation..............3
            </p>
            <p className="p2">
              {" "}
              <p className="p">
                Fig 2: Overall Maturity
                Chart...............................................................................9
              </p>
            </p>
          </div>
          {printfooter(2)}
        </div>
      </div>
      {/* 2nd page ends */}

      {/* 3rd page starts here */}

      <div className="ThirdMid Page">
        <div className="pageInnerContainer">
          {printheader()}
          <div className="para3">
            <p className="Digitalpara heading2">
              Digital Transformation for Manufacturing{" "}
            </p>
            <p className="p3">
              <strong className="strng"> Introduction</strong>{" "}
            </p>
            <p className="p3">
              Based on our research and insights working with 20+ manufacturing
              organizations, we have identified 4 components that are critical
              to success in digital transformation. These are shown in the
              figure below.
            </p>

            <img src={fourkeyImg} style={{ width: "100%" }} alt="" />

            <p className="p3">
              <strong className="strng">1. Digital Vision & Strategy:</strong>{" "}
              There is leadership understanding and buy-in for digital
              investments. Organization has developed a roadmap for digital
              transformation leveraging its unique competitive advantages
            </p>
            <p className="p3">
              <strong className="strng">2. Data & Analytics:</strong> Ability of
              the organization to generate and utilize high quality data for
              better decision making
            </p>
            <p className="p3">
              <strong className="strng">3. Organization & Skills:</strong>{" "}
              Specialized digital roles have been created and role based digital
              skills development has been rolled out across the organization
            </p>
            <p className="p3">
              <strong className="strng">3. Organization & Skills:</strong>{" "}
              Specialized digital roles have been created and role based digital
              skills development has been rolled out across the organization
            </p>
          </div>
          {printfooter(3)}
        </div>
      </div>
      {/* 3rd page ends */}

      {/* 4th page starts here */}

      <div className="FourthMid Page">
        <div className="pageInnerContainer">
          {printheader()}
          <div className="para4">
            <p className="Digitalpara heading2">
              Digital Maturity in Indian Manufacturing Organizations{" "}
            </p>
            <p className="p3" style={{ FontWeight: "small" }}>
              {" "}
              This section provides a summary of the digital maturity along the
              4 components mentioned in section 1.{" "}
            </p>
            {/* ordered list left starts */}
            <div className="OrderedlistMain">
              <div className="Orderedlist">
                <ol className="ol1">
                  <li className="li1">
                    {" "}
                    Digital Vision and Strategy:
                    <ul type="disc" className="ul1">
                      <li className="li1">
                        Digital Strategy is being owned and driven mostly by
                        senior leadership consisting of CEO’s, BU Heads and
                        MD’s. All the organizations interviewed have some
                        digital investments planned but these vary widely
                        depending mainly on the current maturity of the
                        organizations
                      </li>
                      <li className="li1">
                        The awareness of digital technologies is not very high
                        and the investments on digital is being done for
                        reducing operating costs
                      </li>
                      <li className="li1">
                        Most common KPI’s from a Digital Transformation
                        perspective are employee productivity, improving machine
                        utilization and reducting quality rejects.
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
              {/* ordered list left ends */}
              {/*  ordered list right starts */}
              <div className="OrderedlistRight">
                <p className="hrPara">
                  85% of the organizations that we have assessed have their
                  CEO’s or Business Heads driving digital transformation. Most
                  have not hired digital senior leadership
                </p>
                <p className="hrPara">
                  Digital Related investments planned vary widely from INR 5
                  lacs to INR 30 Cr in the organizations assessed. The
                  investment is highly correlated to the overall digital
                  maturity of the organization
                </p>

                <p className="hrBottom hrBottomContainer">
                  <h5 className="hrBottom">
                    GOALS FROM DIGITAL INVERSTMENTS IN NEXT 3 YEARS
                  </h5>
                  <p className="hrBottom">
                    Quality Rejects ....................7%
                  </p>
                  <p className="hrBottom">
                    OEE ....................................10%
                  </p>
                  <p className="hrBottom">
                    Quality Rejects ..................15%
                  </p>
                </p>
              </div>
            </div>
            {/* ordered list right ends */}
          </div>
          {printfooter(4)}
        </div>
      </div>

      {/* 4th page ends */}

      {/* 5th page starts here */}

      <div className="FifthMid Page">
        <div className="pageInnerContainer">
          {printheader()}
          <div className="para4">
            <br />
            <br />
            <br />

            {/* ordered list left starts */}
            <div className="OrderedlistMain">
              <div className="Orderedlist">
                <ol className="ol1" start={2}>
                  <li className="li1">
                    {" "}
                    Data & Analytics:
                    <ul type="disc" className="ul1">
                      <li className="li1">
                        {" "}
                        Usage of data is mostly being done for reporting
                        purposes. Leveraging analytics for data based decision
                        making in the organizations is limited. It is a culture
                        challenge as much as a technology or skills gap
                      </li>
                      <li className="li1">
                        IO/OT integrations have not been initiated in any of the
                        organizations assessed and it remains a key challenge
                        identified by most organizations
                      </li>
                      <li className="li1">
                        {" "}
                        In most organizational data resides in siloes and the
                        companies have not invested in technologies like data
                        warehouse or data lakes to integrate the data and make
                        it available for analysis across the organization
                      </li>
                    </ul>
                  </li>
                  <br />
                  <br />
                  <li className="li1">
                    {" "}
                    Organization & Skill Building:
                    <ul type="disc" className="ul1">
                      <li className="li1">
                        {" "}
                        Organizations have started investing in building digital
                        skills within the technology organization
                      </li>
                      <li className="li1">
                        Most of the organizations don’t have formal, role-based
                        training to build digital skills in the business teams
                      </li>
                    </ul>
                  </li>

                  <br />
                  <br />

                  <li className="li1">
                    {" "}
                    Process and Technology:
                    <ul type="disc" className="ul1">
                      <li className="li1">
                        {" "}
                        Most organizations have made some investment in putting
                        sensors and collecting data from machines
                      </li>
                      <li className="li1">
                        There is a lack of readiness to adopt digital
                        technologies owing to high dependency on manual
                        processes & is considered risky to realign the processes
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
              {/* ordered list left ends */}
              {/*  ordered list right starts */}
              <div className="OrderedlistRight" style={{ marginTop: "140px" }}>
                <p className="hrPara">
                  Lack of standardization of OT systems is quoted as a key
                  challenge to IT/OT integration
                </p>

                <p className="hrPara" style={{ marginTop: "200px" }}>
                  Only 35% of the organizations assessed have either hired or
                  have upskilled their technology teams internally to drive
                  digital projects
                </p>

                <p className="hrPara" style={{ marginTop: "70px" }}>
                  IoT for machines has emerged as a common use case for the
                  organizations assessed. This has been driven mostly by
                  equipment OEM's.
                </p>
              </div>
            </div>
            {/* ordered list right ends */}
          </div>
          {printfooter(5)}
        </div>
      </div>

      {/* 5th page ends */}

      {/* 6th page starts here */}

      <div className="ThirdMid Page">
        <div className="pageInnerContainer">
          {printheader()}
          <div className="para3">
            <p className="Digitalpara heading2">
              How to read this Assessment Scores?{" "}
            </p>
            <p className="p3">The assessment is broken into 3 sections:</p>

            <p className="p3">
              <strong className="strng">1. Digital Maturity:</strong> The
              Digital Maturity section assesses the overarching maturity of the
              organization based on the 4 components listed in the sections
              above.
            </p>
            <p className="p3">
              <strong className="strng">2. Functional Maturity: </strong> The
              functional maturity section assesses the functional maturity of
              the core processes including order management, vendor management
              and supply chain, production planning, core production and asset
              maintenance.
            </p>
            <p className="p3">
              <strong className="strng">3. Overall Maturity:</strong> The
              overall maturity is arrived by aggregating the results from
              Digital Maturity and Functional Maturity sections. We assess the
              overall maturity at 5 levels. Figure 2 provides details of the
              maturity levels
            </p>
            <br />
            <p className="p3">
              We also provide a comparison of your Overall Maturity Score and
              the median score of your industry peers. The industry sample of
              companies which have been assessed by C4i4 which includes Tier 1&
              Tier 2 Auto Comp companies.
            </p>
          </div>
          {printfooter(6)}
        </div>
      </div>
      {/* 6th page ends */}

      {/* 7th page starts here */}

      <div className="SeventhMid Page">
        <div className="pageInnerContainer">
          {printheader()}
          <div className="para2">
            <p className="HeadingCharts heading2">
              Performance of Your Organization
            </p>
            <p className="p2">
              {" "}
              <p className="p">
                Benchmark of Overall Maturity Score with Industry{" "}
              </p>
            </p>
            <BarChart
              width={500}
              height={250}
              data={Hordata}
              layout="vertical"
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis type="number" domain={[0, 100]} />
              <YAxis type="category" dataKey="name" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Bar dataKey="Score(%)" barSize={40} fill="#0088FE" />
            </BarChart>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div>
                <Gauge
                  value={fmS}
                  label={""}
                  width={250}
                  height={250}
                  color={"#0088FE"}
                  topLabelStyle={{ display: "none" }}
                  valueLabelStyle={{ fontSize: "25px" }}
                  valueFormatter={(number) => `${number}%`}
                />
                <div align="center">Functional Maturity</div>
              </div>
              <div>
                <Gauge
                  value={dmScore}
                  label={""}
                  width={250}
                  height={250}
                  color={"#0088FE"}
                  topLabelStyle={{ display: "none" }}
                  valueLabelStyle={{ fontSize: "25px" }}
                  valueFormatter={(number) => `${number}%`}
                />
                <div align="center">Organizational Maturity</div>
              </div>
            </div>
          </div>
          {printfooter(7)}
        </div>
      </div>
      {/* 7th page ends */}

      {/* 8th page starts here */}

      <div className="EighthMid Page">
        <div className="pageInnerContainer">
          {printheader()}

          <p className="Digitalpara heading2" style={{ marginLeft: "40px" }}>
            Maturity Chart
          </p>
          <div className="table">
            <table>
              <tbody className="trcontainer">
                <tr>
                  <th></th>
                  <td> Beginner</td>
                  <td>Explorer</td>
                  <td>Investor</td>
                  <td>Accelerator</td>
                  <td>Visionary</td>
                </tr>
                <tr>
                  <td>Your Score</td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    {" "}
                    <strong
                      style={{ backgroundColor: "#e6eef0", color: "black" }}
                    >
                      {"<=20%"}
                    </strong>
                  </td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    <strong
                      style={{ backgroundColor: "#e6eef0", color: "black" }}
                    >
                      {"<=40%"}
                    </strong>
                  </td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    <strong
                      style={{ backgroundColor: "#e6eef0", color: "black" }}
                    >
                      {"<=60%"}
                    </strong>
                  </td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    <strong
                      style={{ backgroundColor: "#e6eef0", color: "black" }}
                    >
                      {"<=80%"}
                    </strong>
                  </td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    <strong
                      style={{ backgroundColor: "#e6eef0", color: "black" }}
                    >
                      {">80%"}
                    </strong>
                  </td>
                </tr>
                <tr className="alignTextTop">
                  <td>Digital Vision & Strategy</td>
                  <td>
                    -No digital strategy in org. and leadership does not feel
                    the need for it -No leadership buy-in or investment planned
                    for digital
                  </td>
                  <td>
                    - Digital strategy is ad-hoc and driven by individual
                    functions - Initial Digital investments planned - Some
                    consensus from top-leadership on DT
                  </td>

                  <td>
                    -Digital Strategy adopted at 1~2 functions and actively
                    leveraged for driving operational efficiencies -Consensus on
                    DT roadmap from top management
                  </td>

                  <td>
                    -Digital strategy has been widely adopted across the
                    organization to drive key operational and client success
                    -Top-leadership actively involved in the DT roadmap planning
                    and execution
                  </td>

                  <td>
                    -Digital Strategy is been leveraged to transform the
                    business models -Businesses are being re-organized around
                    digital capabilities
                  </td>
                </tr>

                {/*  */}

                <tr
                  className="alignTextTop"
                  style={{ backgroundColor: "#e6eef0" }}
                >
                  <td>Data & Analytics</td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    -Transactional data and is managed only for an audit/
                    regulatory perspective. -Data resides in siloed systems
                    mostly and reporting is ad-hoc and manual
                  </td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    -Some of the data is being integrated across systems Data is
                    mostly being used for reporting purposes
                  </td>

                  <td style={{ backgroundColor: "#e6eef0" }}>
                    -IT/OT integration initiated - Data from non-traditional
                    sources are being stored (e.g., equipment data, web data) -
                    Advanced analytics to derive insights being explored (e.g.,
                    anomaly detection)
                  </td>

                  <td style={{ backgroundColor: "#e6eef0" }}>
                    -Organization can seamlessly integrate data IT/OT to address
                    specific use cases - Data handling is being automated and
                    focus is on building predictive/ prescriptive insights
                  </td>

                  <td style={{ backgroundColor: "#e6eef0" }}>
                    - Organization integrates data from external sources and is
                    able to enrich /augment its own data which in turn helps to
                    drive additional business value - Advanced analytics and AI
                    have been embedded into operations
                  </td>
                </tr>

                {/*  */}
              </tbody>
            </table>
          </div>
          {printfooter(8)}
        </div>
      </div>

      {/* 8th page ends */}

      {/* 9th page starts here */}

      <div className="NinthMid Page">
        <div className="pageInnerContainer">
          {printheader()}

          <div className="table">
            <table>
              <tbody className="trcontainer">
                <tr>
                  <th></th>
                  <td> Beginner</td>
                  <td>Explorer</td>
                  <td>Investor</td>
                  <td>Accelerator</td>
                  <td>Visionary</td>
                </tr>
                <tr>
                  <td>Your Score</td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    {" "}
                    <strong
                      style={{ backgroundColor: "#e6eef0", color: "black" }}
                    >
                      {"<=20%"}
                    </strong>
                  </td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    <strong
                      style={{ backgroundColor: "#e6eef0", color: "black" }}
                    >
                      {"<=40%"}
                    </strong>
                  </td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    <strong
                      style={{ backgroundColor: "#e6eef0", color: "black" }}
                    >
                      {"<=60%"}
                    </strong>
                  </td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    <strong
                      style={{ backgroundColor: "#e6eef0", color: "black" }}
                    >
                      {"<=80%"}
                    </strong>
                  </td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    <strong
                      style={{ backgroundColor: "#e6eef0", color: "black" }}
                    >
                      {">80%"}
                    </strong>
                  </td>
                </tr>

                <tr className="alignTextTop">
                  <td>Organization & Skills</td>
                  <td>
                    -Data resides in siloed systems mostly and reporting is
                    ad-hoc and manual -Focus on basic functional skills -
                    Digital skills not evaluated though some skills may reside
                    in siloes
                  </td>
                  <td>
                    -Data is mostly being used for reporting purposes -Digital
                    is viewed as a technology play and is driven via CTO/IT
                    teams -No training or HR interventions
                  </td>

                  <td>
                    (e.g., equipment data, web data) - Advanced analytics to
                    derive insights being explored (e.g., anomaly detection)
                    -Digital is viewed as a business led transformation. -Data
                    literacy initiated centrally by HR/Learning departments
                    programs
                  </td>

                  <td>
                    - Data handling is being automated and focus is on building
                    predictive/ prescriptive insights -Organization recognizes
                    the importance of building digital capabilities at
                    functional levels. -Role based training and hiring initiated
                    specifically for digital projects
                  </td>

                  <td>
                    data which in turn helps to drive additional business value
                    - Advanced analytics and AI have been embedded into
                    operations -Organization is built around digital components
                    and offerings rather than traditional business units or
                    functions
                  </td>
                </tr>

                {/*  */}
                <tr className="alignTextTop">
                  <td>Process & Technology</td>

                  <td style={{ backgroundColor: "#e6eef0" }}>
                    Processes are ad-hoc and inconsistent -Processes are manual
                    and there is little /no technology interventions
                  </td>
                  <td style={{ backgroundColor: "#e6eef0" }}>
                    - Technology is used for automating repetitive tasks and
                    improve basic efficiencies but end-to-end visibility is
                    lacking - Core processes are digitized but there is no
                    consistency (high variance)
                  </td>

                  <td style={{ backgroundColor: "#e6eef0" }}>
                    -Technology used to provide real-time visibility into core
                    processes and enable end-to-end transaction visibility
                    within organization - Processes are measurable and
                    consistent
                  </td>

                  <td style={{ backgroundColor: "#e6eef0" }}>
                    -Technology used to provide real-time visibility into
                    processes and enable end-to-end across the supply chain -
                    Processes are continuously being improved
                  </td>

                  <td style={{ backgroundColor: "#e6eef0" }}>
                    - Technology is used to provide components and services
                    which can be directly integrated into external partner
                    ecosystem for improved collaboration and co-creation
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {printfooter(9)}
        </div>
      </div>

      {/* 9th page ends */}

      {/* 10th page starts here */}

      <div className="TenthMid Page">
        <div className="pageInnerContainer">
          {printheader()}
          <div className="para3">
            <p className="Digitalpara heading2">How can we help? </p>
            <p className="p3">
              Congratulations on completing your digital maturity assessment! We
              hope you found this useful. We also provide extensive and in-depth
              assessment for digital transformation roadmap framework which is
              designed to help companies answer following critical questions
              related to Digital Transformation:
            </p>

            <p className="p3">
              1. What do I want to achieve via Digital for my Organization?
            </p>
            <p className="p3">
              2. What capabilities, org. structures and resources will I need to
              achieve my goals?
            </p>
            <p className="p3">
              3. What are the current gaps and what investment will be required
              to close the gaps?
            </p>
            <p className="p3">
              4. Where should I prioritize and how do I start?
            </p>
            <br />
            <div className="main10thDiv">
              <div
                className="TenthPageImg"
                style={{ width: "189", backgroundColor: "white" }}
              >
                <img src="TenthpageImg.png" alt="" />
              </div>
              <div
                className="right10thPage"
                style={{ marginLeft: "20px", backgroundColor: "white" }}
              >
                <span
                  style={{ marginBottom: "70px", backgroundColor: "white" }}
                >
                  Our framework uses our proprietary tools to create a highly
                  customized and effective transformation roadmap aligned to
                  your strategic objectives.
                </span>
                <p style={{ marginBottom: "10px", backgroundColor: "white" }}>
                  Results from companies using our assessment framework:
                </p>
                <ul type="disc" style={{ backgroundColor: "white" }}>
                  <li style={{ backgroundColor: "white" }}>
                    20+ companies assessed in Indian Auto sector
                  </li>
                  <li style={{ backgroundColor: "white" }}>
                    {" "}
                    100+ digital initiatives identified
                  </li>
                  <li style={{ backgroundColor: "white" }}>
                    {" "}
                    50 digital applications built for Auto
                  </li>
                </ul>
              </div>
            </div>

            <p className="p3" style={{ backgroundColor: "white" }}>
              Click on the link below and one of our experts will be happy to
              organize a 1on1 with you and your team to discuss how we can help
              you in your digital transformation journey.
            </p>
            <button className="bottomBtn">
              <a
                href="mailto:admin@silfratech.com?subject=Discussion on digital transformation with c4i4!&body=Yes, I am interested!"
                style={{ color: "white" }}
              >
                Yes, I am interested
              </a>
            </button>
          </div>
          {printfooter(10)}
        </div>
      </div>
      {/* 10th page ends */}
    </div>
  );
};

export default DigTrans;
