import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class CustomerDetailsQuestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            radio: '',
            error: '',
            questions: [],
            currentIndex: 0,
            answers: {},
            textAnswer: '',
            addText: '',
            choiceStyle:
                "{paddingLeft: '5%', display:'grid', gridTemplateColumns:'100%'}",
        };
    }

    componentDidMount() {
        this.getQuestions();
        this.getAnswers();
    }

    getAnswers = async () => {
        const url =
            process.env.REACT_APP_SERVER + '/customer/get-current-answers';
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios.get(url, { headers }).then((res) => {
            if (res.status === 251) {
                this.refreshToken(this.getAnswers);
            } else if (res.status === 200) {
                console.log(res.data);
                console.log(this.state);
                console.log(res.data.currentQuestion);
                console.log(
                    this.state.questions[this.state.questions.length - 1].quesID
                );
                let tmpCurrentIndex = 0;
                if (
                    (res.data.currentSection === 'CD' ||
                        res.data.currentSection === 'basic details') &&
                    res.data.currentQuestion <=
                        this.state.questions[this.state.questions.length - 1]
                            .quesID
                ) {
                    tmpCurrentIndex =
                        res.data.currentQuestion -
                        this.state.questions[0].quesID;
                } else if (
                    res.data.currentSection === 'ODM' ||
                    res.data.currentQuestion >
                        this.state.questions[this.state.questions.length - 1]
                            .quesID
                ) {
                    this.props.navigate('/assessment');
                } else {
                    this.props.navigate('/functionalAssessment');
                }
                this.setState({
                    answers: res.data.surveyAnswers,
                    currentIndex:
                        res.data.currentSection === 'CD' ? tmpCurrentIndex : 0,
                });
                if (res.data.currentSection === 'ODM')
                    this.props.navigate('/assessment');
                if (res.data.currentSection === 'FM')
                    this.props.navigate('/functionalAssessment');
            }
        });
    };

    refreshToken = async (func) => {
        const url =
            process.env.REACT_APP_SERVER + '/customer/generate-access-token';
        const token = sessionStorage.getItem('refreshToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios.get(url, { headers }).then((res) => {
            if (res.status === 200) {
                sessionStorage.setItem('accessToken', res.data.accessToken);
            }
            func();
        });
    };

    onLogout = async () => {
        const token = sessionStorage.getItem('accessToken');
        const url =
            process.env.REACT_APP_SERVER + '/customer/submit-survey-answers';
        const apiData = {
            surveyAnswers: this.state.answers,
            isComplete: false,
            currentSection: 'CD',
            currentQuesNumber: this.state.currentIndex,
        };
        const headers = { Authorization: `Bearer ${token}` };
        await axios.post(url, apiData, { headers }).then((res) => {
            if (res.status === 251) {
                this.refreshToken(this.onLogout);
            } else if (res.status === 200) {
                //Success
            } else {
                this.setState({
                    error: true,
                    errors: {
                        submitAnswers: res.data.message,
                    },
                });
            }
        });
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
        this.props.navigate('/');
    };

    getQuestions = async () => {
        const url = process.env.REACT_APP_SERVER + '/customer/get-cd-questions';
        await axios.get(url).then((res) => {
            console.log(res);
            console.log(this.state);
            this.setState({
                questions: res.data.questionData,
                choiceStyle: {
                    paddingLeft: '5%',
                    display: 'grid',
                    gridTemplateColumns:
                        this.state.questions.length === 0
                            ? '33% 33% 33%'
                            : '100%',
                },
            });
            console.log(this.state);
            //window.location.reload(false);
        });
    };

    onChangeInput = (e) => {
        this.setState({
            radio: e.target.value,
            error: '',
        });
    };

    onClickButton = async () => {
        if (
            this.state.radio === '' &&
            this.state.questions[this.state.currentIndex].choiceDetails
                .length !== 0
        ) {
            console.log('error');
            this.setState({ error: 'Please select an option' });
            return;
        } else if (this.state.currentIndex + 1 < this.state.questions.length) {
            this.setState({
                answers: {
                    ...this.state.answers,
                    [this.state.questions[this.state.currentIndex].quesID]:
                        this.state.textAnswer.length === 0
                            ? this.state.radio
                            : this.state.textAnswer,
                },
                radio: '',
                addText: '',
                choiceStyle: {
                    paddingLeft: '5%',
                    display: 'grid',
                    gridTemplateColumns:
                        this.state.questions[this.state.currentIndex].quesNo ===
                        1
                            ? '33% 33% 33%'
                            : '100%',
                },
            });
            console.log(this.state.radio);
            console.log(this.state.currentIndex);
            console.log(
                this.state.questions[this.state.currentIndex + 1].quesNo
            );
            console.log(
                this.state.answers[
                    this.state.questions[this.state.currentIndex + 1].quesNo
                ]
            );
            this.setState({
                radio:
                    this.state.answers[
                        this.state.questions[this.state.currentIndex + 1].quesNo
                    ] === 'empty'
                        ? ''
                        : this.state.answers[
                              this.state.questions[this.state.currentIndex + 1]
                                  .quesNo
                          ],
                currentIndex: this.state.currentIndex + 1,
            });
        } else {
            this.setState({
                answers: {
                    ...this.state.answers,
                    [this.state.questions[this.state.currentIndex].quesID]:
                        this.state.radio,
                },
                choiceStyle: {
                    paddingLeft: '5%',
                    display: 'grid',
                    gridTemplateColumns:
                        this.state.questions[this.state.currentIndex].quesNo ===
                        1
                            ? '33% 33% 33%'
                            : '100%',
                },
            });
            this.setState({
                radio: '',
                addText: '',
            });
            const token = sessionStorage.getItem('accessToken');
            const url =
                process.env.REACT_APP_SERVER +
                '/customer/submit-survey-answers';
            const apiData = {
                surveyAnswers: this.state.answers,
                isComplete: false,
                currentSection: 'CD',
                currentQuesNumber:
                    this.state.questions[this.state.currentIndex].quesID,
            };
            const headers = { Authorization: `Bearer ${token}` };
            await axios.post(url, apiData, { headers }).then((res) => {
                if (res.status === 251) {
                    this.refreshToken(this.onLogout);
                } else if (res.status === 200) {
                    //Success
                    this.setState({ radio: '' });
                } else {
                    this.setState({
                        error: true,
                        errors: {
                            submitAnswers: res.data.message,
                        },
                    });
                }
            });

            // sessionStorage.setItem("accessToken", res.data.accessToken);
            // sessionStorage.setItem("refreshToken", res.data.refreshToken);
            this.props.navigate('/assessment');
        }
    };

    prevQuestion = () => {
        if (this.state.currentIndex === 0) {
            this.props.navigate('/introduction');
        } else {
            this.setState({
                currentIndex: this.state.currentIndex - 1,
            });
        }
    };
    render() {
        let answerError = this.state.error ? (
            <div className="text-danger custom-margin-form-control">
                {this.state.error}
            </div>
        ) : null;
        return (
            <div>
                <Navbar />
                <div className="container">
                    <div
                        className="w-full mx-auto mt-3 mb-3 p-4"
                        style={{ width: '100%', border: 'solid' }}
                    >
                        <p className="theme-p-color">
                            <strong>
                                {this.state.questions.length > 0 &&
                                    this.state.questions[
                                        this.state.currentIndex
                                    ].quesNo}
                                .{' '}
                                {this.state.questions.length > 0 &&
                                    this.state.questions[
                                        this.state.currentIndex
                                    ].questionDescription}
                            </strong>
                        </p>
                        {this.state.questions.length > 0 &&
                        this.state.questions[this.state.currentIndex]
                            .choiceDetails.length ? (
                            this.state.questions[this.state.currentIndex]
                                .quesID === 1 ? (
                                <div>
                                    <select
                                        class="form-select"
                                        value={this.state.radio}
                                        onChange={this.onChangeInput}
                                    >
                                        {this.state.questions[
                                            this.state.currentIndex
                                        ].choiceDetails.map((choice, index) => (
                                            <option
                                                style={{ flex: index % 2 }}
                                                name="flexSelectDefault"
                                                id="flexSelect1"
                                                value={choice.key}
                                            >
                                                {choice.key}
                                                {'. '}
                                                {choice.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ) : (
                                <div style={this.state.choiceStyle}>
                                    {this.state.questions[
                                        this.state.currentIndex
                                    ].choiceDetails.map((choice, index) => (
                                        <div className="form-check">
                                            <input
                                                style={{ flex: index % 2 }}
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault1"
                                                value={choice.key}
                                                checked={
                                                    this.state.radio ===
                                                    choice.key
                                                }
                                                onChange={this.onChangeInput}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault1"
                                            >
                                                {choice.key}
                                                {'. '}
                                                {choice.value}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )
                        ) : (
                            <div className="form-group mt-3">
                                <label htmlFor="Answer">Answer</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Answer"
                                    value={this.state.textAnswer}
                                    id="answer"
                                    onChange={(e) => {
                                        this.setState({
                                            textAnswer: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                        )}
                        <label htmlFor="addText" className="mt-2">
                            Add Text
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Add Text"
                            value={this.state.addText}
                            id="addText"
                            onChange={(e) => {
                                this.setState({ addText: e.target.value });
                            }}
                        />
                        <br />
                    </div>

                    <br />

                    <br />
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <div
                                className="btn btn-primary mt-3 mb-auto float-end"
                                onClick={this.prevQuestion}
                            >
                                Back
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button
                                className="btn btn-primary mt-3"
                                onClick={this.onClickButton}
                            >
                                Next
                            </button>
                        </div>
                        <div className="col-md-3">
                            <CircularProgressbar
                                value={
                                    this.state.questions.length > 0
                                        ? ((this.state.currentIndex + 1) *
                                              100) /
                                          25
                                        : 0
                                }
                                text={
                                    this.state.questions.length > 0 &&
                                    `${
                                        this.state.questions.length > 0
                                            ? ((this.state.currentIndex + 1) *
                                                  100) /
                                              25
                                            : 0
                                    }%`
                                }
                                strokeWidth={8}
                            />
                        </div>
                        <div className="col-md-3">
                            <button
                                className="btn btn-primary mt-3"
                                onClick={this.onLogout}
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default (props) => {
    const navigate = useNavigate();
    return <CustomerDetailsQuestions navigate={navigate} />;
};
