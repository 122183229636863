import React from "react";
// import Loader from '../components/Loader';
// import Navbar from '../components/Navbar';
// import Footer from '../components/Footer';
// import { validateEmail } from '../utils';
import { Link, useNavigate, NavLink } from "react-router-dom";
// import image from '../assets/images/Silfra_Logo.png';
// C:\Users\User\OneDrive\Desktop\C4I4\c4i4assessment\src\assets\images\Silfra_Logo.png

import image from "../../assets/images/Silfra_Logo.png";
import axios from "axios";
// import { updateValues } from '../redux/reducers/score';
import { updateValues } from "../../redux/reducers/score";
import { connect } from "react-redux";
import isEmail from "validator/es/lib/isEmail";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import { validateEmail } from "../../utils";
import { toast } from "react-toastify";

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      otp: "",
      error: true,
      otpSent: false,
      otpVerified: false,
      errors: {},
      timer: 0,
    };
  }
  onChangeInput = (e) => {
    // validate the email.
    if (e.target.name === "email") {
      // validate the email.
      let val = e.target.value;
      if (val === "" || !validateEmail(val) || !isEmail(val)) {
        this.setState({
          errors: {
            email: "Please enter a valid email address.",
          },
          error: true,
        });
        return;
      }
    }

    this.setState({
      errors: {},
      error: false,
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("clicked");
    this.setState({
      loading: true,
      otpSent: false,
      email: "",
    });
    const url =
      // 'https://silfratech.in/dmat-api/user/forgot-password';
      process.env.REACT_APP_SERVER + "/user/forgot-password";

    const apiData = { email_or_username: this.state.email };

    console.log(apiData);
    //         if (this.state.email !== '' || validateEmail(this.state.email)) {
    await axios
      .post(url, apiData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Reset password link sent to your mail");
          this.setState({
            loading: false,
            otpSent: true,
            error: false,
            errors: {},
            email: "",
          });
          console.log("mail", this.state.email);
        }
        //       else if (this.state.email === '') {
        //           toast.error('Enter an email');
        //       }
        else {
          // toast.error("Error");
          toast.error("Please enter an valid email");
          this.setState({
            loading: false,
            errors: {
              otp: res.data.message,
            },
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({
          loading: false,
        });
      });
    //         }
    //         this.state.email = '';
  };

  render() {
    let EmailError = this.state.errors.email ? (
      <div className="text-danger">{this.state.errors.email}</div>
    ) : null;
    // let otpButtonText = 'Sent OTP'
    // if (this.state.timer > 0)
    //     otpButtonText = `${this.state.timer}`
    // else
    //     otpButtonText = 'Resend OTP'
    return (
      <div>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="container  navbar-collapse d-flex"
                id="navbarTogglerDemo01"
              >
                <NavLink
                  className="navbar-brand"
                  to="#!"
                  activeClassName="active"
                >
                  <img height={60} src={image} alt="C4i4 Logo" />
                </NavLink>
                {"           "}
                <div style={{ flex: 1 }}>
                  <h1 style={{ color: "royalblue" }} className="text-center">
                    Digital Maturity Assesment Survey
                  </h1>
                </div>
              </div>
              <Link to="/dmat/admin">
                <button className="btn btn-primary">Admin</button>
              </Link>
            </div>
          </nav>
          <br />
        </div>

        <div className="form-main">
          <form className="form mt-3">
            <div className="row content">
              <h3 style={{ paddingBottom: "5%" }}>Forgot Password</h3>
              <div className="col-md-12">
                <div className="form-group">
                  <label style={{ fontSize: "20px" }} htmlFor="email">
                    Enter Your Email
                  </label>
                  <input
                    style={{ fontSize: "20px" }}
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    // value={this.state.email}
                    onChange={this.onChangeInput}
                  />
                  {EmailError}
                </div>

                <div class="d-grid gap-2 col-6 mx-auto pt-3">
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <Footer />
      </div>
    );
  }
}

export default ForgetPassword;
