import styles from "../styles";
import { View, Text, Image } from "@react-pdf/renderer";
import Header from "./Header";
import Footer from "./Footer";

export const Page3 = ({ about }) => {
  return (
    <View style={styles.section_right}>
      <Header />
      <Text style={styles.contenthead}>Glossary of Terms Used</Text>
      <Text style={styles.toc}>OEM: Original Equipment Manufacturer</Text>
      <Text style={styles.toc}>DT: Digital Transformation</Text>
      <Text style={styles.toc}>OEE: Original Equipment Effectiveness</Text>
      <Text style={styles.toc}>IT: Information Technology</Text>
      <Text style={styles.toc}>OT: Operational Technology</Text>
      <Text style={styles.toc}>AI: Artificial Intelligence</Text>
      <Text style={styles.toc}>ML: Machine Learning</Text>
      <Text style={styles.toc}>HR: Human Resource</Text>
      <Text style={styles.contenthead}>List of Figures:</Text>
      <View style={{ flexDirection: "row", left: 0, right: 0 }}>
        <Text style={styles.toc}>
          Four Key Differentiations for Successful Digital Transformation
        </Text>
        <Text style={styles.toc}>
          .........................................................
        </Text>
        <Text style={styles.toc}>3</Text>
      </View>
      <View style={{ flexDirection: "row", left: 0, right: 0 }}>
        <Text style={styles.toc}>Overall Maturity Chart</Text>
        <Text style={styles.toc}>
          ........................................................................................................................
        </Text>
        <Text style={styles.toc}>9</Text>
      </View>
      <Footer pgnum='3' />
    </View>
  );
};
