import Navbar from "./Navbar";
import React, { Component } from "react";
import { MdEdit, MdDelete } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  onChangeChoice,
  onChangeQuestion,
  addQuestion,
  deleteQuestion,
} from "../../redux/reducers/surveyQuestions";
import { getAllQuestions } from "../../redux/functions/surveyQuestions";
import axios from "axios";
import { toast } from "react-toastify";
export class SurveyQuestionMan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   getInitialState: function () {
      //       return { selectValue: '--Select--' };
      //   },
      questionsUpdated: false,
      currentSelected: 0,
      rank: false,
      show: false,
      question: {
        sectionName: "",
        id: 0,
        questionDescription: "",
        questionHelp: "kfhkf",
        questionInformation: "",
        choiceDetails: [],
        questionNumber: 1,
        //       selectValue: '',
      },
      loading: false,
      editingMode: false,
      maintenanceMode: false,
      count: [],
    };
  }

  handleChange = (val) => {
    //         this.setState({ count: 0 });
    this.state.count = [];

    this.props.questions.map((item, index) => {
      if (item.sectionName === val) {
        this.state.count.push(item.questionNumber);
        //       this.setState({ count: this.state.count++ });
      }
    });

    this.setState({
      question: {
        ...this.state.question,
        sectionName: val,
        questionNumber: 23,
      },
    });
  };

  questionNoHandleChange = (val) => {
    this.setState({
      question: {
        ...this.state.question,
        questionNumber: val,
      },
    });
  };

  async componentDidMount() {
    await this.props.getAllQuestions();
    await this.maintenanceModeStatus();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.questionsUpdated === true) {
      //     this.props.getAllQuestions();
      this.setState({
        questionsUpdated: false,
      });
    }
  }

  async componentWillUnmount() {
    if (this.maintenanceModeStatus === true) {
      await this.disableEditing();
    }
  }

  async componentDidCatch() {
    await this.disableEditing();
  }

  maintenanceModeStatus = async () => {
    const url = process.env.REACT_APP_SERVER + "/admin/get-maintenance-status";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .get(url, { headers })
      .then((res) => {
        if (res.status === 251) {
          this.refreshToken(this.maintenanceModeStatus);
        } else if (res.status === 200) {
          const { inMaintenanceMode } = res.data;
          this.setState({
            maintenanceMode: inMaintenanceMode,
          });
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  editHandler = (currentIndex) => {
    this.setState({
      show: true,
      question: this.props.questions[currentIndex],
      currentSelected: currentIndex,
    });
  };
  deleteHandler = (currentIndex) => {
    console.log(currentIndex);
    this.props.deleteQuestion({
      currentSelected: currentIndex,
    });
  };

  handleChangeQuestion = (e) => {
    e.preventDefault();

    let Ques = parseInt(this.state.question.question_number);

    console.log("lin 141 ", this.state.question);

    //         if (Ques > 0) {
    //

    //

    this.props.onChangeQuestion({
      currentSelected: this.state.currentSelected,
      updatedQuestion: this.state.question,
    });
    //   questions

    this.setState({
      // ...this.state,

      show: false,
    });
    //         }
  };

  handleModalClose = () => {
    this.setState({
      show: false,
    });
  };

  onChangeChoices = (currentIndex, index, e) => {
    let question = this.state.question;

    var { choice_details } = question;
    choice_details = choice_details.slice();
    choice_details[index] = {
      ...choice_details[index],
      value: e.target.value,
    };
    question = {
      ...question,
      choice_details,
    };
    this.setState({
      question,
    });
  };

  onChangeRank = (currentIndex, index, e) => {
    let question = this.state.question;
    console.log(
      `curentInd=${currentIndex}, index=${index}, e=${e}, Questions=${question}`
    );
    if (e.target.value <= 0) {
      this.setState({
        rank: true,
      });
      toast.error("Enter positive score");
      return;
    }

    var { choiceDetails } = question;
    choiceDetails = choiceDetails.slice();
    choiceDetails[index] = {
      ...choiceDetails[index],
      rank: e.target.value,
    };

    question = {
      ...question,
      choiceDetails,
    };
    this.setState({
      question,
    });

    console.log("Question 203", this.state.question);
    this.setState({
      rank: false,
    });
  };

  handleAddChoice = () => {
    var question = this.state.question;
    var { choiceDetails } = question;
    choiceDetails = choiceDetails.slice();
    choiceDetails.push({
      value: "",
      rank: 0,
      key: String(choiceDetails.length + 1),
    });
    question = {
      ...question,
      choiceDetails,
    };
    this.setState({
      question,
    });
  };

  onChangeInput = (e) => {
    // if()
    this.setState({
      question: {
        ...this.state.question,
        [e.target.name]: e.target.value,
      },
    });

    console.log("line182", this.state.question);
  };

  handleSubmit = async () => {
    const url = process.env.REACT_APP_SERVER + "/user/submit-question-batch";

    const apiData = {
      questions: this.props.questions,
    };
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };

    await axios
      .post(url, apiData, { headers })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            questionsUpdated: true,
            editingMode: false,
          });

          this.props.getAllQuestions();
        }
      })
      .catch((err) => console.log(err));
  };

  refreshToken = async (func) => {
    const url = process.env.REACT_APP_SERVER + "/user/generate-access-token";
    const token = sessionStorage.getItem("refreshToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .get(url, { headers })
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("accessToken", res.data.accessToken);
          func();
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  enableMaintenanceMode = async () => {
    const url = process.env.REACT_APP_SERVER + "/admin/enable-maintenance-mode";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .get(url, { headers })
      .then((res) => {
        console.log(res);
        if (res.status === 251) {
          this.refreshToken(this.enableMaintenanceMode);
        } else if (res.status === 200) {
          this.setState({
            maintenanceMode: true,
          });
          toast.success(`${res.data.message}`);
        }
      })
      .catch((err) => console.log(err));
  };

  disableMaintenanceMode = async () => {
    const url =
      process.env.REACT_APP_SERVER + "/admin/disable-maintenance-mode";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .get(url, { headers })
      .then((res) => {
        if (res.status === 251) {
          this.refreshToken(this.disableMaintenanceMode);
        } else if (res.status === 222) {
          const { message } = res.data;
          toast.error(`${message}`);
        } else if (res.status === 200) {
          this.setState({
            maintenanceMode: false,
            editingMode: false,
          });
          toast.success(`${res.data.message}`);
        }
      })
      .catch((err) => console.log(err));
  };

  addQuestion = async () => {
    this.props.addQuestion();
  };

  enableEditing = async () => {
    const url = process.env.REACT_APP_SERVER + "/user/start-editing-questions";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .get(url, { headers })
      .then((res) => {
        console.log("Line353 res data", res);
        if (res.status === 251) {
          this.refreshToken(this.enableEditing);
        } else if (res.status === 222) {
          const { userEditingQuestions } = res.data;
          toast.error(
            `${userEditingQuestions} is currently editing the questions.`
          );
        } else if (res.status === 200) {
          this.setState({
            editingMode: true,
          });
          toast.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  disableEditing = async () => {
    const url = process.env.REACT_APP_SERVER + "/user/stop-editing-questions";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .get(url, { headers })
      .then((res) => {
        if (res.status === 251) {
          this.refreshToken(this.disableEditing);
        } else if (res.status === 200) {
          this.setState({
            editingMode: false,
          });
          toast.success(res.data.message);
        } else if (res.status === 222) {
          toast.error(res.data.message);
        } else {
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    let sectionDetails =
      this.state.question.sectionName == "" ? (
        <div className="text-danger">Enter the section details</div>
      ) : null;
    let questionNumber =
      this.state.question.questionNumber <= 0 ? (
        <div className="text-danger">Enter Question Number</div>
      ) : null;
    let MaintenanceModeButtons =
      this.state.loading === false && !this.state.maintenanceMode ? (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary mx-3"
            onClick={this.enableMaintenanceMode}
          >
            Enable Maintenance Mode
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          {!this.state.editingMode ? (
            <button
              className="btn btn-primary mx-3"
              onClick={this.enableEditing}
            >
              Enable Editing
            </button>
          ) : (
            <button
              className="btn btn-primary mx-3"
              onClick={this.disableEditing}
            >
              Disable Editing
            </button>
          )}
          <button
            className="btn btn-primary mx-3"
            style={{ cursor: this.state.editingMode ? "not-allowed" : "" }}
            onClick={
              !this.state.editingMode
                ? this.disableMaintenanceMode
                : () => undefined
            }
          >
            Disable Maintenance Mode
          </button>
        </div>
      );
    return (
      <>
        <Navbar />
        <main className="container">
          <section>
            <Modal
              show={this.state.show}
              onHide={this.handleModalClose}
              centered
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Survey Answer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <div className="row mb-3">
                                    <div className="col-md-3">
                                        <label
                                            htmlFor="sectionName"
                                            className="col-form-label"
                                        >
                                            Section Details
                                        </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="sectionDetails"
                                            value={
                                                this.state.question.sectionName
                                            }
                                            name="sectionName"
                                            onChange={this.onChangeInput}
                                        />
                                        {sectionDetails}
                                    </div>
                                </div> */}
                {/* Question Section Dropdown */}
                {/* {this.state.question.newQuestion && ( */}
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="questionNumber" className="col-form-label">
                      Section Name
                    </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      disabled={!this.state.question.newQuestion}
                      value={this.state.question.section_name}
                      onChange={(e) => this.handleChange(e.target.value)}
                      className="col-md-9 w-100 py-2"
                    >
                      <option value="">---- SELECT ----</option>
                      <option value="Section 2 - Company Profile">
                        Section 2 - Company Profile
                      </option>
                      <option value="Section 3 - Organization Digital Maturity">
                        Section 3 - Organization Digital Maturity
                      </option>
                      <option value="Section 4 - Functional Maturity">
                        Section 4 - Functional Maturity
                      </option>
                    </select>
                    <p>{/* {message} */}</p>
                  </div>
                </div>
                {/* )} */}

                {/*  */}

                {/* input dropdown */}

                {/* {this.state.question.newQuestion && ( */}
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="questionNumber" className="col-form-label">
                      Question Number
                    </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      disabled={!this.state.question.newQuestion}
                      value={this.state.question.question_number}
                      onChange={(e) =>
                        this.questionNoHandleChange(e.target.value)
                      }
                      className="col-md-9 w-100 py-2"
                    >
                      {
                        ((this.state.count = []),
                        this.props.questions.map((item, index) => {
                          if (
                            item.sectionName === this.state.question.sectionName
                          ) {
                            this.state.count.push(item.question_number);
                          }
                        }))
                      }
                      {this.state.count &&
                        this.state.count?.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                    </select>
                    {/* {questionNumber} */}
                  </div>
                </div>
                {/* )} */}
                {/*  */}
                {/* <div className="row">
                                    <div className="col-md-3">
                                        <label
                                            htmlFor="questionNumber"
                                            className="col-form-label"
                                        >
                                            Question Number
                                        </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="questionNumber"
                                            value={
                                                this.state.question &&
                                                this.state.question.id
                                            }
                                            name="questionNumber"
                                            onChange={this.onChangeInput}
                                        />
                                        {questionNumber}
                                    </div>
                                </div> */}

                <div className="row pt-2">
                  <div className="col-md-3">
                    <label
                      htmlFor="questionDescription"
                      className="col-form-label"
                    >
                      Question Description
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      id="question_description"
                      value={
                        this.state.question &&
                        this.state.question.question_description
                      }
                      name="question_description"
                      onChange={this.onChangeInput}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label
                      htmlFor="questionInformation"
                      className="col-form-label"
                    >
                      Choices
                    </label>
                  </div>
                  <div className="col-md-9">
                    {this.state.question &&
                      this.state.question.choice_details &&
                      this.state.question.choice_details.length !== 0 &&
                      this.state.question.choice_details.map(
                        (choice, index) => (
                          <div className="row p-2 d-flex" key={index}>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="form-control"
                                id={`choice${index}`}
                                value={choice.value}
                                name="choice_details"
                                onChange={(e) =>
                                  this.onChangeChoices(
                                    this.state.currentIndex,
                                    index,
                                    e
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-2">
                              <input
                                type="number"
                                // disabled={
                                //     !this.state
                                //         .question
                                //         .newQuestion
                                // }
                                className=" form-control"
                                id={`choice${index}rank`}
                                value={choice.rank}
                                name="ChoiceDetails"
                                onChange={(e) =>
                                  this.onChangeRank(
                                    this.state.currentIndex,
                                    index,
                                    e
                                  )
                                }
                              />
                            </div>
                          </div>
                        )
                      )}
                    {/* <div className="row p-2">
                                            <button
                                                className="btn btn-primary"
                                                onClick={this.handleAddChoice}
                                            >
                                                Add Choice
                                            </button>
                                        </div> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="questionHelp" className="col-form-label">
                      Question Information
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      id="question_help"
                      value={this.state.question.question_help}
                      name="question_help"
                      onChange={this.onChangeInput}
                    />
                  </div>
                </div>
                <div className="row justify-content-center my-3">
                  <div className="col-md-3">
                    <button
                      className="btn btn-primary"
                      onClick={this.handleChangeQuestion}
                    >
                      Change the Question
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {MaintenanceModeButtons}
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">SL.NO</th>
                  <th scope="col">Section Name</th>
                  <th scope="col">Question NO</th>
                  <th scope="col">Question Description</th>

                  {/* {this.state.editingMode && (
                                        <th scope="col">Delete</th>
                                    )} */}
                  <th scope="col">Last Updated</th>
                  {this.state.editingMode && <th scope="col">Edit</th>}
                </tr>
              </thead>
              <tbody>
                {this.props.questions &&
                  this.props.questions.map((section, index) => (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>{section.section_name}</td>
                      <td>{section.question_number}</td>

                      <td>{section.question_description}</td>

                      <td>{section.updated_on || section.created_on}</td>
                      {this.state.editingMode && (
                        <td>
                          <button
                            type="button"
                            className="btn"
                            onClick={() => this.editHandler(index)}
                          >
                            <MdEdit color="black" size={30} />
                          </button>
                        </td>
                      )}
                      {/* {this.state.editingMode && (
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn"
                                                            onClick={() =>
                                                                this.deleteHandler(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <MdDelete
                                                                color="black"
                                                                size={30}
                                                            />
                                                        </button>
                                                    </td>
                                                )} */}
                      <td>{section.updatedOn}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* {this.state.editingMode && (
                            <div className="d-flex justify-content-center">
                                <button
                                    onClick={this.addQuestion}
                                    className="btn btn-primary  my-3"
                                >
                                    Add a Question
                                </button>
                            </div>
                        )} */}
            {this.state.editingMode && (
              <div className="d-flex justify-content-center">
                <button
                  onClick={this.handleSubmit}
                  className="btn btn-primary  my-3"
                >
                  Submit Changes
                </button>
              </div>
            )}
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { surveyQuestions } = state;
  return surveyQuestions;
};

const mapDispatchToProps = {
  onChangeChoice,
  onChangeQuestion,
  getAllQuestions,
  addQuestion,
  deleteQuestion,
};

const ReduxSurveyQuestionMan = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyQuestionMan);

export default (props) => {
  const navigate = useNavigate();
  return <ReduxSurveyQuestionMan navigate={navigate} />;
};
