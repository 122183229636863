import React from 'react';

class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: props.loading
        }
    }
    render() {
        if (this.props.loading) {
            return (
                <div className="spinner-border" role="status"></div>
            )
        }
        return null;
    }
}

export default Loader;
