import React from "react";
import Loader from "../../components/Loader";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { validateEmail } from "../../utils";
import { Link, useNavigate, NavLink } from "react-router-dom";
import image from "../../assets/images/Silfra_Logo.png";
import axios from "axios";
import { updateValues } from "../../redux/reducers/score";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import isEmail from "validator/lib/isEmail";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      usernameOrEmail: "",
      password: "",
      otp: "",
      error: true,
      otpSent: false,
      otpVerified: false,
      errors: {},
      timer: 0,
    };
  }
  onChangeInput = (e) => {
    // validate the email.
    if (e.target.name === "username") {
      // validate the email.
      let val = e.target.value;
      if (val === "") {
        this.setState({
          errors: {
            usernameOrEmail: "Please enter a valid username.",
          },
          error: true,
        });
        return;
      }
    }
    if (e.target.name === "password") {
      // validate the email.
      let val = e.target.value;
      if (val === "") {
        this.setState({
          errors: {
            password: "Please enter a valid password.",
          },
          error: true,
        });
        return;
      }
    }

    this.setState({
      errors: {},
      error: false,
      [e.target.name]: e.target.value,
    });
  };

  onSubmitOTP = async (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.usernameOrEmail !== "" && this.state.password !== "") {
      const url = process.env.REACT_APP_SERVER + "/user/login";
      const apiData = {
        email_or_username: this.state.usernameOrEmail,
        password: this.state.password,
      };
      axios
        .post(url, apiData)
        .then((res) => {
          if (res.status === 200) {
            const { message, response, access_token, refresh_token } = res.data;

            sessionStorage.setItem("accessToken", access_token);
            sessionStorage.setItem("refreshToken", refresh_token);
            if (response !== "Success") {
              toast.success("No user has logged in");
            } else {
              toast.success(message);
            }
            this.props.navigate("/dmat/admin/surveyQuestionManagement");
          } else {
            this.setState({
              errors: {
                ...this.state.errors,
                login: res.data.message,
              },
            });
          }
        })
        .catch((err) => console.log(err));
      // this.props.navigate("/admin/surveyQuestionManagement");
    }
    if (this.state.usernameOrEmail === "" && this.state.password === "") {
      this.setState({
        errors: {
          ...this.state.errors,
          usernameOrEmail: "Please enter an email",
          password: "Please enter a password",
        },
      });
    } else if (this.state.usernameOrEmail === "") {
      this.setState({
        errors: {
          ...this.state.errors,
          usernameOrEmail: "Please enter an email",
        },
      });
    } else if (this.state.password === "") {
      this.setState({
        errors: {
          ...this.state.errors,
          password: "Please enter a password",
        },
      });
    }
  };
  render() {
    let UserNameOrEmailError = this.state.errors.usernameOrEmail ? (
      <div className="text-danger">{this.state.errors.usernameOrEmail}</div>
    ) : null;
    let PasswordError = this.state.errors.password ? (
      <div className="text-danger">{this.state.errors.password}</div>
    ) : null;
    let LoginError = this.state.errors.login ? (
      <div className="text-danger text-center mt-2">
        {this.state.errors.login}
      </div>
    ) : null;

    // let otpButtonText = 'Sent OTP'
    // if (this.state.timer > 0)
    //     otpButtonText = `${this.state.timer}`
    // else
    //     otpButtonText = 'Resend OTP'
    return (
      <div>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="container collapse navbar-collapse"
                id="navbarTogglerDemo01"
              >
                <NavLink
                  className="navbar-brand"
                  to="#!"
                  activeClassName="active"
                >
                  <img
                    style={{ width: "20%", height: "20%" }}
                    src={image}
                    alt="C4i4 Logo"
                  />
                </NavLink>
                <div>
                  <h1 style={{ color: "royalblue" }}>Admin Login </h1>
                </div>
              </div>
              <Link to="/dmat">
                <button className="btn btn-primary">Home</button>
              </Link>
            </div>
          </nav>
          <br />
        </div>

        <div className="form-main">
          <form className="form mt-3">
            <div className="row content">
              <h3 style={{ paddingBottom: "5%" }}>Login</h3>

              <div className="col-md-6">
                <div className="form-group">
                  <label style={{ fontSize: "20px" }} htmlFor="username">
                    User Name or Email
                  </label>
                  <input
                    style={{ fontSize: "20px" }}
                    type="text"
                    className="form-control"
                    id="usernameOrEmail"
                    name="usernameOrEmail"
                    onChange={this.onChangeInput}
                  />
                  {UserNameOrEmailError}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="password" style={{ fontSize: "20px" }}>
                    Password
                  </label>
                  <input
                    style={{ fontSize: "20px" }}
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    onChange={this.onChangeInput}
                  />
                  {PasswordError}
                </div>
              </div>
            </div>
            <div className="row mb-2 mt-2 d-flex justify-content-center">
              <Loader loading={this.state.loading} />
            </div>
            <div className="text-center mt-3" onClick={this.handleSubmit}>
              <div className="btn btn-primary" style={{ fontSize: "20px" }}>
                Login
              </div>
            </div>
            {LoginError}
            <div className="d-flex justify-content-between">
              <Link to="/dmat/admin/signup" className="">
                <p className="text-left mt-3">
                  Don't have an account? Sign Up Here
                </p>
              </Link>
              <Link to="/dmat/admin/forgotPassword" className="">
                <p className="text-right mt-3">Forgot Password</p>
              </Link>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    );
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <Login navigate={navigate} />;
};
