import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const initialState = {
    answers: {
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
        10: '',
        11: '',
        12: '',
        13: '',
        14: '',
        15: '',
        16: '',
        17: '',
        18: '',
    },
    loading: false,
    status: 0,
    currentSection: null,
    currentQuestion: null,
    question_ids: null,
    userChoice: '',
    no_of_questions: 1,
    currentIndex: 0,
    maxProgress: 0,
};

export const refreshToken = createAsyncThunk('score/refreshToken', async() => {
    const url =
        process.env.REACT_APP_SERVER + '/customer/generate-access-token';
    const token = sessionStorage.getItem('refreshToken');
    const headers = { Authorization: `Bearer ${token}` };
    await axios.get(url, { headers }).then((res) => {
        if (res.status === 200) {
            sessionStorage.setItem('accessToken', res.data.accessToken);
        }
    });
});

export const getInitialScore = createAsyncThunk(
    'score/getInitialScore',
    async(dummyData, thunkAPI) => {
        const url =
            process.env.REACT_APP_SERVER + '/customer/get-current-answers';
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        var data;
        await axios
            .get(url, { headers })
            .then(async(res) => {
                console.log('line 61 res=', res);
                if (res.status === 251) {
                    refreshToken();
                    await axios.get(url, { headers }).then((res) => {
                        if (res.status === 200) {
                            data = res.data;
                        } else {
                            data = thunkAPI.rejectWithValue({
                                ...res.data,
                                status: res.status,
                            });
                        }
                    });
                } else if (res.status === 200) {
                    data = res.data;
                } else {
                    data = thunkAPI.rejectWithValue({
                        ...res.data,
                        status: res.status,
                    });
                }
            })
            .catch((err) => {
                data = thunkAPI.rejectWithValue({
                    ...err.response,
                    status: 404,
                });
            });
        return data;
    }
);

export const scoreSlice = createSlice({
    name: 'score',
    initialState,
    reducers: {
        updateAnswer: (state, action) => {
            console.log('line97=', action.payload);
            return {
                ...state,
                answers: {
                    ...state.answers,
                    [action.payload.id]: {
                        user_choice: action.payload.answer,
                        user_comment: action.payload.userComment,
                    },
                },
            };
        },
        updateValues: (state, action) => {
            console.log(action);
            var currentIndex = action.payload.question_ids.findIndex((x) => {
                //  console.log(x);
                return x === action.payload.current_question;
            });
            console.log('current=', currentIndex);
            // let currentIndex = 0;
            console.log('line114=', currentIndex);
            return {
                ...state,
                currentQuestion: action.payload.current_section,
                currentSection: action.payload.current_section.split('-')[0],
                question_ids: action.payload.question_ids,
                noOfQuestions: action.payload.no_of_questions,
                currentIndex: currentIndex === undefined ? 0 : currentIndex,
                maxProgress: currentIndex + 1 > state.maxProgress ?
                    currentIndex + 1 :
                    state.maxProgress,
            };
        },
        updateSection: (state, action) => {
            return {
                ...state,
                currentSection:
                //  'Charan',
                    action.payload,
            };
        },
        updateQuestion: (state, action) => {
            if (action.payload === 'Next') {
                return {
                    ...state,
                    currentQuestion: state.question_ids[state.currentIndex + 1],
                    currentIndex: state.currentIndex + 1,
                    maxProgress: state.currentIndex + 1 > state.maxProgress ?
                        state.currentIndex + 1 :
                        state.maxProgress,
                };
            } else {
                return {
                    ...state,
                    currentQuestion: state.question_ids[state.currentIndex - 1],
                    currentIndex: state.currentIndex - 1,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getInitialScore.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getInitialScore.rejected, (state, action) => {
            console.log(action);
            return {
                ...state,
                loading: false,
                error: true,
            };
        });
        builder.addCase(getInitialScore.fulfilled, (state, action) => {
            console.log(action);
            return {
                ...state,
                loading: false,
                current_section: action.payload.current_section,
                answers: action.payload.survey_answers,
                currentQuestion: action.payload.current_question,

                //  action.payload.currentQuestion === 'BasicDetails' ?
                //   1: action.payload.currentQuestion,
            };
        });
    },
});

export const { updateAnswer, updateValues, updateQuestion, updateSection } =
scoreSlice.actions;
export default scoreSlice.reducer;