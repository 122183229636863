import styles from '../styles'
import { View, Text, Svg, Line } from '@react-pdf/renderer'
import Gauge from "react-svg-gauge";
import { PieChart, Pie, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Header from './Header'
import Footer from './Footer'

export const Page9 = ({ about }) => {
  let odmScore = 20;
  let odmMaxScore = 40;
  let fmScore = 19;
  let fmMaxScore = 42;
  let dmScore = (odmScore / odmMaxScore).toFixed(2);
  console.log(dmScore)
  let fmS = ((fmScore * 100) / fmMaxScore).toFixed(2);
  console.log(fmS)
  let deno = odmMaxScore + fmMaxScore
  let tScore = ((odmScore + fmScore) * 100 / deno).toFixed(2);

  const Hordata = [
    {
      name: 'Industry Median',
      "Score(%)": 35
    },
    {
      name: 'Your Org Score',
      "Score(%)": tScore
    },

  ];


  return (
    <View style={styles.section_right}>
      <Header />
      <Text style={{ marginLeft: "40px", marginBottom: "40px" }}>Maturity Chart</Text>
      <View style={styles.tableContainer}>
        {/*<TableHeader />*/}
        <View style={styles.row} key="0">
          <Text style={styles.description}></Text>
          <Text style={styles.description}>Beginner</Text>
          <Text style={styles.description}>Explorer</Text>
          <Text style={styles.description}>Investor</Text>
          <Text style={styles.description}>Accelerator</Text>
          <Text style={styles.description}>Visionary</Text>
        </View>
        <Svg height="2" width="900">
          <Line
            x1="0"
            y1="0"
            x2="900"
            y2="0"
            strokeWidth={2}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.altrow} key="1">
          <Text style={styles.tablealtcellhead}>Your Score</Text>

          <Text style={styles.tablealtcellhead}>Up to 20%</Text>
          <Text style={styles.tablealtcellhead}>Up to 40%</Text>
          <Text style={styles.tablealtcellhead}>Up to 60%</Text>
          <Text style={styles.tablealtcellhead}>Up to 80%</Text>
          <Text style={styles.tablealtcellhead}> More than 80%</Text>

        </View>
        <Svg height="2" width="900">
          <Line
            x1="0"
            y1="0"
            x2="900"
            y2="0"
            strokeWidth={2}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.row} key="2">
          <Text style={styles.tablecell}>Digital Vision & Strategy</Text>
          <Text style={styles.tablecell}>-No digital strategy in org. and leadership does not feel the need for it{"\n"} -No leadership buy-in or investment planned for digital</Text>
          <Text style={styles.tablecell}>- Digital strategy is ad-hoc and driven by individual functions {"\n"}- Initial Digital investments planned {"\n"}- Some consensus from top-leadership on DT</Text>
          <Text style={styles.tablecell}>-Digital Strategy adopted at 1~2 functions and actively leveraged for driving operational efficiencies{"\n"}-Consensus on DT roadmap from top management</Text>
          <Text style={styles.tablecell}>-Digital strategy has been widely adopted across the organization to drive key operational and client success {"\n"}-Top-leadership actively involved in the DT roadmap planning and execution</Text>
          <Text style={styles.tablecell}>-Digital Strategy is been leveraged to transform the business models{"\n"} -Businesses are being re-organized around digital capabilities</Text>
        </View>
        <Svg height="2" width="900">
          <Line
            x1="0"
            y1="0"
            x2="900"
            y2="0"
            strokeWidth={2}
            stroke="rgb(0,0,0)"
          />
        </Svg>

        <View style={styles.altrow} key="3">
          <Text style={styles.tablecell}>Data & Analytics</Text>

          <Text style={styles.tablealtcell}>-Transactional data and is managed only for an audit/ regulatory perspective. {"\n"}-Data resides in siloed systems mostly and reporting is ad-hoc and manual.</Text>
          <Text style={styles.tablealtcell}>-Some of the data is being integrated across systems{"\n"} -Data is mostly being used for reporting purposes.</Text>
          <Text style={styles.tablealtcell}>-IT/OT integration initiated {"\n"}- Data from non-traditional sources are being stored (e.g., equipment data, web data){"\n"} - Advanced analytics to derive insights being explored (e.g., anomaly detection)</Text>
          <Text style={styles.tablealtcell}>-Organization can seamlessly integrate data IT/OT to address specific use cases{"\n"} - Data handling is being automated and focus is on building predictive/ prescriptive insights</Text>
          <Text style={styles.tablealtcell}>- Organization integrates data from external sources and is able to enrich /augment its own data which in turn helps to drive additional business value{"\n"} - Advanced analytics and AI have been embedded into operations</Text>

        </View>
        <Svg height="2" width="900">
          <Line
            x1="0"
            y1="0"
            x2="900"
            y2="0"
            strokeWidth={2}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        {/*<TableFooter items={data.items} />*/}
      </View>
      <Footer pgnum='9' />
    </View>
  )
}