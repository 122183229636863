import styles from '../styles'
import { View, Text, Image } from '@react-pdf/renderer'
import fourkeyImg from '../../assets/images/fourkey1.png';
import Header from './Header'
import Footer from './Footer'

export const Page6 = ({ about }) => {
  return (
    <View style={styles.section_right}>
      <Header />
      <View style={{ flexDirection: "row" }}>
        <View style={{ flexDirection: "column", width: '45%' }}>
          <View style={{ marginTop: '10', marginBottom: '0', paddingRight: '40', paddingLeft: '50', flexDirection: "row" }}>
            <Text style={{ marginHorizontal: 8 }}>•</Text>
            <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}>IO/OT integrations have not been initiated in any of the organizations assessed and it remains a key challenge identified by most organizations</Text>
          </View>
        </View>
        <View style={{ flexDirection: "column", width: '55%' }}>
          <View style={{ marginTop: '10', marginBottom: '10', paddingRight: '20', paddingLeft: '10', paddingTop: '10', paddingBottom: '10', flexDirection: "row", borderTop: '4px solid #2ca5d1', borderBottom: '4px solid #2ca5d1', color: '#2ca5d1' }}>
            <Text style={styles.specia}>Lack of standardization of OT systems is quoted as a
              key challenge to IT/OT integration</Text>
          </View>
        </View>
      </View>
      <View style={{ marginTop: '0', marginBottom: '0', paddingRight: '40', paddingLeft: '50', flexDirection: "row" }}>
        <Text style={{ marginHorizontal: 8 }}>•</Text>
        <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}>In most organizational data resides in siloes and the companies have not invested in technologies like data warehouse or data lakes to integrate the data and make it available for analysis across the organization</Text>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flexDirection: "column", width: '45%' }}>
          <Text style={{ fontSize: '12', marginTop: '10', marginBottom: '5', paddingRight: '40', paddingLeft: '40' }}>3. Organization & Skill Building:</Text>
          <View style={{ marginTop: '10', marginBottom: '5', paddingRight: '40', paddingLeft: '50', flexDirection: "row" }}>
            <Text style={{ marginHorizontal: 8 }}>•</Text>
            <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}>Organizations have started investing in building digital skills within the technology organization</Text>
          </View>
          <View style={{ marginTop: '0', marginBottom: '5', paddingRight: '40', paddingLeft: '50', flexDirection: "row" }}>
            <Text style={{ marginHorizontal: 8 }}>•</Text>
            <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}>Most of the organizations don’t have formal, role-based training to build digital skills in the business teams</Text>
          </View>
        </View>
        <View style={{ flexDirection: "column", width: '55%' }}>
          <View style={{ marginTop: '40', marginBottom: '10', paddingRight: '20', paddingLeft: '10', paddingBottom: '10', paddingTop: '10', flexDirection: "row", borderTop: '4px solid #2ca5d1', borderBottom: '4px solid #2ca5d1', color: '#2ca5d1' }}>
            <Text style={styles.specia}>Only 35% of the organizations assessed have either hired or have upskilled their technology teams internally to drive digital projects</Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flexDirection: "column", width: '45%' }}>
          <Text style={{ fontSize: '12', marginTop: '10', marginBottom: '5', paddingRight: '40', paddingLeft: '40' }}>4. Process and Technology:</Text>
          <View style={{ marginTop: '0', marginBottom: '10', paddingRight: '40', paddingLeft: '50', flexDirection: "row" }}>
            <Text style={{ marginHorizontal: 8 }}>•</Text>
            <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}>Most organizations have made some investment in putting sensors and collecting data from machines</Text>
          </View>
          <View style={{ marginTop: '0', marginBottom: '0', paddingRight: '40', paddingLeft: '50', flexDirection: "row" }}>
            <Text style={{ marginHorizontal: 8 }}>•</Text>
            <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}>There is a lack of readiness to adopt digital technologies owing to high dependency on manual processes & is considered risky to realign the processes</Text>
          </View>
        </View>
        <View style={{ flexDirection: "column", width: '55%' }}>
          <View style={{ marginTop: '35', marginBottom: '10', paddingRight: '20', paddingLeft: '10', paddingTop: '10', paddingBottom: '10', flexDirection: "row", borderTop: '4px solid #2ca5d1', borderBottom: '4px solid #2ca5d1', color: '#2ca5d1' }}>
            <Text style={styles.specia}>IoT for machines has emerged as a common use case for the organizations assessed. This has been driven mostly by equipment OEM's.</Text>
          </View>
        </View>
      </View>
      <Footer pgnum='6' />
    </View>
  )
}