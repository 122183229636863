import Navbar from './Navbar';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { filterCustomers,resetFilter } from '../../redux/reducers/customers';
import { MdEdit, MdDelete } from 'react-icons/md';
import {
    getAllCustomerDetails,
    searchCustomers,
} from '../../redux/functions/customers';
import './styles/index.scss';

export class CustomersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            customer: {},
            currentIndex: 0,
            show: false,
        };
    }

    componentDidMount() {
        this.props.getAllCustomerDetails();
        console.log(this.props.customers);
        // this.props.getAllCustomerDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customers !== this.props.customers) {
            const location = this.props.location.pathname.split('/')[3];
            this.props.filterCustomers({ location });
        }
    }

    componentWillUnmount() {
        this.props.resetFilter();
    }

    

    handleEdit = (index) => {
        console.log(index);
        this.setState({
            currentIndex: index,
            customer: {
                ...this.props.filteredCustomers[index],
            },
        });
        this.setState({
            show: true,
        });
    };

    onChangeSearchInput = (e) => {
        this.setState({
            search: e.target.value,
        });
        console.log(this.state.search);
        if (e.target.value === '') {
            const location = this.props.location.pathname.split('/')[3];
            this.props.filterCustomers({ location });
        } else {
            this.props.searchCustomers({ search: e.target.value });
        }
    };

    onChangeInput = (e) => {
        this.setState({
            customer: {
                ...this.state.customer,
                [e.target.name]: e.target.value,
            },
        });

        console.log('customerDetails 77', this.state.customer);
    };
    deleteHandler = async (customerId) => {
        const url =
            process.env.REACT_APP_SERVER +
            '/user/delete-customer?customer_id=' +
            customerId;
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios
            .delete(url, { headers })
            .then((res) => {
                if (res.status === 251) {
                    this.refreshToken(this.deleteHandler);
                } else if (res.status === 200) {
                    this.props.getAllCustomerDetails();
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    handleModalClose = () => {
        this.setState({
            show: false,
        });
    };

    refreshToken = async (func) => {
        const url =
            process.env.REACT_APP_SERVER + '/user/generate-access-token';
        const token = sessionStorage.getItem('refreshToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios
            .get(url, { headers })
            .then((res) => {
                if (res.status === 200) {
                    sessionStorage.setItem('accessToken', res.data.accessToken);
                    func();
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleChangeInfo = async (e) => {
        this.setState({
            show: false,
        });
        const url = process.env.REACT_APP_SERVER + '/user/edit-customer';
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        const apiData = { ...this.state.customer };
        console.log('line 135 api data', apiData);
        await axios
            .patch(url, apiData, { headers })
            .then((res) => {
                if (res.status === 251) {
                    this.refreshToken(this.handleChangeInfo);
                } else if (res.status === 200) {
                    this.props.getAllCustomerDetails();
                }
            })
            .catch((err) => console.log(err));
    };

    render() {
        return (
            <div>
                <Navbar />
                <section>
                    <Modal
                        show={this.state.show}
                        onHide={this.handleModalClose}
                        centered
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Customer Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <label
                                        htmlFor="sectionName"
                                        className="col-form-label"
                                    >
                                        Customer Name
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="customer_name"
                                        value={this.state.customer.customer_name}
                                        name="customer_name"
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <label
                                        htmlFor="sectionName"
                                        className="col-form-label"
                                    >
                                        Mobile No
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="mobile_no"
                                        value={this.state.customer.mobile_no}
                                        name="mobile_no"
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <label
                                        htmlFor="sectionName"
                                        className="col-form-label"
                                    >
                                        Company Name
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="company_name"
                                        value={this.state.customer.company_name}
                                        name="company_name"
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <label
                                        htmlFor="sectionName"
                                        className="col-form-label"
                                    >
                                        Designation
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="designation"
                                        value={this.state.customer.designation}
                                        name="designation"
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <label
                                        htmlFor="sectionName"
                                        className="col-form-label"
                                    >
                                        Company Url
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="company_url"
                                        value={this.state.customer.company_url}
                                        name="company_url"
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button
                                    onClick={this.handleChangeInfo}
                                    className="btn btn-primary mx-auto"
                                >
                                    Save Customer Information
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <div className="row justify-content-center">
                        <div class="col-auto">
                            <label for="searchBar" class="visually-hidden">
                                Search
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                id="searchBar"
                                placeholder="Search Customers"
                                onChange={this.onChangeSearchInput}
                            />
                        </div>
                    </div>
                    <div className="container">
                        <table
                            className="table"
                            style={{ height: 'fit-content' }}
                        >
                            <thead>
                                <tr>
                                    <th scope="col">Customer ID</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Mobile</th>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Designation</th>
                                    <th scope="col">Company URL</th>
                                    <th scope="col">Registration Date</th>
                                    <th scope="col">Edit</th>
                                    <th scope="col">Delete</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.filteredCustomers &&
                                    this.props.filteredCustomers.map(
                                        (customer, index) => (
                                            <tr key={index}>
                                                <th>{index + 1}</th>
                                                <td>{customer.customer_name}</td>
                                                <td>{customer.mobile_no}</td>
                                                <td>{customer.company_name}</td>
                                                <td>{customer.designation}</td>
                                                <td>{customer.company_url} </td>
                                                {/*//Company Url not sent */}
                                                <td>{customer.created_on}</td>
                                                <td>
                                                <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() =>
                                                            this.handleEdit(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <MdEdit
                                                            color="black"
                                                            size={30}
                                                        />
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn"
                                                        onClick={() =>
                                                            this.deleteHandler(
                                                                customer.customer_id
                                                            )
                                                        }
                                                    >
                                                        <MdDelete
                                                            color="black"
                                                            size={20}
                                                        />
                                                    </button>
                                                </td>
                                                <td>{customer.survey_status}</td>
                                            </tr>
                                        )
                                    )}
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { customers } = state;
    return customers;
};

const mapDispatchToProps = {
    filterCustomers,
    resetFilter,
    getAllCustomerDetails,
    searchCustomers,
};

const ReduxCustomersTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersTable);

export default (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    return <ReduxCustomersTable location={location} navigate={navigate} />;
};
