import styles from "../styles";
import {
  View,
  Text,
  Image,
  Svg,
  Rect,
  G,
  Defs,
  ClipPath,
  Link,
} from "@react-pdf/renderer";
import Header from "./Header";
import Footer from "./Footer";
import TenthpageImage from "../../assets/images/TenthpageImg.png";

export const Page11 = ({ about }) => {
  return (
    <View style={styles.section_right}>
      <Header />
      <Text style={styles.heading}>How can we help?</Text>
      <View
        style={{
          marginTop: "20",
          marginBottom: "20",
          paddingRight: "10",
          paddingLeft: "35",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Text
          style={{
            color: "gray",
            fontSize: "12",
            marginBottom: "20",
            lineHeight: 1.3,
          }}
        >
          Congratulations on completing your digital maturity assessment! We
          hope you found this useful. We also provide extensive and in-depth
          assessment for digital transformation roadmap framework which is
          designed to help companies answer following critical questions related
          to Digital Transformation:
        </Text>

        <View style={{ flexDirection: "row", marginTop: "20" }}>
          <Text style={{ color: "gray", fontSize: "12", marginHorizontal: 8 }}>
            1.
          </Text>
          <Text style={{ color: "gray", fontSize: "12" }}>
            What do I want to achieve via Digital for my Organization?
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={{ color: "gray", fontSize: "12", marginHorizontal: 8 }}>
            2.
          </Text>
          <Text style={{ color: "gray", fontSize: "12" }}>
            What capabilities, org. structures and resources will I need to
            achieve my goals?
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={{ color: "gray", fontSize: "12", marginHorizontal: 8 }}>
            3.
          </Text>
          <Text style={{ color: "gray", fontSize: "12" }}>
            What are the current gaps and what investment will be required to
            close the gaps?
          </Text>
        </View>
        <View style={{ flexDirection: "row", marginBottom: "20" }}>
          <Text style={{ color: "gray", fontSize: "12", marginHorizontal: 8 }}>
            4.
          </Text>
          <Text style={{ color: "gray", fontSize: "12" }}>
            Where should I prioritize and how do I start?
          </Text>
        </View>
        <View
          style={{ flexDirection: "row", marginTop: "20", marginBottom: "20" }}
        >
          <View style={{ flexDirection: "column", width: "38%" }}>
            <Image src={TenthpageImage} alt="" />
          </View>
          <View style={{ flexDirection: "column", width: "60%" }}>
            <Text
              style={{
                color: "gray",
                fontSize: "12",
                marginBottom: 4,
                lineHeight: 1.3,
              }}
            >
              Our framework uses our proprietary tools to create a highly
              customized and effective transformation roadmap aligned to your
              strategic objectives.
            </Text>
            <Text style={{ color: "gray", fontSize: "12", marginBottom: "10" }}>
              Results from companies using our assessment framework:
            </Text>
            <View style={{ flexDirection: "row", marginBottom: 4 }}>
              <Text style={{ fontSize: "12", marginHorizontal: 8 }}>•</Text>
              <Text style={{ color: "gray", fontSize: "12" }}>
                20+ companies assessed in Indian Auto sector
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginBottom: 4 }}>
              <Text style={{ fontSize: "12", marginHorizontal: 7 }}>•</Text>
              <Text style={{ color: "gray", fontSize: "12" }}>
                100+ digital initiatives identified
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginBottom: 4 }}>
              <Text style={{ fontSize: "12", marginHorizontal: 8 }}>•</Text>
              <Text style={{ color: "gray", fontSize: "12" }}>
                50 digital applications built for Auto
              </Text>
            </View>
          </View>
        </View>
        <Text style={{ color: "gray", fontSize: "12", paddingBottom: "20" }}>
          Click on the link below and one of our experts will be happy to
          organize a 1on1 with you and your team to discuss how we can help you
          in your digital transformation journey.
        </Text>

        <Link src="mailto:admin@silfratech.com?subject=Discussion on digital transformation with c4i4!&body=Yes, I am interested!">
          <Svg viewBox="0 0 180 30">
            <G>
              <Rect
                x="48"
                rx="5"
                ry="5"
                width="90"
                height="20"
                fill="#43C6DB"
              />

              <Text
                x="60"
                y="13"
                fill="black"
                style={{ fontSize: "7", color: "gray", marginBottom: "10" }}
              >
                Yes, I am interested
              </Text>
            </G>
          </Svg>
        </Link>
        <button className="bottomBtn">
          <a
            href="mailto:admin@silfratech.com?subject=Discussion on digital transformation with c4i4!&body=Yes, I am interested!"
            style={{ color: "white" }}
          >
            Yes, I am interested
          </a>
        </button>
      </View>
      <Footer pgnum="11" />
    </View>
  );
};
