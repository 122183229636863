import { View, Text, Image, Svg, Path, Defs, G } from "@react-pdf/renderer";
import styles from "../styles";
import { ReactComponent as Logo } from "../../assets/images/Silfra_Logo.png";
import logo from "../../assets/images/Silfra_Logo.png";

const Header = () => {
  {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
  }
  return (
    <View style={styles.header}>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={{ flexDirection: "column", width: "33%" }}>
          <View style={{ width: "100%", height: "auto" }}>
            <Image src={logo} style={{ width: "20%", height: "auto" }} />
          </View>
        </View>

        <View style={{ flexDirection: "column", width: "33%" }}>
          <Text style={{ textAlign: "center" }}>
            {" "}
            Digital Transformation E-Book
          </Text>
        </View>
        <View style={{ flexDirection: "column", width: "33%" }}>
          <Text style={{ textAlign: "right" }}>{today}</Text>
        </View>
      </View>
    </View>
  );
};

export default Header;
