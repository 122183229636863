import styles from '../styles'
import { View, Text, Image, Svg, Line, G, Path } from '@react-pdf/renderer'
import fourkeyImg from '../../assets/images/fourkey1.png';
import Header from './Header'
import Footer from './Footer'

export const Page5 = ({ about }) => {
  return (
    <View style={styles.section_right}>
      <Header />
      <Text style={styles.heading}>Digital Maturity in Indian Manufacturing Organizations  </Text>
      <Text style={{ color: 'gray', lineHeight: 1.3, fontSize: '12', marginTop: '10', marginBottom: '10', paddingRight: '20', paddingLeft: '38' }}>  This section provides a summary of the digital maturity along the 4 components mentioned in section 1.  </Text>

      {/* ordered list left starts */}
      <View style={{ flexDirection: "row" }}>
        <View style={{ flexDirection: "column", width: '45%' }}>
          <Text style={{ fontSize: '12', marginTop: '10', marginBottom: '10', paddingRight: '40', paddingLeft: '40' }}>1. Digital Vision and Strategy:</Text>
          <View style={{ marginTop: '0', marginBottom: '5', marginLeft: "45", paddingRight: '30', flexDirection: "row" }}>
            <Text style={{ marginHorizontal: 8 }}>•</Text>
            <Text style={{ lineHeight: 1.3, fontSize: '12', color: "gray" }}>Digital Strategy is being owned and driven mostly by
              senior leadership consisting
              of CEO’s, BU Heads and MD’s.
              All the organizations
              interviewed have some
              digital investments planned
              but these vary widely
              depending mainly on the
              current maturity of the
              organizations</Text>
          </View>
          <View style={{ marginTop: '0', marginBottom: '5', marginLeft: "45", paddingRight: '30', flexDirection: "row" }}>
            <Text style={{ marginHorizontal: 8 }}>•</Text>
            <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}>The awareness of digital
              technologies is not very high
              and the investments on
              digital is being done for
              reducing operating costs</Text>
          </View>
          <View style={{ marginBottom: '5', marginLeft: "45", paddingRight: '30', flexDirection: "row" }}>
            <Text style={{ marginHorizontal: 8 }}>•</Text>
            <Text style={{ color: "grey", lineHeight: 1.3, fontSize: '12' }}>Most common KPI’s from a
              Digital Transformation
              perspective are employee
              productivity, improving
              machine utilization and
              reducting quality rejects.</Text>
          </View>
        </View>
        <View style={{ flexDirection: "column", width: '55%' }}>
          <View style={{ lineHeight: 1.3, marginTop: '40', marginBottom: '10', paddingLeft: '5', paddingTop: '10', paddingBottom: '10', flexDirection: "row", borderTop: '4px solid #2ca5d1', borderBottom: '4px solid #2ca5d1', color: '#2ca5d1', fontStyle: "oblique" }}>

            <Text style={styles.specia}>85% of the organizations that we have assessed have their
              CEO’s or Business Heads driving digital transformation.
              Most have not hired digital senior leadership </Text>
          </View>
          <View style={{ lineHeight: 1.3, marginTop: '10', marginBottom: '10', paddingLeft: '5', paddingTop: '10', paddingBottom: '10', flexDirection: "row", borderTop: '4px solid #2ca5d1', borderBottom: '4px solid #2ca5d1', color: '#2ca5d1', fontStyle: "italic" }}>

            <Text style={styles.specia}>Digital Related investments planned vary widely from INR
              5 lacs to INR 30 Cr in the organizations assessed. The
              investment is highly correlated to the overall digital
              maturity of the organization</Text>
          </View>
          <View style={{ marginTop: '10', marginBottom: '10', paddingLeft: '5', paddingTop: '10', paddingBottom: '10', flexDirection: "row", borderTop: '4px solid #2ca5d1', borderBottom: '4px solid #2ca5d1' }}>
            <View style={{ flexDirection: 'Column' }}>
              <Text style={{ lineHeight: 1.3, fontSize: '10', textAlign: 'center', color: "gray" }}>GOALS FROM DIGITAL INVERSTMENTS IN NEXT 3 YEARS</Text>
              <Svg height="70" width="100%">
                <G>
                  <Text x="10" y="33" style={{ fontSize: '8', color: 'gray', fontFamily: "Roboto" }}>Quality Rejects</Text>
                  <Text x="50" y="43" style={{ fontSize: '8', color: 'gray', fontFamily: "Roboto" }}>OEE</Text>
                  <Text x="0" y="53" style={{ fontSize: '8', color: 'gray', fontFamily: "Roboto" }}>Revenue/Employee</Text>
                  <Text x="165" y="33" style={{ fontSize: '8', color: 'gray', fontFamily: "Roboto" }}>7%</Text>
                  <Text x="210" y="43" style={{ fontSize: '8', color: 'gray', fontFamily: "Roboto" }}>10%</Text>
                  <Text x="248" y="53" style={{ fontSize: '8', color: 'gray', fontFamily: "Roboto" }}>15%</Text>
                  <Line x1="75" y1="20" x2="75" y2="60" strokeWidth={2} stroke="lightgray" />
                  <Path d="M 80 50 L 245 50 L 245 51 L 80 51 L 80 50 Z" stroke="rgb(255, 230, 0)" strokeWidth={3} />
                  <Path d="M 80 40 L 205 40 L 205 41 L 80 41 L 80 40 Z" stroke="rgb(255, 230, 0)" strokeWidth={3} />
                  <Path d="M 80 30 L 160 30 L 160 31 L 80 31 L 80 30 Z" stroke="rgb(255, 230, 0)" strokeWidth={3} />
                </G>
              </Svg>
              <Text style={{ fontSize: '6', color: 'gray', fontFamily: "Roboto" }}>All % benefits over current baseline</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: "column" }}>
        <Text style={{ fontSize: '12', marginTop: '10', marginBottom: '10', paddingRight: '40', paddingLeft: '40' }}>2. Data & Analytics:</Text>
        <View style={{ marginTop: '0', marginBottom: '10', paddingRight: '40', paddingLeft: '60', flexDirection: "row" }}>
          <Text style={{ color: "grey", fontSize: '12' }}>Usage of data is mostly being done for reporting purposes. Leveraging analytics
            for data based decision making in the organizations is limited. It is a culture
            challenge as much as a technology or skills gap</Text>
        </View>
      </View>


      <Footer pgnum='5' />
    </View>
  )
}