import React, { Component } from "react";
import axios from "axios";
import { useNavigate, NavLink, Link, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import image from "../../assets/images/Silfra_Logo.png";
import { validateEmail, validatePassword } from "../../utils";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-toastify";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      otp: "",
      password: "",
      username: "",
      confirmPassword: "",
      error: true,
      otpSent: false,
      otpVerified: false,
      errors: {},
      timer: 0,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.username !== this.state.username) {
      const url = process.env.REACT_APP_SERVER + "/user/check-username";
      const apiData = {
        username: this.state.username,
      };
      await axios
        .post(url, apiData)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              error: false,
              errors: {
                ...this.state.errors,
                username: null,
              },
            });
          } else {
            this.setState({
              error: true,
              errors: {
                ...this.state.errors,
                username: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (prevState.email !== this.state.email) {
      const url = process.env.REACT_APP_SERVER + "/user/check-email";
      const apiData = {
        email: this.state.email,
      };
      await axios
        .post(url, apiData)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              error: false,
              errors: {
                ...this.state.errors,
                email: null,
              },
            });
          } else {
            this.setState({
              error: true,
              errors: {
                ...this.state.errors,
                email: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          this.setState({
            error: true,
            errors: {
              ...this.state.errors,
              email: "Please enter a valid Email3.",
            },
          });
        });
    }
  }

  onChangeInput = (e) => {
    if (e.target.name === "password") {
      // validate the email.
      let val = e.target.value;
      if (val === "" || !validatePassword(val)) {
        this.setState({
          errors: {
            ...this.state.errors,
            password: "Please enter a Strong Password.",
          },
          error: true,
          [e.target.name]: e.target.value,
        });
        return;
      }
    }

    if (e.target.name === "confirmPassword") {
      // validate the password.
      let val = e.target.value;
      if (val === "" || val !== this.state.password) {
        this.setState({
          errors: {
            ...this.state.errors,
            confirmPassword: "Passwords don't match.",
          },
          error: true,
          [e.target.name]: e.target.value,
        });
        return;
      }
    }

    this.setState((prev) => ({
      errors: {
        ...prev.errors,
        [e.target.name]: null,
      },
      error: Object.values(prev.errors).filter((x) => x === null).length === 4,
      [e.target.name]: e.target.value,
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { password = null, confirmPassword = null } = this.state.errors;
    if (password === null && confirmPassword === null) {
      const url =
        process.env.REACT_APP_SERVER +
        "/user/new-password?token=" +
        this.props.token;

      const apiData = {
        new_password: this.state.password,
      };
      await axios
        .post(url, apiData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Your password changed sucessfully");
            this.props.navigate("/dmat/admin/login");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => toast.error(err?.message));
    }
  };

  render() {
    let ConfirmPasswordError = this.state.errors.confirmPassword ? (
      <div className="text-danger">{this.state.errors.confirmPassword}</div>
    ) : null;
    let PasswordError = this.state.errors.password ? (
      //   <div className="text-danger">{this.state.errors.password}</div>
      <div className="text-danger">
        <h5 className="fw-bold pt-1">Weak Password!</h5>
        <ul className="text-danger fw-bold">
          <li>Use at least 5 character</li>
          <li>Use upper and lower case character</li>
          <li>Use one or more numbers</li>
          <li>Use one or more special character</li>
        </ul>
      </div>
    ) : null;
    return (
      <>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="container collapse navbar-collapse"
                id="navbarTogglerDemo01"
              >
                <NavLink
                  className="navbar-brand"
                  to="/admin"
                  activeClassName="active"
                >
                  <img
                    style={{ width: "20%", height: "20%" }}
                    src={image}
                    alt="C4i4 Logo"
                  />
                </NavLink>
                <div>
                  <h1 style={{ color: "royalblue" }}>
                    Digital Maturity Assesment Survey
                  </h1>
                </div>
              </div>
              {/* <Link to="/admin">
                                <button className="btn btn-primary">
                                    Admin
                                </button>
                            </Link> */}
            </div>
          </nav>
        </div>
        <main>
          <div className="form-main">
            <form className="form mt-3">
              <h3 style={{ paddingBottom: "5%" }}>Reset Password</h3>

              {/* <div className="row content">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label
                                            style={{ fontSize: '20px' }}
                                            htmlFor="username"
                                        >
                                            Email
                                        </label>
                                        <input
                                            style={{ fontSize: '20px' }}
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            onChange={this.onChangeInput}
                                        />
                                        {EmailError}
                                    </div>
                                </div>
                            </div> */}
              <div className="row content">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="otp" style={{ fontSize: "20px" }}>
                      Enter New Password
                    </label>
                    <input
                      style={{ fontSize: "20px" }}
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      onChange={this.onChangeInput}
                    />
                    {PasswordError}
                  </div>
                </div>
              </div>
              <div className="row content">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="otp" style={{ fontSize: "20px" }}>
                      Confirm Password
                    </label>
                    <input
                      style={{ fontSize: "20px" }}
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={this.onChangeInput}
                    />
                    {ConfirmPasswordError}
                  </div>
                </div>
              </div>
              <div className="row mb-2 mt-2 d-flex justify-content-center">
                <Loader loading={this.state.loading} />
              </div>
              <div className="text-center mt-3" onClick={this.handleSubmit}>
                <div className="btn btn-primary" style={{ fontSize: "20px" }}>
                  Submit
                </div>
              </div>
              {/* <Link to="/admin/" className="">
                                <p className="text-center mt-3">
                                    Already have an account? Sign In Here
                                </p>
                            </Link> */}
            </form>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}

export default (props) => {
  const navigate = useNavigate();
  let { token } = useParams();
  return <ResetPassword token={token} navigate={navigate} />;
};
