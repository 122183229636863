import Navbar from "./Navbar";
import React, { Component } from "react";

export class LandingPage extends Component {
  render() {
    return (
      <>
        <Navbar />
        <main></main>
      </>
    );
  }
}

export default LandingPage;
