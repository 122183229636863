import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from './Login';
import Registration from './Registration';
import Foreword from './Foreword';
import Introduction from './Introduction';
import Assessment from './Assessment';
import NotFound from './site/NotFound';
import FunctionalAssessment from './FunctionalAssessment';
import DigTrans from './DigTrans.js';
import './styles.css';
import CustomerDetailsQuestions from './CustomerDetailsQuestions';
import OneAssessment from './OneAssessment';
import AdminApp from './admin/AdminApp';
import Report from './Report';
import ResetPassword from './admin/ResetPassword';
import { Navigate } from 'react-router-dom';

class App extends React.Component {
    // const search = useLocation().search;
         //  const accessToken= sessionStorage.getItem("accessToken")
         constructor(props) {
            super(props);
         this.state={
            accessToken:sessionStorage.getItem("accessToken")
         }
      }
         
         


    render() {
      
      


        const token = new URLSearchParams(window.location.search).get('token');
        const page = new URLSearchParams(window.location.href).get('page');
        console.log('token', token);
        console.log('page', page);
        console.log('this.props', this.props);
        if (page === 'resetPassword') {
            this.props.navigation?.navigate(
                '/admin/resetPassword?token=' + token
            );
        }
        return (
            <div>

                {console.log("accessToken=",this.state.accessToken)}
                {page === 'resetPassword' ? (
                    <Navigate to="/dmat/admin/resetPassword" token={token} />
                ) : null}
                {console.log("This is App.js Section")}
                <Routes>
                    <Route
                        path="/dmat/admin/resetPassword"
                        element={<ResetPassword token={token} />}
                    />
                    <Route path="/dmat/" element={<Login />} />
                    <Route path="/dmat/login" element={<Login />} />
                    <Route path="/dmat/register" element={<Registration />} />
                    <Route path="/dmat/introduction" element={<Introduction />} />
                    <Route path="/dmat/assessment" element={
                      <OneAssessment/>} />
                    <Route
                        path="/dmat/functionalAssessment"
                        element={ 
                    
                      <OneAssessment/>}
                    />
                    <Route
                        path="/dmat/CustomerDetailsQuestions"
                        element={ 
                    
                      <OneAssessment/>}
                    />
                    <Route path="/dmat/oneAssessment" element={
                     
                      <OneAssessment/>} />
                    <Route
                        path="/dmat/report2"
                        element={<DigTrans odmScore={18} fmScore={24} />}
                    />
                    <Route path="/dmat/report" element={<Report />} />
                    <Route path="/dmat/admin/*" element={<AdminApp />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        );
    }
}

export default App;
