import React, { Component } from "react";
import axios from "axios";
import { useNavigate, NavLink, Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";

import image from "../../assets/images/Silfra_Logo.png";
import {
  containsSpecialChars,
  validateEmail,
  validatePassword,
} from "../../utils";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-toastify";

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      otp: "",
      password: "",
      username: "",
      confirmPassword: "",
      error: true,
      otpSent: false,
      otpVerified: false,
      errors: {},
      timer: 0,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.username !== this.state.username) {
      const url = process.env.REACT_APP_SERVER + "/user/check-username";
      const apiData = {
        username: this.state.username,
      };
      await axios
        .post(url, apiData)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              error: false,
              errors: {
                ...this.state.errors,
                username: null,
              },
            });
          } else {
            this.setState({
              error: true,
              errors: {
                ...this.state.errors,
                username: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (prevState.email !== this.state.email) {
      const url = process.env.REACT_APP_SERVER + "/user/check-email";
      const apiData = {
        email: this.state.email,
      };
      await axios
        .post(url, apiData)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              error: false,
              errors: {
                ...this.state.errors,
                email: null,
              },
            });
          } else {
            this.setState({
              error: true,
              errors: {
                ...this.state.errors,
                email: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          this.setState({
            error: true,
            errors: {
              ...this.state.errors,
              email: "Please enter a valid Email.",
            },
          });
        });
    }
  }

  onChangeInput = (e) => {
    console.log("line 99");
    // validate the email.
    if (e.target.name === "username") {
      // validate the email.
      let val = e.target.value;
      if (
        val === ""
        //    || containsSpecialChars(val)
      ) {
        this.setState({
          errors: {
            username: "Please enter a valid username.",
          },
          error: true,
        });
        return;
      }
    }
    if (e.target.name === "email") {
      // validate the email.
      let val = e.target.value;
      if (val === "" || !isEmail(val)) {
        this.setState({
          errors: {
            ...this.state.errors,
            email: "Please enter a valid Email.",
          },
          error: true,
        });
        return;
      }
    }

    if (e.target.name === "password" || validatePassword(e.target.name)) {
      // validate the email.
      let val = e.target.value;
      console.log("line 132 val", val);
      if (val === "" || !validatePassword(val)) {
        this.setState({
          errors: {
            ...this.state.errors,
            password: `Passwords should have a minimum length of at least Five characters \n  Passwords should have a atleast 1 upper case Passwords should have atleast 1 number Passwords should have atleast 1 special character`,
          },
          error: true,
        });
        return;
      }
      //   else if (val === '') {
      //       this.setState({
      //           errors: {
      //               ...this.state.errors,
      //               password: 'Please enter the Password.',
      //           },
      //           error: true,
      //       });
      //       return;
      //   }
    }

    if (
      e.target.name === "confirmPassword" ||
      (e.target.name === "password" && this.state.confirmPassword !== "")
    ) {
      // validate the password.
      let val = e.target.value;
      console.log("line133 val");
      if (val === "" || val !== this.state.password) {
        this.setState({
          errors: {
            ...this.state.errors,
            confirmPassword: "Passwords don't match.",
          },
          error: true,
        });
        return;
      }
    }

    this.setState({
      errors: {
        ...this.state.errors,
        [e.target.name]: null,
      },
      error:
        Object.values(this.state.errors).filter((x) => x === null).length === 4,
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      this.state.error &&
      Object.values(this.state.errors).filter((x) => x !== null).length !== 0
    ) {
      return null;
    }

    if (
      this.state.error === false &&
      Object.values(this.state.errors).filter((x) => x !== null).length === 0 &&
      this.state.confirmPassword !== "" &&
      this.state.password !== "" &&
      this.state.email !== "" &&
      this.state.username !== ""
    ) {
      const url = process.env.REACT_APP_SERVER + "/user/register";
      const apiData = {
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
      };
      axios
        .post(url, apiData)
        .then((res) => {
          if (res.status === 200) {
            toast.success(
              "Please Check your email and complete registration process"
            );
            // sessionStorage.setItem('token', res.data.token);
            // this.props.navigate('/admin/registration');
          } else {
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (
        this.state.confirmPassword === "" &&
        this.state.password === "" &&
        this.state.email === "" &&
        this.state.username === ""
      ) {
        this.setState({
          errors: {
            ...this.state.errors,
            confirmPassword: "Passwords don't match.",
            email: "Please enter a valid email.",
            password: "Enter the passwords.",
            username: "Enter the user name.",
          },
          error: true,
        });
        //   return;
      }
      let errors = {};
      if (this.state.password === "") {
        errors = {
          ...errors,
          password: "Enter the password.",
        };
        //   return;
      }

      if (this.state.confirmPassword === "") {
        errors = {
          ...errors,
          confirmPassword: "Passwords does not match.",
        };
        //   return;
      }
      if (this.state.email === "") {
        errors = {
          ...errors,
          email: "Please enter a valid Email.",
        };
        //   return;
      }
      if (
        this.state.username === ""
        //         || containsSpecialChars(val)
      ) {
        errors = {
          ...errors,
          username: "Please enter a valid username.",
        };
      }

      this.setState({ errors: errors });
    }
  };
  render() {
    console.log("line 260", this.state.errors);
    let UserNameError = this.state.errors.username ? (
      <div className="text-danger">{this.state.errors.username}</div>
    ) : null;
    let EmailError = this.state.errors.email ? (
      <div className="text-danger">{this.state.errors.email}</div>
    ) : null;
    let ConfirmPasswordError = this.state.errors.confirmPassword ? (
      <div className="text-danger">{this.state.errors.confirmPassword}</div>
    ) : null;
    let PasswordError = this.state.errors.password ? (
      //   <div className="text-danger">{this.state.errors.password}</div>
      <div className="text-danger">
        <h5 className="fw-bold pt-1">Weak Password!</h5>
        <ul className="text-danger fw-bold">
          <li>Use at least 5 character</li>
          <li>Use upper and lower case character</li>
          <li>Use one or more numbers</li>
          <li>Use one or more special character</li>
        </ul>
      </div>
    ) : null;
    return (
      <>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="container collapse navbar-collapse"
                id="navbarTogglerDemo01"
              >
                <NavLink
                  className="navbar-brand"
                  to="#!"
                  activeClassName="active"
                >
                  <img
                    style={{ width: "20%", height: "20%" }}
                    src={image}
                    alt="C4i4 Logo"
                  />
                </NavLink>
                <div>
                  <h1 style={{ color: "royalblue" }}>Admin Register </h1>
                </div>
              </div>
              <Link to="/dmat">
                <button className="btn btn-primary">Home</button>
              </Link>
            </div>
          </nav>
        </div>
        <main>
          <div className="form-main">
            <form className="form mt-3">
              <h3 style={{ paddingBottom: "5%" }}>Register</h3>
              <div className="row content">
                <div className="col-md-12">
                  <div className="form-group">
                    <label style={{ fontSize: "20px" }} htmlFor="username">
                      User Name
                    </label>
                    <input
                      style={{ fontSize: "20px" }}
                      type="username"
                      className="form-control"
                      id="username"
                      name="username"
                      onChange={this.onChangeInput}
                    />
                    {UserNameError}
                  </div>
                </div>
              </div>
              <div className="row content">
                <div className="col-md-12">
                  <div className="form-group">
                    <label style={{ fontSize: "20px" }} htmlFor="username">
                      Email
                    </label>
                    <input
                      style={{ fontSize: "20px" }}
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      onChange={this.onChangeInput}
                    />
                    {EmailError}
                  </div>
                </div>
              </div>
              <div className="row content">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="otp" style={{ fontSize: "20px" }}>
                      Password
                    </label>
                    <input
                      style={{ fontSize: "20px" }}
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      onChange={this.onChangeInput}
                    />
                    {PasswordError}
                  </div>
                </div>
              </div>
              <div className="row content">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="otp" style={{ fontSize: "20px" }}>
                      Confirm Password
                    </label>
                    <input
                      style={{ fontSize: "20px" }}
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={this.onChangeInput}
                    />
                    {ConfirmPasswordError}
                  </div>
                </div>
              </div>
              <div className="row mb-2 mt-2 d-flex justify-content-center">
                <Loader loading={this.state.loading} />
              </div>
              <div className="text-center mt-3" onClick={this.handleSubmit}>
                <div className="btn btn-primary" style={{ fontSize: "20px" }}>
                  Register
                </div>
              </div>
              <Link to="/dmat/admin/" className="">
                <p className="text-center mt-3">
                  Already have an account? Sign In Here
                </p>
              </Link>
            </form>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <SignUp navigate={navigate} />;
};
