import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="footer mt-3 mb-3">
          © 2024 Copyright: Silfra Technologies
        </div>
      </footer>
    );
  }
}

export default Footer;
