import React from 'react';
import Loader from '../components/Loader';
import {
    containsChars,
    validateEmail,
    validateCompanyName,
    containsSpecialChars,
} from '../utils';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link, Navigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import { getInitialScore } from '../redux/reducers/score';
import { connect, useDispatch, useSelector } from 'react-redux';

class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            form: {
                name: '',
                company_name: '',
                company_url: '',
                designation: '',
                official_email: '',
                mob: '',
                country: 'India',
            },
            error: true,
            otpSent: false,
            otpVerified: false,
            errors: {},
            timer: 0,
            redirect: false,
        };
    }

    async componentDidMount() {
        console.log('this.getDetails', this.getDetails());
        console.log('this.props.InitialScore', this.props.getInitialScore());
        this.getDetails();
        this.props.getInitialScore();
    }

    getDetails = async () => {
        const url = process.env.REACT_APP_SERVER + '/customer/get-details';
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios.get(url, { headers }).then((res) => {
            if (res.status === 251) {
                this.refreshToken(this.getDetails);
            } else if (res.status === 200 || res.status === 201) {
                console.log('line56 res', res);
                this.setState({
                    form: {
                        name: res.data.user_details.customer_name,
                        company_url: res.data.user_details.company_url,
                        company_name: res.data.user_details.company_name,
                        designation: res.data.user_details.designation,
                        official_email: res.data.user_details.company_email_id,
                        mob: res.data.user_details.mobile_no,
                        country: res.data.user_details.country,
                    },
                });
            }
        });
    };

    refreshToken = async (func) => {
        const url =
            process.env.REACT_APP_SERVER + '/customer/generate-access-token';
        const token = sessionStorage.getItem('refreshToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios.get(url, { headers }).then((res) => {
            if (res.status === 200) {
                console.log('line78 res=', res);
                sessionStorage.setItem('accessToken', res.data.access_token);
            }
            func();
        });
    };

    onChangePhone = (e, country) => {
        this.setState({
            form: {
                ...this.state.form,
                mob: e,
                country: country.name,
            },
        });
        if (e.length < 10) {
            this.setState({
                error: true,
                errors: {
                    ...this.state.errors,
                    mob: 'Please Enter Valid Mobile Number',
                },
            });
        } else {
            const { mob, ...rest } = this.state.errors;
            this.setState({
                error: false,
                errors: rest,
            });
        }
    };

    onValidate = (key, value) => {
        if (key === 'email' || key === 'name') {
            if (key === 'email' && value !== '' && !validateEmail(value)) {
                this.setState({
                    errors: {
                        email: 'Please use official email id.',
                    },
                    error: true,
                });
                return;
            } else if (
                key === 'name' &&
                (value === '' ||
                    !containsChars(value) ||
                    containsSpecialChars(value))
            ) {
                this.setState({
                    form: {
                        ...this.state.form,
                        [key]: value,
                    },
                    errors: {
                        ...this.state.errors,
                        name: 'Please enter a valid name.',
                    },
                    error: true,
                });
                return;
            } else {
                const { name, ...rest } = this.state.errors;
                this.setState({
                    error: false,
                    errors: rest,
                });
            }
        }

        if (key === 'company_name') {
            if (
                value === '' ||
                value.length < 3
                //       ||
                //       !containsSpecialChars(value)
            ) {
                this.setState({
                    form: {
                        ...this.state.form,
                        [key]: value,
                    },
                    errors: {
                        ...this.state.errors,
                        company_name: 'Please enter a valid company name.',
                    },
                    error: true,
                });
                return;
            } else {
                const { company_name, ...rest } = this.state.errors;
                this.setState({
                    error: false,
                    errors: rest,
                });
            }
        }

        this.setState({
            form: {
                ...this.state.form,
                [key]: value,
            },
        });
    };
    onChangeInput = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        this.onValidate(key, value);

        console.log('line no 184 error', this.state.error);
        console.log('line no 185 errors', this.state.errors);
    };

    onClickHandler = async (e) => {
        //   let value = e.target.value;
        console.log('Line no 187', this.state.form);
        e.preventDefault();
        e.stopPropagation();
        let error = false;
        let errors = {};
        if (
            this.state.form.name === '' ||
            !containsChars(this.state.form.name) ||
            containsSpecialChars(this.state.form.name)
        ) {
            error = true;
            errors.name = 'Please enter a valid name.';
        }
        if (this.state.form.company_name === '') {
            error = true;
            errors.company_name = 'Please enter a valid company name.';
        }

        // email.
        if (
            this.state.form.email === '' ||
            !validateEmail(this.state.form.email)
        ) {
            error = true;
            errors.email = 'Please use official email id.';
        }
        //         mob
        if (this.state.form.mob?.length < 10) {
            error = true;
            errors.mob = 'Please Enter Valid Mobile Number';
        }

        if (error) {
            this.setState({
                error: true,
                errors,
            });
            return;
        }
        // check if this.state.errors is empty object

        if (error === false) {
            // redirect to otp page
            const url =
                process.env.REACT_APP_SERVER + '/customer/enter-details';
            const apiData = {
                customer_name: this.state.form.name,
                company_name: this.state.form.company_name,
                mobile_no: this.state.form.mob,
                companyEmailId: this.state.form.official_email,
                designation: this.state.form.designation,
                country: this.state.form.country,
                company_url: this.state.form.company_url,
            };
            const token = sessionStorage.getItem('accessToken');
            const headers = { Authorization: `Bearer ${token}` };
            await axios.post(url, apiData, { headers }).then(async (res) => {
                if (res.status === 251) {
                    this.refreshToken(this.onClickHandler);
                } else if (res.status === 200) {
                    await this.updateDataHandler();
                    this.setState({
                        redirect: true,
                    });
                } else if (res.status === 299) {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            mob: 'Mobile number is already used',
                        },
                    });
                } else if (res.status == 231) {
                    const url =
                        process.env.REACT_APP_SERVER +
                        '/customer/update-details';
                    const headers = { Authorization: `Bearer ${token}` };
                    await axios
                        .patch(url, apiData, { headers })
                        .then(async (res) => {
                            if (res.status === 251) {
                                this.refreshToken(this.onClickHandler);
                            } else if (res.status === 299) {
                                this.setState({
                                    errors: {
                                        ...this.state.errors,
                                        mob: 'Mobile number is already used',
                                    },
                                });
                            } else if (res.status === 200) {
                                await this.updateDataHandler();
                                this.setState({
                                    redirect: true,
                                });
                            } else {
                                this.setState({
                                    redirect: true,
                                    error: true,
                                    errors: {
                                        ...this.state.errors,
                                        submit: 'There was an error while updating the details',
                                    },
                                });
                                console.log(res);
                            }
                        })
                        .catch((err) => {
                            this.setState({
                                error: true,
                                errors: {
                                    ...this.state.errors,
                                    submit: 'There was an error while updating the details',
                                },
                            });
                        });
                }
            });
        }
    };

    updateDataHandler = async () => {
        const url =
            process.env.REACT_APP_SERVER + '/customer/submit-survey-answers';
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        const updateData = {
            surveyAnswers: this.props.answers,
            isComplete: false,
            currentQuesNumber: this.props.currentQuestion,
            currentSection: 'Introduction',
        };
        console.log('lin323 update data=', updateData);
        if (
            updateData.surveyAnswers === undefined &&
            updateData.isComplete === false &&
            updateData.currentSection === 'Introduction' &&
            updateData.currentQuesNumber === undefined
        ) {
            console.log('lin330 update data=,', updateData);
        } else {
            await axios
                .post(url, updateData, { headers })
                .then((res) => {
                    console.log('line327 res', res);
                    if (res.status === 251) {
                        this.refreshToken(this.updateDataHandler);
                    } else if (res.status === 222) {
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                mob: 'Mobile number is already used',
                            },
                        });
                    } else if (res.status !== 200) {
                        console.log(res);
                    } else {
                        console.log('success');
                        console.log(res.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    render() {
        if (this.state.redirect) {
            return <Navigate to="/dmat/introduction" />;
        }
        let EmailError = this.state.errors.email ? (
            <div className="text-danger custom-margin-form-control">
                {this.state.errors.email}
            </div>
        ) : null;
        let NameError = this.state.errors.name ? (
            <div className="text-danger custom-margin-form-control">
                {this.state.errors.name}
            </div>
        ) : null;
        let companyNameError = this.state.errors.company_name ? (
            <div className="text-danger custom-margin-form-control">
                {this.state.errors.company_name}
            </div>
        ) : null;
        let MobError = this.state.errors.mob ? (
            <div className="text-danger custom-margin-form-control">
                {this.state.errors.mob}
            </div>
        ) : null;

        let GeneralError = this.state.errors.general ? (
            <div className="text-danger custom-margin-form-control">
                {this.state.errors.general}
            </div>
        ) : null;

        return (
            <div>
                <Navbar />
                <div className="container mt-3 mb-3">
                    <form className="mx-auto w-100 reg">
                        <div className="row g-3 align-items-center mt-3">
                            <div className="col-md-2">
                                <label
                                    htmlFor="email"
                                    className="col-form-label"
                                >
                                    <strong>
                                        Name{' '}
                                        <span className="text-danger"> *</span>
                                    </strong>
                                </label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type="name"
                                    className="form-control custom-margin-form-control"
                                    id="name"
                                    value={this.state.form.name}
                                    name="name"
                                    onChange={this.onChangeInput}
                                />
                                {NameError}
                            </div>
                        </div>

                        <div className="row g-3 align-items-center mt-2">
                            <div className="col-md-2">
                                <label
                                    htmlFor="cname"
                                    className="col-form-label"
                                >
                                    <strong>
                                        Company Name{' '}
                                        <span className="text-danger"> *</span>
                                    </strong>
                                </label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type="cname"
                                    value={this.state.form.company_name}
                                    className="form-control custom-margin-form-control"
                                    id="cname"
                                    name="company_name"
                                    onChange={this.onChangeInput}
                                />
                                {companyNameError}
                            </div>
                        </div>

                        <div className="row g-3 align-items-center mt-2">
                            <div className="col-md-2">
                                <label
                                    htmlFor="curl"
                                    className="col-form-label"
                                >
                                    <strong>Company URL</strong>
                                </label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type="url"
                                    className="form-control custom-margin-form-control"
                                    id="curl"
                                    value={this.state.form.company_url}
                                    name="company_url"
                                    onChange={this.onChangeInput}
                                />
                            </div>
                        </div>

                        <div className="row g-3 align-items-center mt-2">
                            <div className="col-md-2">
                                <label
                                    htmlFor="designation"
                                    className="col-form-label"
                                >
                                    <strong>Designation</strong>
                                </label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    value={this.state.form.designation}
                                    type="text"
                                    className="form-control custom-margin-form-control"
                                    id="designation"
                                    name="designation"
                                    onChange={this.onChangeInput}
                                />
                            </div>
                        </div>

                        <div className="row g-3 align-items-center mt-2">
                            <div className="col-md-2">
                                <label
                                    htmlFor="email"
                                    className="col-form-label"
                                >
                                    <strong>
                                        Office E-Mail{' '}
                                        <span className="text-danger"> *</span>
                                    </strong>
                                </label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type="email"
                                    value={this.state.form.official_email}
                                    className="form-control custom-margin-form-control"
                                    id="email"
                                    name="email"
                                    contentEditable={false}
                                    onChange={this.onChangeInput}
                                    disabled
                                />
                                {EmailError}
                            </div>
                        </div>

                        <div className="row g-3 align-items-center mt-2">
                            <div className="col-md-2">
                                <label htmlFor="mob" className="col-form-label">
                                    <strong>
                                        Mob#{' '}
                                        <span className="text-danger"> *</span>
                                    </strong>
                                </label>
                            </div>
                            <div className="col-md-10">
                                <PhoneInput
                                    country={'in'}
                                    value={this.state.form.mob}
                                    onChange={this.onChangePhone}
                                    inputClass=" custom-margin-form-control"
                                />

                                {/* <input
                                    type='tel'
                                    className='form-control custom-margin-form-control'
                                    id='mob'
                                    name="phone"
                                    onChange={this.onChangeInput}
                                /> */}
                                {MobError}
                            </div>
                        </div>

                        <div className="row g-3 align-items-center mt-2">
                            <div className="col-md-2">
                                <label
                                    htmlFor="country"
                                    className="col-form-label"
                                >
                                    <strong>Country</strong>
                                </label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type="country"
                                    className="form-control custom-margin-form-control"
                                    id="country"
                                    name="country"
                                    onChange={this.onChangeInput}
                                    value={this.state.form.country}
                                />
                            </div>
                        </div>
                        <div className="row g-3 align-items-center mt-2">
                            <strong className="text-danger">Fields marked with (*) are mandatory</strong>
                        </div>

                        <div className="row mt-3">
                            <Loader loading={this.state.loading} />
                        </div>
                        {GeneralError}
                        <div className="p-1 text-center">
                            <div className="mr-2">
                                {/* <button
                                    type='submit'
                                    className='btn btn-primary mr-3 float-start'
                                    disabled={this.state.loading}
                                >Back</button> */}
                                {/* <Link to='/login' className='btn btn-primary mr-3 inch-left'>
                  Back
                </Link> */}
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={this.state.loading}
                                    onClick={this.onClickHandler}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { score } = state;
    return score;
};

const ReduxRegistration = connect(mapStateToProps, { getInitialScore })(
    Registration
);

export default (props) => {
    return <ReduxRegistration />;
};
