import React from "react";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { validateEmail } from "../utils";
import { Link, useNavigate, NavLink } from "react-router-dom";
import image from "../assets/images/Silfra_Logo.png";
import axios from "axios";
import { updateValues } from "../redux/reducers/score";
import { connect } from "react-redux";
import isEmail from "validator/es/lib/isEmail";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      otp: "",
      error: true,
      otpSent: false,
      otpVerified: false,
      errors: {},
      timer: 0,
    };
  }
  onChangeInput = (e) => {
    // validate the email.
    if (e.target.name === "email") {
      // validate the email.
      let val = e.target.value;
      if (val === "" || !validateEmail(val) || !isEmail(val)) {
        this.setState({
          errors: {
            email: "Please enter a valid email address.",
          },
          error: true,
        });
        return;
      }
    }

    this.setState({
      errors: {},
      error: false,
      [e.target.name]: e.target.value,
    });
  };

  onSubmitOTP = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("clicked");
    this.setState({
      loading: true,
      otpSent: false,
    });
    const url = process.env.REACT_APP_SERVER + "/customer/enter-email";
    const apiData = { email: this.state.email };
    // if()
    await axios
      .post(url, apiData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.setState({
            loading: false,
            otpSent: true,
            error: false,
            errors: {},
          });
        } else {
          this.setState({
            loading: false,
            errors: {
              email: res.data.message,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  getScore = async () => {
    const url = process.env.REACT_APP_SERVER + "/customer/get-nav-data";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .get(url, { headers })
      .then((res) => {
        console.log("line 95=", res);
        if (res.status === 200) {
          console.log("line 98=", res.data.current_section);
          this.props.updateValues(res.data);
          console.log("this.props=", this.props);

          if (res.data.current_section === "BasicDetails") {
            console.log("line 100 This is basic detils");
            this.props.navigate("/dmat/register");
          } else if (res.data.current_section === "Introduction") {
            this.props.navigate("/dmat/introduction");
          } else if (res.data.current_section === "SurveyComplete") {
            this.props.navigate("/dmat/report");
          } else if (
            res.data.current_section === "Section 2 - Company Profile"
          ) {
            this.props.navigate("/dmat/CustomerDetailsQuestions");
          } else if (
            res.data.current_section ===
            "Section 3 - Organization Digital Maturity"
          ) {
            this.props.navigate("/dmat/assessment");
          } else if (
            res.data.current_section === "Section 4 - Functional Maturity "
          ) {
            this.props.navigate("/dmat/functionalAssessment");
          } else {
            this.props.navigate("/dmat/register");
          }
          console.log(res.data.currentSection);
          console.log(this.props.currentSection);
        } else {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("clicked");
    this.setState({
      loading: true,
    });
    const url = process.env.REACT_APP_SERVER + "/customer/verify-otp";
    const apiData = { email: this.state.email, otp: this.state.otp };
    await axios
      .post(url, apiData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.setState({
            loading: false,
            otpSent: true,
            error: false,
            errors: {},
          });
          console.log("line 150=", res.data);
          console.log(
            `access token= ${res.data.access_token} || refresh 
                         token= ${res.data.refresh_token}`
          );
          sessionStorage.setItem("accessToken", res.data.access_token);
          sessionStorage.setItem("refreshToken", res.data.refresh_token);
          this.getScore();
        } else {
          this.setState({
            loading: false,
            errors: {
              otp: res.data.message,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
        this.setState({
          error: true,
          errors: {
            verify: "Unable to verify",
          },
        });
      });
  };
  render() {
    let EmailError = this.state.errors.email ? (
      <div className="text-danger">{this.state.errors.email}</div>
    ) : null;
    let OtpError = this.state.errors.otp ? (
      <div className="text-danger">{this.state.errors.otp}</div>
    ) : null;
    // let otpButtonText = 'Sent OTP'
    // if (this.state.timer > 0)
    //     otpButtonText = `${this.state.timer}`
    // else
    //     otpButtonText = 'Resend OTP'
    return (
      <div>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="container  navbar-collapse d-flex"
                id="navbarTogglerDemo01"
              >
                <NavLink
                  className="navbar-brand"
                  to="#!"
                  activeClassName="active"
                >
                  <img height={60} src={image} alt="C4i4 Logo" />
                </NavLink>
                {"           "}
                <div style={{ flex: 1 }}>
                  <h1 style={{ color: "royalblue" }} className="text-center">
                    Digital Maturity Assesment Survey
                  </h1>
                </div>
              </div>
              <Link to="/dmat/admin">
                <button className="btn btn-primary">Admin</button>
              </Link>
            </div>
          </nav>
          <br />
        </div>

        <div className="form-main">
          <form className="form mt-3">
            <div className="row content">
              <h3 style={{ paddingBottom: "5%" }}>Login</h3>
              <div className="col-md-12">
                <div className="form-group">
                  <label style={{ fontSize: "20px" }} htmlFor="email">
                    Email
                  </label>
                  <input
                    style={{ fontSize: "20px" }}
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={this.onChangeInput}
                  />
                  {EmailError}
                </div>
              </div>
              <div className="mt-1 col-md-12">
                <div className="col-md-12 d-flex justify-content-center">
                  <div className="form-group" style={{ paddingTop: "10px" }}>
                    <button
                      style={{ fontSize: "20px" }}
                      type="submit"
                      className="btn btn-primary"
                      onClick={this.onSubmitOTP}
                      disabled={
                        this.state.loading ||
                        this.state.email === "" ||
                        this.state.error
                      }
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="row mb-2 mt-2 d-flex justify-content-center"
                style={{
                  display: this.state.loading ? "none" : "unset",
                }}
              >
                <Loader loading={this.state.loading} />
              </div>
              <div
                className="my-3 text-center"
                style={{
                  display:
                    this.state.loading || !this.state.otpSent
                      ? "none"
                      : "unset",
                }}
              >
                {this.state.otpSent &&
                  "OTP has been sent to your email. Please enter the OTP and Click on Login"}
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="otp" style={{ fontSize: "20px" }}>
                    OTP
                  </label>
                  <input
                    style={{ fontSize: "20px" }}
                    type="number"
                    className="form-control"
                    id="otp"
                    name="otp"
                    onChange={this.onChangeInput}
                    disabled={!this.state.otpSent}
                  />
                  {OtpError}
                </div>
              </div>
              <div
                className="text-center mt-3 col-md-12"
                onClick={this.handleSubmit}
              >
                <div className="btn btn-primary" style={{ fontSize: "20px" }}>
                  Login
                </div>
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { score } = state;
  return score;
};

const ReduxLogin = connect(mapStateToProps, { updateValues })(Login);

export default (props) => {
  const navigate = useNavigate();
  return <ReduxLogin navigate={navigate} />;
};
