import styles from "../styles";
import { View, Text, Svg, Path, Circle, Line, G } from "@react-pdf/renderer";
import Gauge from "react-svg-gauge";
import {
  PieChart,
  Pie,
  Sector,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Header from "./Header";
import Footer from "./Footer";

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  console.log(angleInDegrees);
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  console.log(angleInRadians);
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  console.log(x, y, radius, startAngle, endAngle);

  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");
  console.log(d);
  return d;
}

export const Page8 = ({ odmScore, odmMaxScore, fmScore, fmMaxScore }) => {
  let dmScore = parseInt(((100 * odmScore) / odmMaxScore).toFixed(2));
  console.log(dmScore);
  console.log(-90 + dmScore * 1.8);
  let fmS = parseInt(((fmScore * 100) / fmMaxScore).toFixed(2));
  console.log(fmS);
  console.log(-90 + fmS * 1.8);
  let deno = odmMaxScore + fmMaxScore;
  let tScore = parseInt(((odmScore + fmScore) * 100) / deno);
  let industryMeanScore = 37;

  console.log(tScore);
  console.log((tScore * 300) / 100);
  console.log(-90 + tScore * 1.8);

  let d_dms = describeArc(150, 150, 100, -90, -90 + dmScore * 1.8);
  let d_dms_gray = describeArc(150, 150, 100, -90 + dmScore * 1.8, 90);
  let d_fms = describeArc(150, 150, 100, -90, -90 + fmS * 1.8);
  let d_fms_gray = describeArc(150, 150, 100, -90 + fmS * 1.8, 90);
  let d_total =
    "M 100 200 L " +
    (100 + (tScore * 300) / 100) +
    " 200 L " +
    (100 + (tScore * 300) / 100) +
    " 170 L 100 170 Z";
  let d_total_industry_mean =
    "M 100 110 L " +
    (100 + (industryMeanScore * 300) / 100) +
    " 110 L " +
    (100 + (industryMeanScore * 300) / 100) +
    " 80 L 100 80 Z";

  console.log(d_dms);
  console.log(d_dms_gray);
  console.log(d_fms);
  console.log(d_fms_gray);
  console.log(d_total);
  console.log(d_total_industry_mean);

  const Hordata = [
    {
      name: "Industry Median",
      "Score(%)": 35,
    },
    {
      name: "Your Org Score",
      "Score(%)": tScore,
    },
  ];

  return (
    <View style={styles.section_right}>
      <Header />
      <Text style={styles.headingorg}>Performance of Your Organization</Text>
      <Text style={{ fontSize: "14", marginTop: "15", color: "#5497b8", paddingLeft: "50" }}>
        Benchmark of Overall Maturity Score with Industry{" "}
      </Text>
      <View
        style={{ paddingLeft: "30", paddingTop: "40", paddingBottom: "40" }}
      >
        <Svg height='300' width='100%'>
          <G>
            {/* <Line
              x1='100'
              y1='250'
              x2='400'
              y2='250'
              stroke='black'
              strokeDasharray='4'
            />
            <Line
              x1='100'
              y1='200'
              x2='400'
              y2='200'
              stroke='lightgray'
              strokeDasharray='4'
            />
            <Line
              x1='100'
              y1='150'
              x2='400'
              y2='150'
              stroke='lightgray'
              strokeDasharray='4'
            />
            <Line
              x1='100'
              y1='100'
              x2='400'
              y2='100'
              stroke='lightgray'
              strokeDasharray='4'
            />
            <Line
              x1='100'
              y1='50'
              x2='400'
              y2='50'
              stroke='lightgray'
              strokeDasharray='4'
            />
            <Line
              x1='100'
              y1='50'
              x2='100'
              y2='250'
              stroke='black'
              strokeDasharray='4'
            />
            <Line
              x1='150'
              y1='50'
              x2='150'
              y2='250'
              stroke='lightgray'
              strokeDasharray='4'
            />
            <Line
              x1='200'
              y1='50'
              x2='200'
              y2='250'
              stroke='lightgray'
              strokeDasharray='4'
            />
            <Line
              x1='250'
              y1='50'
              x2='250'
              y2='250'
              stroke='lightgray'
              strokeDasharray='4'
            />
            <Line
              x1='300'
              y1='50'
              x2='300'
              y2='250'
              stroke='lightgray'
              strokeDasharray='4'
            />
            <Line
              x1='350'
              y1='50'
              x2='350'
              y2='250'
              stroke='lightgray'
              strokeDasharray='4'
            />
            <Line
              x1='400'
              y1='50'
              x2='400'
              y2='250'
              stroke='lightgray'
              strokeDasharray='4'
            /> */}
            <Path
              fill='#157DEC'
              stroke='#157DEC'
              d={d_total_industry_mean}
            />
            <Path
              fill='#157DEC'
              stroke='#157DEC'
              d={d_total}
            />
            {/* <Text x='95' y='270'>
              0
            </Text>
            <Text x='143' y='270'>
              50
            </Text>
            <Text x='190' y='270'>
              100
            </Text>
            <Text x='240' y='270'>
              150
            </Text>
            <Text x='290' y='270'>
              200
            </Text>
            <Text x='340' y='270'>
              250
            </Text>
            <Text x='390' y='270'>
              300
            </Text> */}
            <Text x='20' y='90' style={{ fontSize: "12" }}>
              Industry
            </Text>
            <Text x='25' y='110' style={{ fontSize: "12" }}>
              Median
            </Text>
            <Text x='20' y='180' style={{ fontSize: "12" }}>
              Your Org
            </Text>
            <Text x='25' y='200' style={{ fontSize: "12" }}>
              Score
            </Text>
            <Text x={110 + (tScore * 300) / 100} y='195'>
              {tScore}
            </Text>
            <Text x={140 + (tScore * 300) / 100} y='195'>
              %
            </Text>
            <Text x={110 + (industryMeanScore * 300) / 100} y='105'>
              {industryMeanScore}
            </Text>
            <Text x={130 + (industryMeanScore * 300) / 100} y='105'>
              %
            </Text>
          </G>
        </Svg>
      </View>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={{ flexDirection: "column", width: "50%" }}>
          <View style={{ flexDirection: "row", textAlign: "center" }}>
            <Svg height='175' width='100%'>
              <G>
                <Path
                  fill='none'
                  stroke='lightgray'
                  strokeWidth='30'
                  d={d_fms_gray}
                />
                <Path fill='none' stroke='#157DEC' strokeWidth='30' d={d_fms} />
                <Text x='45' y='175' fill='lightgray'>
                  0
                </Text>
                <Text x='230' y='175' fill='lightgray'>
                  100%
                </Text>
                <Text x='140' y='145'>
                  {fmS}
                </Text>

              </G>
            </Svg>
          </View>
          <Text style={{ fontSize: "14", textAlign: "center", color: "#5497b8" }}>
            Functional Maturity
          </Text>
        </View>
        <View style={{ flexDirection: "column", width: "50%" }}>
          <View style={{ flexDirection: "row", textAlign: "center" }}>
            <Svg height='175' width='100%'>
              <G>
                <Path fill='none' stroke='#157DEC' strokeWidth='30' d={d_dms} />
                <Path
                  fill='none'
                  stroke='lightgray'
                  strokeWidth='30'
                  d={d_dms_gray}
                />
                <Text x='45' y='175' fill='lightgray'>
                  0
                </Text>
                <Text x='230' y='175' fill='lightgray'>
                  100%
                </Text>
                <Text x='140' y='145'>
                  {dmScore}
                </Text>
              </G>
            </Svg>
          </View>
          <Text style={{ fontSize: "14", textAlign: "center", color: "#5497b8" }}>
            Organizational Maturity
          </Text>
        </View>
      </View>

      <Footer pgnum='8' />
    </View>
  );
};
