import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCustomerDetails,
  getCustomerOverview,
  searchCustomers,
} from "../functions/customers";

const initialState = {
  customers: [],
  filteredCustomers: [],
  customerCount: {
    complete: 1,
    inComplete: 1,
    notStarted: 1,
    total: 3,
  },
  loading: false,
  error: false,
  errors: {},
};

export const customersReducer = createSlice({
  name: "customers",
  initialState,
  reducers: {
    filterCustomers: (state, action) => {
      console.log(action);
      if (action.payload.location === "complete") {
        return {
          ...state,
          filteredCustomers: state.customers.filter(
            (x) => x.survey_status === "Complete"
          ),
        };
      } else if (action.payload.location === "inComplete") {
        return {
          ...state,
          filteredCustomers: state.customers.filter(
            (x) => x.survey_status === "Pending"
          ),
        };
      } else if (action.payload.location === "notStarted") {
        return {
          ...state,
          filteredCustomers: state.customers.filter(
            (x) => x.survey_status === 'Not Started'
          ),
        };
      } else {
        return {
          ...state,
          filteredCustomers: state.customers,
        };
      }
    },
    resetFilter: (state) => {
      return {
        ...state,
        filteredCustomers: null,
      };
    },
  },
  extraReducers: {
    [getAllCustomerDetails.pending]: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        errors: {},
      };
    },
    [getAllCustomerDetails.rejected]: (state, action) => {
      console.log(action);
      return {
        ...state,
        loading: false,
        error: true,
        error: {
          getCustomerDetails: action.payload.response.msg,
        },
      };
    },
    [getAllCustomerDetails.fulfilled]: (state, action) => {
      console.log(action.payload);
      const { customer_details } = action.payload;
      return {
        ...state,
        loading: false,
        customers: customer_details,
      };
    },
    [getCustomerOverview.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    [getCustomerOverview.rejected]: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        error: true,
      };
    },
    [getCustomerOverview.fulfilled]: (state, action) => {
      console.log(action.payload);
      const { complete, not_started, pending, total } = action.payload;
      return {
        ...state,
        loading: false,
        customerCount: {
          complete: parseInt(complete),
          inComplete: parseInt(pending),
          notStarted: parseInt(not_started),
          total: parseInt(total),
        },
      };
    },
    [searchCustomers.pending]: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    [searchCustomers.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: true,
        filteredCustomers: action.payload,
      };
    },
    [searchCustomers.rejected]: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        error: true,
      };
    },
  },
});

export default customersReducer.reducer;
export const { filterCustomers, resetFilter } = customersReducer.actions;
