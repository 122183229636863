import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllQuestions = createAsyncThunk(
  "surveyQuestions/getAllQuestions",
  async (dummyData, { rejectWithValue, dispatch }) => {
    const url = process.env.REACT_APP_SERVER + "/user/get-all-questions";
    const token = sessionStorage.getItem("accessToken");
    console.log(token);
    const headers = { Authorization: `Bearer ${token}` };
    const data = await axios
      .get(url, { headers })
      .then(async (res) => {
        if (res.status === 251) {
          dispatch(refreshToken());
        } else if (res.status === 200) {
          return res.data;
        } else {
          return rejectWithValue(res.data);
        }
      })
      .catch((err) => rejectWithValue(err));
    return data;
  }
);

const refreshToken = createAsyncThunk(
  "surveyQuestions/refreshToken",
  async (dummyData, { rejectWithValue, dispatch }) => {
    const url = process.env.REACT_APP_SERVER + "/user/generate-access-token";
    const token = sessionStorage.getItem("refreshToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .get(url, { headers })
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("accessToken", res.data.accessToken);
          dispatch(getAllQuestions());
        } else {
          return rejectWithValue(res.data);
        }
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

const refreshToken2 = async (func) => {
  const url = process.env.REACT_APP_SERVER + "/user/generate-access-token";
  const token = sessionStorage.getItem("refreshToken");
  const headers = { Authorization: `Bearer ${token}` };
  await axios
    .get(url, { headers })
    .then((res) => {
      if (res.status === 200) {
        sessionStorage.setItem("accessToken", res.data.accessToken);
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
};
