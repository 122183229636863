import React from "react";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateSection } from "../redux/reducers/score";
import image from "../assets/images/Silfra_Logo.png";

class Navbar extends React.Component {
  navClick = (e) => {
    //         console.log('line 10', this.props);
    this.props.updateSection("BasicDetails");
  };

  onLogout = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    this.props.navigate("/dmat");
    toast.success("You have been logged out");
  };

  onSectionChange = (sectionName) => {
    this.props.updateSection(sectionName);

    console.log("line no 23 nav bar");
  };

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="container collapse navbar-collapse"
              style={{ height: "90%" }}
              id="navbarTogglerDemo01"
            >
              <NavLink
                className="navbar-brand"
                to="#!"
                activeClassName="active"
              >
                <img
                  style={{ objectFit: "cover" }}
                  src={image}
                  width={60}
                  alt="C4i4 Logo"
                />
              </NavLink>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link nav-link-color left-nav"
                    to="/dmat/introduction"
                    activeClassName="active"
                    onClick={this.navClick}
                  >
                    Introduction
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link nav-link-color"
                    to="/damt/register"
                    activeClassName="active"
                    onClick={this.navClick}
                  >
                    Basic Details
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link nav-link-color"
                    to="/dmat/CustomerDetailsQuestions"
                    activeClassName="active"
                    onClick={this.navClick}
                  >
                    Company Details
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link nav-link-color"
                    to="/dmat/assessment"
                    activeClassName="active"
                    onClick={this.navClick}
                  >
                    Overall Digital Maturity
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link nav-link-color"
                    to="/dmat/functionalAssessment"
                    activeClassName="active"
                    onClick={this.navClick}
                  >
                    Functional Maturity
                  </NavLink>
                </li>
                <li>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={this.onLogout}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <br />
        {/* <div className="mt-2 mb-2 w-80 mx-auto" style={{width: "80%"}}>
          <hr />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { score } = state;
  return score;
};

const ReduxNavbar = connect(mapStateToProps)(Navbar);

export default (props) => {
  const navigate = useNavigate();
  return <ReduxNavbar navigate={navigate} />;
};
