import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllCustomerDetails = createAsyncThunk(
  "customers/getAllCustomerDetails",
  async (dummyData, { rejectWithValue, getState, dispatch }) => {
    const url = process.env.REACT_APP_SERVER + "/user/all-customer-details";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    const data = await axios
      .get(url, { headers })
      .then((res) => {
        if (res.status === 251) {
          dispatch(refreshToken(getAllCustomerDetails));
          return rejectWithValue(null);
        } else if (res.status === 200) {
          return res.data;
        } else {
          return rejectWithValue(res.data);
        }
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
    return data;
  }
);

export const getCustomerOverview = createAsyncThunk(
  "customers/getCustomerOverview",
  async (dummyData, { rejectWithValue, getState, dispatch }) => {
    const url = process.env.REACT_APP_SERVER + "/user/customers-overview";
    const token = sessionStorage.getItem("accessToken");

    const token1 = sessionStorage.getItem("refreshToken");
    const headers = { Authorization: `Bearer ${token}` };
    console.log(token1);
    const data = await axios
      .get(url, { headers })
      .then((res) => {
        if (res.status === 251) {
          dispatch(refreshToken(getCustomerOverview));
          return rejectWithValue(null);
        } else if (res.status === 200) {
          return {
            ...res.data.overview,
          };
        } else {
          return rejectWithValue(res.data);
        }
      })
      .catch((err) => rejectWithValue(err));
    return data;
  }
);

export const searchCustomers = createAsyncThunk(
  "customers/searchCustomers",
  async ({ search }, { rejectWithValue, getState, dispatch }) => {
    const url = process.env.REACT_APP_SERVER + "/user/search-customers";
    const token = sessionStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    const apiData = {
      search_string: search,
    };
    const data = await axios
      .post(url, apiData, { headers })
      .then((res) => {
        if (res.status === 251) {
          dispatch(refreshToken(searchCustomers));
          return rejectWithValue(null);
        } else if (res.status === 200) {
          return res.data.customer_details;
        } else {
          return rejectWithValue(res.data);
        }
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
    return data;
  }
);

export const refreshToken = createAsyncThunk(
  "customers/refreshToken",
  async (func, { rejectWithValue, dispatch }) => {
    const url = process.env.REACT_APP_SERVER + "/user/generate-access-token";
    const token = sessionStorage.getItem("accessToken");
    console.log(token);
    const headers = { Authorization: `Bearer ${token}` };
    await axios
      .get(url, { headers })
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("accessToken", res.data.accessToken);
          dispatch(func());
        } else {
          return rejectWithValue(res.data);
        }
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
