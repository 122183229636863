import styles from '../styles'
import { View, Text } from '@react-pdf/renderer'
import Header from './Header'
import Footer from './Footer'

export const Page7 = ({ about }) => {
    return (
        <View style={styles.section_right}>
            <Header />
            <View>
                <View style={{ marginTop: '10', marginBottom: '10', paddingRight: '40', paddingLeft: '45', flexDirection: "row" }}>
                    <Text style={styles.headingacess}>How to read this Assessment Scores?  </Text>
                </View>
                <View style={{ marginTop: '10', marginBottom: '10', paddingRight: '40', paddingLeft: '45', flexDirection: "row" }}>
                    <Text style={{ fontSize: '12', color: "gray" }}>The assessment is broken into 3 sections:</Text>
                </View>
                <View style={{ marginTop: '10', marginBottom: '10', paddingRight: '40', paddingLeft: '80', flexDirection: "row" }}>
                    <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}><Text style={{ color: 'black' }}>1.  Overall Maturity:</Text>   The Digital Maturity section assesses the overarching maturity of the organization based on the 4 components listed in the sections above. This score is the aggregate of Functional Maturity and Organizational Maturity. The Maturity Chart provides a guidance of interpreting your Overall Maturity Score on the four components of digital maturity. </Text>
                </View>
                <View style={{ marginTop: '10', marginBottom: '10', paddingRight: '40', paddingLeft: '100', flexDirection: "row" }}>
                    <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}><Text style={{ color: 'black' }}>a. Functional Maturity:</Text>  The functional maturity section assesses the functional maturity of the core processes including order management, vendor management and supply chain, production planning, core production and asset maintenance.</Text>
                </View>
                <View style={{ marginTop: '10', marginBottom: '10', paddingRight: '40', paddingLeft: '100', flexDirection: "row" }}>
                    <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}> <Text style={{ color: 'black' }}>b. Organizational Maturity:</Text> The organizational maturity assesses the people and process maturity for digital transformation.</Text>
                </View>
                <View style={{ marginTop: '10', marginBottom: '10', paddingRight: '40', paddingLeft: '80', flexDirection: "row" }}>
                    <Text style={{ lineHeight: 1.3, color: "grey", fontSize: '12' }}>We also provide a comparison of your Overall Maturity Score and the median score of your industry peers based on the sample of 20+ manufacturing companies assessed by C4i4.</Text>
                </View>
            </View>

            <Footer pgnum='7' />
        </View>
    )
}