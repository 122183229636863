import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducer: {},
});

export default adminSlice.reducer;
