import styles from '../styles'
import { View, Text, Svg, Line } from '@react-pdf/renderer'
import Gauge from "react-svg-gauge";
import { PieChart, Pie, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Header from './Header'
import Footer from './Footer'

export const Page10 = ({ about }) => {



  return (
    <View style={styles.section_right}>
      <Header />
      <Text style={{ marginLeft: "40px", marginBottom: "40px" }}>Maturity Chart</Text>
      <View style={styles.tableContainer}>
        {/*<TableHeader />*/}
        <View style={styles.row} key="0">
          <Text style={styles.description}></Text>
          <Text style={styles.description}>Beginner</Text>
          <Text style={styles.description}>Explorer</Text>
          <Text style={styles.description}>Investor</Text>
          <Text style={styles.description}>Accelerator</Text>
          <Text style={styles.description}>Visionary</Text>
        </View>
        <Svg height="2" width="900">
          <Line
            x1="0"
            y1="0"
            x2="900"
            y2="0"
            strokeWidth={2}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.altrow} key="1">
          <Text style={styles.tablealtcellhead}>Your Score</Text>

          <Text style={styles.tablealtcellhead}>Up to 20%</Text>
          <Text style={styles.tablealtcellhead}>Up to 40%</Text>
          <Text style={styles.tablealtcellhead}>Up to 60%</Text>
          <Text style={styles.tablealtcellhead}>Up to 80%</Text>
          <Text style={styles.tablealtcellhead}> More than 80%</Text>

        </View>
        <Svg height="2" width="900">
          <Line
            x1="0"
            y1="0"
            x2="900"
            y2="0"
            strokeWidth={2}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.row} key="2">
          <Text style={styles.tablecell}>Organization & Skills</Text>
          <Text style={styles.tablecell}>-Focus on basic functional skills {"\n"}- Digital skills not evaluated though some skills may reside in siloes</Text>
          <Text style={styles.tablecell}>-Digital is viewed as a technology play and is driven via CTO/IT teams{"\n"} -No training or HR interventions</Text>
          <Text style={styles.tablecell}>-Digital is viewed as a business led transformation.{"\n"} -Data literacy initiated centrally by HR/Learning departments programs</Text>
          <Text style={styles.tablecell}>-Organization recognizes the importance of building digital capabilities at functional levels.{"\n"} -Role based training and hiring initiated specifically for digital projects</Text>
          <Text style={styles.tablecell}>-Organization is built around digital components and offerings rather than traditional business units or functions</Text>
        </View>
        <Svg height="2" width="900">
          <Line
            x1="0"
            y1="0"
            x2="900"
            y2="0"
            strokeWidth={2}
            stroke="rgb(0,0,0)"
          />
        </Svg>

        <View style={styles.altrow} key="3">

          <Text style={styles.tablecell}>Process & Technology</Text>

          <Text style={styles.tablealtcell}>Processes are ad-hoc and inconsistent {"\n"}-Processes are manual and there is little /no technology interventions</Text>
          <Text style={styles.tablealtcell}>- Technology is used for automating repetitive tasks and improve basic efficiencies but end-to-end visibility is lacking {"\n"}- Core processes are digitized but there is no consistency (high variance)</Text>
          <Text style={styles.tablealtcell}>-Technology used to provide real-time visibility into core processes and enable end-to-end transaction visibility within organization {"\n"}- Processes are measurable and consistent</Text>
          <Text style={styles.tablealtcell}>-Technology used to provide real-time visibility into processes and enable end-to-end across the supply chain{"\n"} - Processes are continuously being improved</Text>
          <Text style={styles.tablealtcell}>- Technology is used to provide components and services which can be directly integrated into external partner ecosystem for improved collaboration and co-creation</Text>

        </View>
        <Svg height="2" width="900">
          <Line
            x1="0"
            y1="0"
            x2="900"
            y2="0"
            strokeWidth={2}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <Text style={{ paddingLeft: '38', fontSize: '8', color: 'gray' }} >Fig 2 : Overall Maturity Chart,© Edify.AI India Pvt Ltd 2022</Text>
        {/*<TableFooter items={data.items} />*/}
      </View>
      <Footer pgnum='10' />
    </View>
  )
}