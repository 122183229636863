import React, { useState, useEffect } from 'react';
import {
    Document,
    Page,
    PDFViewer,
    PDFDownloadLink,
    renderToStream,
    pdf,
    renderToFile,
    usePDF,
    View,
    Text,
} from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import LeftSection from '../components/PDF/LeftSection';

import { Page1 } from '../components/PDF/Page1';
import { RightSection } from '../components/PDF/RightSection';
import { Page3 } from '../components/PDF/Page3';
import { Page4 } from '../components/PDF/Page4';
import { Page5 } from '../components/PDF/Page5';
import { Page6 } from '../components/PDF/Page6';
import { Page7 } from '../components/PDF/Page7';
import { Page8 } from '../components/PDF/Page8';
import { Page9 } from '../components/PDF/Page9';
import { Page10 } from '../components/PDF/Page10';
import { Page11 } from '../components/PDF/Page11';
import styles from '../components/styles';
import axios from 'axios';

const Preview = () => {
    const [odmScore, setOdmScore] = useState(1);
    const [odmMaxScore, setOdmMaxScore] = useState(1);
    const [fmScore, setFmScore] = useState(1);
    const [fmMaxScore, setFmMaxScore] = useState(1);
    const [instance, update] = usePDF({
        document: (
            <Template
                odmMaxScore={odmMaxScore}
                odmScore={odmScore}
                fmScore={fmScore}
                fmMaxScore={fmMaxScore}
            />
        ),
    });
    const [sentOnce, setSentOnce] = useState(false);
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        update();
        setSentOnce(false);
    }, [odmScore, odmMaxScore, fmMaxScore, fmScore]);

    const refreshToken = async (func) => {
        const url =
            process.env.REACT_APP_SERVER + '/customer/generate-access-token';
        const token = sessionStorage.getItem('refreshToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios.get(url, { headers }).then((res) => {
            if (res.status === 200) {
                sessionStorage.setItem('accessToken', res.data.accessToken);
            }
            func();
        });
    };

    const sendPDf = async () => {
        // const pdfStream = await renderToStream(
        //   <Template
        //     odmMaxScore={odmMaxScore}
        //     odmScore={odmScore}
        //     fmScore={fmScore}
        //     fmMaxScore={fmMaxScore}
        //   />
        // );
    };

    const getScore = async () => {
        const url = process.env.REACT_APP_SERVER + '/customer/get-survey-score';
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios.get(url, { headers }).then((res) => {
            if (res.status === 251) {
                refreshToken(getScore);
            } else if (res.status === 200) {
                const { score } = res.data;
                setOdmMaxScore(
                    score['Section 3 - Organization Digital Maturity'].total
                );
                setOdmScore(
                    score['Section 3 - Organization Digital Maturity'].score
                );
                setFmMaxScore(score['Section 4 - Functional Maturity'].total);
                setFmScore(score['Section 4 - Functional Maturity'].score);
                console.log(odmMaxScore);
                update();
                setSentOnce(false);
                setUpdated(true);
            }
        });
    };

    const submitReport = async () => {
        const url =
            process.env.REACT_APP_SERVER + '/customer/upload-survey-report';
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        const arrayData = await instance.blob.arrayBuffer();
        var uint8View = new Uint8Array(arrayData);
        console.log(typeof uint8View);
        console.log(uint8View);
        const apiData = {
            // blob_input: [...uint8View],
            report_input: [...uint8View],
        };
      //   const apiData1 = new FormData();
      //   apiData1.append('blob_input', await instance.blob.arrayBuffer());
      //   console.log(instance.blob.text());
        await axios
            .post(url, apiData, { headers })
            .then((res) => {
                console.log('upload servay report', res);
                if (res.status === 251) {
                    refreshToken(submitReport);
                } else if (res.status === 200) {
                    console.log(res);
                    setSentOnce(true);
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSendEmail = async () => {
        if (updated && instance.loading === false) {
            const url = process.env.REACT_APP_SERVER + '/customer/mail-report';
            const token = sessionStorage.getItem('accessToken');
            const headers = { Authorization: `Bearer ${token}` };
            await axios
                .get(url, { headers })
                .then((res) => {
                    console.log('res', res);
                    if (res.status === 251) {
                        refreshToken(handleSendEmail);
                    } else if (res.status === 200) {
                        console.log(res);
                        toast.success('The mail has been sent');
                    } else {
                        console.log(res);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            toast.error('Your report is being generated');
        }
    };

    useEffect(() => {
        getScore();
    }, []);

    useEffect(() => {
        console.log(update);
        console.log(instance.blob);
        if (sentOnce === false || instance.loading === false) {
            submitReport();
        }
    }, [instance]);

    return (
        <div
            style={{
                width: '100%',
                height: '95vh',
                display: 'flex',
            }}
        >
            <div style={{ flexGrow: 1 }}>
                <PDFViewer
                    showToolbar={false}
                    style={{
                        width: '100%',
                        height: '95%',
                    }}
                >
                    <Template
                        odmMaxScore={odmMaxScore}
                        odmScore={odmScore}
                        fmScore={fmScore}
                        fmMaxScore={fmMaxScore}
                    />
                </PDFViewer>
                <div style={{ align: 'center' }}>
                    <PDFDownloadLink
                        document={
                            <Template
                                odmMaxScore={odmMaxScore}
                                odmScore={odmScore}
                                fmScore={fmScore}
                                fmMaxScore={fmMaxScore}
                            />
                        }
                        fileName="SurveyReport.pdf"
                    >
                        {({ loading }) =>
                            loading ? 'Loading document...' : 'Download now!'
                        }
                    </PDFDownloadLink>
                    <button
                        className="bottomBtn"
                        style={{ paddingLeft: '10', backgroundColor: 'blue' }}
                        onClick={handleSendEmail}
                    >
                        Email Report
                    </button>
                </div>
            </div>
        </div>
    );
};
// Create Document Component
const Template = ({ odmScore, odmMaxScore, fmScore, fmMaxScore }) => {
    return (
        <Document>
            <Page object-fit="fill" size="A4" style={styles.page}>
                <Page1 />
            </Page>
            <Page size="A4" style={styles.page}>
                <RightSection />
            </Page>
            <Page size="A4" style={styles.page}>
                <Page3 />
            </Page>
            <Page size="A4" style={styles.page}>
                <Page4 />
            </Page>
            <Page size="A4" style={styles.page}>
                <Page5 />
            </Page>
            <Page size="A4" style={styles.page}>
                <Page6 />
            </Page>
            <Page size="A4" style={styles.page}>
                <Page7 />
            </Page>
            <Page size="A4" style={styles.page}>
                <Page8
                    odmMaxScore={odmMaxScore}
                    odmScore={odmScore}
                    fmScore={fmScore}
                    fmMaxScore={fmMaxScore}
                />
            </Page>
            <Page size="A4" style={styles.page}>
                <Page9 />
            </Page>
            <Page size="A4" style={styles.page}>
                <Page10 />
            </Page>
            <Page size="A4" style={styles.page}>
                <Page11 />
            </Page>
        </Document>
    );
};

export default Preview;
