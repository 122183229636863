import styles from '../styles'
import { View, Text, Image } from '@react-pdf/renderer'
import topImage from '../../assets/images/topImg.png';



export const Page1 = ({ about }) => {
  return (
    <View style={styles.section_right}>
      <Image style={styles.image}src={topImage} alt=""  />
    </View>
  )
}