import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import Navbar from './Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { MdEdit, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

export class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            loading: true,
            user: {},
            show: false,
        };
    }

    async componentDidMount() {
        this.getUsers();
    }

    refreshToken = async (func) => {
        const url =
            process.env.REACT_APP_SERVER + '/user/generate-access-token';
        const token = sessionStorage.getItem('refreshToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios
            .get(url, { headers })
            .then((res) => {
                if (res.status === 200) {
                    sessionStorage.setItem('accessToken', res.data.accessToken);
                    func();
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getUsers = async () => {
        const url = process.env.REACT_APP_SERVER + '/admin/get-all-users';
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios
            .get(url, { headers })
            .then((res) => {
                if (res.status === 251) {
                    this.refreshToken(this.getUsers);
                } else if (res.status === 200) {
                    this.setState({
                        users: res.data.users,
                        loading: false,
                    });
                } else {
                    console.log(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    handleModalClose = () => {
        this.setState({
            show: false,
        });
    };

    onChangeInput = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleChangeUser = async () => {
        console.log('line82', this);
        const url = process.env.REACT_APP_SERVER + '/admin/edit-user';
        const apiData = {
            ...this.state.user,
            username_current: this.state.user.username,
            email_current: this.state.user.email,
            name_current: this.state.user.name,
            mob_no_current: this.state.user.mob_no,
        };
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios
            .patch(url, apiData, { headers })
            .then((res) => {
                if (res.status === 251) {
                    this.refreshToken(this.handleChangeUser);
                } else if (res.status === 200) {
                    this.getUsers();
                    this.setState({
                        show: false,
                    });
                } else {
                    console.log(res);
                }
            })
            .catch((err) => console.log(err));
    };

    editHandler = (index) => {
        this.setState({
            user: {
                ...this.state.users[index],
                username_current: this.state.users[index].username,
            },
        });
        this.setState({
            show: true,
        });
        console.log('line113', this.state.users);
    };
    

    deleteHandler = async (username) => {
        const url =
            process.env.REACT_APP_SERVER +
            '/admin/delete-user?username=' +
            username;
        const token = sessionStorage.getItem('accessToken');
        const headers = { Authorization: `Bearer ${token}` };
        await axios
            .delete(url, { headers })
            .then((res) => {
                if (res.status === 251) {
                    this.refreshToken(this.deleteHandler);
                } else if (res.status === 200) {
                    this.getUsers();
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    render() {
        if (this.state.loading === true) {
            return <Loader />;
        } else {
            return (
                <div>
                    <Navbar />
                    <main className="container">
                        <section>
                            <Modal
                                show={this.state.show}
                                onHide={this.handleModalClose}
                                centered
                                size="lg"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        Change User Details
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {/* <div className="row mb-3">
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="sectionName"
                                                className="col-form-label"
                                            >
                                                User Name
                                            </label>
                                        </div>
                                        <div className="col-md-9">
                                        <input
                                                type="text"
                                                className="form-control"
                                                id="username_current"
                                                value={
                                                    this.state.user &&
                                                    this.state.user.username
                                                }
                                                name="username"
                                                onChange={this.onChangeInput}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="row mb-2">
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="quesID"
                                                className="col-form-label"
                                            >
                                                Name
                                            </label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name_current"
                                                value={
                                                    this.state.user &&
                                                    this.state.user.name
                                                }
                                                name="name"
                                                onChange={this.onChangeInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="questionDescription"
                                                className="col-form-label"
                                            >
                                                Email
                                            </label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                value={
                                                    this.state.user &&
                                                    this.state.user.email
                                                }
                                                name="email"
                                                onChange={this.onChangeInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="questionInformation"
                                                className="col-form-label"
                                            >
                                                Mob No
                                            </label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="mob_no"
                                                value={
                                                    this.state.user &&
                                                    this.state.user.mob_no
                                                }
                                                name="mob_no"
                                                onChange={this.onChangeInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center my-3">
                                        <div className="col-md-3">
                                            <button
                                                className="btn btn-primary"
                                                onClick={this.handleChangeUser}
                                            >
                                                Change User Details
                                            </button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">SL.NO</th>
                                        <th scope="col">User Name</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Mob No</th>
                                        <th scope="col">Edit</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users &&
                                        this.state.users.map((user, index) => (
                                            <tr key={index}>
                                                <th>{index + 1}</th>
                                                <td>{user.username}</td>
                                                <td>{user.name}</td>
                                                <td>{user.email}</td>
                                                <td>{user.mob_no}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() =>
                                                            this.editHandler(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <MdEdit
                                                            color="black"
                                                            size={30}
                                                        />
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() =>
                                                            this.deleteHandler(
                                                                user.username
                                                            )
                                                        }
                                                    >
                                                        <MdDelete
                                                            color="black"
                                                            size={30}
                                                        />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </section>
                    </main>
                </div>
            );
        }
    }
}
export default (props) => {
    const navigate = useNavigate();
    return <UserManagement navigate={navigate} />;
};
