import React from "react";
import { connect } from "react-redux";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { updateSection } from "../../redux/reducers/score";
import image from "../../assets/images/Silfra_Logo.png";
import axios from "axios";

class Navbar extends React.Component {
  navClick = (e) => {
    e.preventDefault();
    this.props.navigate(`/dmat/admin/${e.target.name}`);
  };

  refreshToken = async (func) => {
    const url = process.env.REACT_APP_SERVER + "/user/generate-access-token";
    const token = sessionStorage.getItem("refreshToken");
    const headers = { Authorization: `Bearer ${token}` };
    await axios.get(url, { headers }).then((res) => {
      if (res.status === 200) {
        sessionStorage.removeItem("accessToken");
        sessionStorage.setItem("accessToken", res.data.accessToken);
      }
      func();
    });
  };

  onLogout = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    this.props.navigate("/dmat/admin");
    toast.success("You have been logged out");
  };

  // onLogout = async () => {
  //   const url = process.env.REACT_APP_SERVER + "/user/logout";
  //   const token = sessionStorage.getItem("accessToken");
  //   console.log(token);
  //   const headers = { Authorization: `Bearer ${token}` };
  //    axios
  //     .get(url, { headers })
  //     .then((res) => {
  //       if (res.status === 251) {
  //         this.refreshToken(this.onLogout);
  //       } else if (res.status === 200) {
  //         this.props.navigate("/admin/");
  //         toast.success("You have been logged out");
  //         sessionStorage.removeItem("accessToken");
  //         sessionStorage.removeItem("refreshToken");
  //       } else {
  //         console.log(res);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  onSectionChange = (sectionName) => {
    this.props.updateSection(sectionName);
  };

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="container collapse navbar-collapse"
              style={{ height: "90%" }}
              id="navbarTogglerDemo01"
            >
              <NavLink
                className="navbar-brand"
                to="/dmat/admin/"
                name="/"
                onClick={this.navClick}
                activeClassName="active"
              >
                <img
                  style={{ objectFit: "cover" }}
                  src={image}
                  width={60}
                  alt="C4i4 Logo"
                />
              </NavLink>
              <ul className="navbar-nav me-auto mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    to="/dmat/admin/userManagement"
                    className="nav-link nav-link-color"
                    name="userManagement"
                    activeClassName="active"
                    onClick={this.navClick}
                  >
                    User Management
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/dmat/admin/surveyQuestionManagement"
                    className="nav-link nav-link-color left-nav"
                    activeClassName="active"
                    onClick={this.navClick}
                    name="surveyQuestionManagement"
                  >
                    Survey Question Management
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/dmat/admin/customerDetails"
                    className="nav-link nav-link-color"
                    activeClassName="active"
                    name="customerDetails"
                    onClick={this.navClick}
                  >
                    Customer Details
                  </NavLink>
                </li>
              </ul>
              <span>
                <button
                  className="btn btn-primary mt-3"
                  onClick={this.onLogout}
                >
                  Logout
                </button>
              </span>
            </div>
          </div>
        </nav>
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { score } = state;
  return score;
};

const ReduxNavbar = connect(mapStateToProps)(Navbar);

export default (props) => {
  const navigate = useNavigate();
  return <ReduxNavbar navigate={navigate} />;
};
