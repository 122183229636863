import { configureStore } from "@reduxjs/toolkit";
import score from "./reducers/score";
import adminUsers from "./reducers/adminUsers";
import surveyQuestions from "./reducers/surveyQuestions";
import customers from "./reducers/customers";

export default configureStore({
  reducer: {
    score,
    adminUsers,
    surveyQuestions,
    customers,
  },
});
