import styles from '../styles'
import { View, Text, Svg, Line } from '@react-pdf/renderer'
import Header from './Header'
import Footer from './Footer'

export const RightSection = () => {
  return (
    <View style={styles.section_right}>
      <Header />
      <Text style={styles.contenthead}>Contents</Text>
      <View style={{ flexDirection: "row", left: 0, right: 0 }}>
        <Text style={styles.toc}>Glossary of Terms Used</Text>
        <Text style={styles.toc}>...............................................................................................................</Text>
        <Text style={styles.toc}>2</Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: 4, left: 0, right: 0, textAlign: 'center' }}>
        <Text style={styles.toc}>List of Figures:</Text>
        <Text style={styles.toc}>..............................................................................................................................</Text>
        <Text style={styles.toc}>2</Text>
      </View>
      <View style={{ flexDirection: "row", left: 0, right: 0 }}>
        <Text style={styles.toc}>Introduction</Text>
        <Text style={styles.toc}>...................................................................................................................................</Text>
        <Text style={styles.toc}>3</Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: 4, left: 0, right: 0, textAlign: 'center' }}>
        <Text style={styles.toc}>Digital Maturity in Indian Manufacturing Organizations</Text>
        <Text style={styles.toc}>.................................................................</Text>
        <Text style={styles.toc}>4</Text>
      </View>
      <View style={{ flexDirection: "row", left: 0, right: 0 }}>
        <Text style={styles.toc}>How to read this Assessment Scores?</Text>
        <Text style={styles.toc}>..........................................................................................</Text>
        <Text style={styles.toc}>6</Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: 4, left: 0, right: 0, textAlign: 'center' }}>
        <Text style={styles.toc}>Performance of Your Organization</Text>
        <Text style={styles.toc}>.................................................................................................</Text>
        <Text style={styles.toc}>7</Text>
      </View>
      <View style={{ flexDirection: "row", left: 0, right: 0 }}>
        <Text style={styles.toc}>Overall Maturity Score (Refer Fig 2 for details)</Text>
        <Text style={styles.toc}>..............................................................................</Text>
        <Text style={styles.toc}>7</Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: 4, left: 0, right: 0, textAlign: 'center' }}>
        <Text style={styles.toc}>Break-up of Overall Score: Digital and Functional Maturity</Text>
        <Text style={styles.toc}>............................................................</Text>
        <Text style={styles.toc}>7</Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: 4, left: 0, right: 0, textAlign: 'center' }}>
        <Text style={styles.toc}>Benchmark of Overall Maturity Score with Industry</Text>
        <Text style={styles.toc}>.......................................................................</Text>
        <Text style={styles.toc}>8</Text>
      </View>
      <View style={{ flexDirection: "row", left: 0, right: 0 }}>
        <Text style={styles.toc}>Maturity Chart</Text>
        <Text style={styles.toc}>................................................................................................................................</Text>
        <Text style={styles.toc}>8</Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: 4, left: 0, right: 0, textAlign: 'center' }}>
        <Text style={styles.toc}>How can we help?</Text>
        <Text style={styles.toc}>.........................................................................................................................</Text>
        <Text style={styles.toc}>10</Text>
      </View>

      <Footer pgnum='2' />
    </View>
  )
}