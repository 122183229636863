import { Font, StyleSheet } from '@react-pdf/renderer'
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: `Roboto/Roboto-Regular.ttf`
    },
    {
      src: `Roboto/Roboto-Bold.ttf`,
      fontWeight: 'bold'
    },
    {
      src: `Roboto/Roboto-Italic.ttf`,
      fontWeight: 'normal',
      fontStyle: 'italic'
    },
    {
      src: `Roboto/Roboto-BoldItalic.ttf`,
      fontWeight: 'bold',
      fontStyle: 'italic'
    }
  ]
})

export default StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    orientation: 'portrait',
  },
  section_right: {
    margin: 10,
    padding: 10,
    paddingTop: 20,
    width: '99%',
  },
  section_left: {
    width: '25%',
    height: '100%',
    backgroundColor: '#084c41',
  },
  profile_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20',
    marginBottom: '20px',
    height: '150',
    fontFamily: 'Helvetica-Bold',
  },
  name_text: {
    paddingTop: '10px',
    paddingBottom: '5px',
    fontSize: '14px',
    fontWeight: '900',
    color: 'white',
  },
  profession_text: {
    color: '#d1d5db',
    fontSize: '11px',
  },
  profile_img: {
    width: '60px',
    height: '60px',
    borderRadius: '90',
  },
  profile_line: {
    marginTop: '10px',
    width: '10%',
    height: '1px',
    backgroundColor: '#FFF',
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  header: {
    fontSize: 12,
    textAlign: 'center',
    color: 'grey',
    marginBottom: 10,
  },
  toc: {
    fontSize: 11,
    marginBottom: 12,
    lineHeight: 1.3,
    marginLeft: 38,
  },

  sub: {
    fontSize: 14,
    marginBottom: 12,
    fontWeight: 'bolder',
    marginLeft: 38,
  },
  specia: {
    lineHeight: 1.3,
    fontSize: '12',
    fontFamily: 'Roboto',
    fontStyle: 'italic',
  },
  heading: {
    fontSize: 21,
    marginBottom: 10,
    marginTop: 10,
    color: 'black',
    marginLeft: 38,
  },
  headingacess: {
    fontSize: 21,
    marginBottom: 8,
    marginTop: 10,
    color: 'black',
  },
  contenthead: {
    fontSize: 18,
    marginBottom: 10,
    marginTop: 10,
    color: '#2ca5d1',
    marginLeft: 38,
    fontStyle: 'Roboto',
  },
  headingorg: {
    fontSize: 23,
    marginBottom: 10,
    marginTop: 10,
    color: 'black',
    paddingLeft: 50,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    lineHeight: 1.3,
  },
  row: {
    flexDirection: "row",
    lineHeight: 1.3,
  },
  altrow: {
    flexDirection: "row",
    backgroundColor: '#E5E7E9',

  },
  description: {
    width: "60%",
    marginLeft: 20,
    fontStyle: 'italic',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: "black",
    paddingBottom: "2",
    fontSize: "13px",

  },
  viewtablealtcell: {
    backgroundColor: '#E5E7E9',
    width: "100%",
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    lineHeight: 1.3,
  },
  tablealtcell: {
    width: "18%",
    fontFamily: "Roboto",
    fontSize: "11px",
    display: 'flex',
    flexDirection: 'row',
    color: "gray",
    backgroundColor: '#E5E7E9',
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1.4,
  },
  tablealtcellhead: {
    width: "18%",
    fontStyle: "italic",
    fontSize: "11px",
    display: 'flex',
    color: "black",
    flexDirection: 'row',
    backgroundColor: '#E5E7E9',
    textAlign: "center"
    , paddingLeft: 10,
    paddingRight: 10,
  },
  tablecell: {
    width: "18%",
    fontSize: "11px",
    fontFamily: "Roboto",
    display: 'flex',
    color: "gray",
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: "0",
    lineHeight: 1.4,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  xyz: {
    width: "40%",
    lineHeight: 1.3,
  },
  footerFigCaption: {
    fontStyle: 'italic',
    fontSize: '10px',
    lineHeight: 1.3,
  }
})