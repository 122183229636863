import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../site/NotFound';
import CustomerDetails from './CustomerDetails';
import CustomersTable from './CustomersTable';
import ForgetPassword from './ForgetPassword';
import LandingPage from './LandingPage';
import Login from './Login';
import Registration from './Registration';
import ResetPassword from './ResetPassword';
import SignUp from './SignUp';
import SurveyQuestionMan from './SurveyQuestionMan';
import UserManagement from './UserManagement';

export class AdminApp extends Component {
    render() {
        return (
            <>
                <Routes>
                    
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/landing" element={<LandingPage />} />
                    <Route
                        path="/userManagement"
                        element={<UserManagement />}
                    />
                    <Route path="/registration/:token" element={<Registration />} />
                    <Route
                        path="/forgotPassword"
                        element={<ForgetPassword />}
                    />
                    <Route
                        path="/surveyQuestionManagement"
                        element={<SurveyQuestionMan />}
                    />
                    <Route
                        path="/customerDetails"
                        element={<CustomerDetails />}
                    />
                    <Route
                        key="all"
                        path="/customerDetails/all"
                        element={<CustomersTable />}
                    />
                    <Route
                        key="complete"
                        path="/customerDetails/complete"
                        element={<CustomersTable />}
                    />
                    <Route
                        key="inComplete"
                        path="/customerDetails/inComplete"
                        element={<CustomersTable />}
                    />
                    <Route
                        key="notStarted"
                        path="/customerDetails/notStarted"
                        element={<CustomersTable />}
                    />
                    <Route
                        key="resetPassword"
                        path="/resetPassword/:token"
                        element={<ResetPassword />}
                    />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </>
        );
    }
}

export default AdminApp;
