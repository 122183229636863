const isValidEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
        );
};
const validateEmail = (email) => {
    // if (!isValidEmail(email)) {
    //   console.log("its me");
    //   return false;
    // }
    // if (/(gmail|yahoo|hotmail)\.com$/.test(email)) {
    //     return false;
    // }

    return true;
};

const validatePhone = (phone) => {
    return String(phone)
        .toLowerCase()
        .match(/^(\+\d{1,3}[- ]?)?\d{10}$/);
};

const convertNumberToStandardFormat = (number) => {
    return String(number)
        .replace(/[^0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

function containsChars(str) {
    var regex = /^[A-Za-z\s]*$/;
    //     /^[a-zA-Z]+ [a-zA-Z]+$/;
    return regex.test(str);
}

const validateCompanyName = (companyName) => {
    return Number.isInteger(companyName);
};
const validatePassword = (password) => {
    var regularExpression =
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{5,16}$/;
    return regularExpression.test(password);
};
function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
}

export {
    containsChars,
    containsSpecialChars,
    validateEmail,
    validatePhone,
    convertNumberToStandardFormat,
    validateCompanyName,
    validatePassword,
};
